import Close from '../asset/img/modal-close.svg'
export const ViewUserModal = ({ onClose, userData }) => {
    return (
        <>
            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg planogram-div">
                    <div className="modal-content rounded-0 p-2">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() => onClose()}
                        />
                        <div className="modal-header rounded-0 align-items-center py-4 px-4">
                            <div className="col">
                                <h4 className="modal-title">User List </h4>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container" style={{
                                'overflow': 'scroll',
                                'height': '60vh'
                            }}>
                                <div className="row">

                                    <div className="table-responsive">
                                        <table className="table onboardTable">
                                            <thead className="bg-light">
                                                <tr className="border-0">
                                                    <th className="border-0">Id</th>
                                                    <th className="border-0">Name</th>
                                                    <th className="border-0">Member Id</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                {userData?.map((user) => {
                                                    return <tr>
                                                        <td>
                                                            {user?.id}
                                                        </td> <td>
                                                            {user?.name}
                                                        </td>
                                                        <td>
                                                            {user?.member_id}
                                                        </td>
                                                    </tr>
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-end">
                          

                            <button className="btn btn-primary submitRoom" style={{ marginLeft: '20px' }} onClick={() => onClose()}>Close</button>

                        </div>
                    </div>

                </div>

            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}
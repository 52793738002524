import axios from "axios";
import { BASE_URL } from "../Redux/saga/EndPoints";
import { store } from '../Redux/store'

const SCHEDULAR_BASE_URL = BASE_URL

const axiosInstance = axios.create({
    baseURL: SCHEDULAR_BASE_URL,
});

const axiosScheduler = axios.create({
    baseURL: SCHEDULAR_BASE_URL,
});
export const publicHeaders = () => {
    let token = store?.getState()?.LOGIN_CREDS?.user?.token
    return {
        "Authorization": `Token ${token}`,
        "Content-Type": "application/json",
    };
};
const checkForRedirect = () => {
    localStorage.removeItem('persist:root')
    window.location.href = '/'
}
export const login = async (url, data) => {
    const config = {
        method: 'POST',
        url,
        data,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const response = await axiosInstance(config);
    return response.data;
};

export const doGet = async (url, params) => {
    const config = {
        params,
        headers: publicHeaders(),
    };
    try {
        const response = await axiosInstance.get(url, config);
        return response.data;
    }
    catch (error) {
        if (error.response.status === 401) {
            console.log("****Unauthorized found in get method****", error)
            return checkForRedirect();
        }
        return error;
    }
};

export const doSchedlerGet = async (url, params) => {
    const config = {
        params,
        headers: publicHeaders(),
    };
    try {
        const response = await axiosScheduler.get(url, config);
        return response.data;
    }
    catch (error) {
        if (error.response.status === 401) {
            console.log("****Unauthorized found in get method****", error)
            return checkForRedirect();
        }
        return error;
    }
};

export const doPost = async (url, params) => {
    const config = {
        headers: publicHeaders(),
    };
    try {
        const response = await axiosInstance.post(url, params, config);
        return response.data;
    }
    catch (error) {
        if (error.response.status === 401) {
            console.log("****Unauthorized found in post method****", error)
            return checkForRedirect();
        }
        return error;
    }
};







import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { memberData } from "../Redux/reducer/MemberReducer";
import Actions from "../Redux/action"
import { useNavigate } from "react-router";
import { validatemaxLengthZipReg } from "../Utils/Validations";
import { getCollegeList } from "../Redux/reducer/SchedulerReducer";
import moment, { max } from "moment";
import Close from '../asset/img/modal-close.svg'
import AppLoader from "../component/AppLoader";
let cameraList = [{
    id: "",
    type: ""
}]
let authorizedUser = [{
    user: ""
}]
const onlyCharactersRegex = new RegExp(`^[a-zA-Z0-9 -]+$`);
const onlyNumberRegex = new RegExp(`^[0-9.]+$`);
const negativeNumberRegex = new RegExp(`/^-\d+(\.\d+)?$/`);

export const Createroom = ({close }) => {
    const [cameraCount, setCameraCount] = useState(0)
    const [authorizedUserCount, setAuthorizedUserCount] = useState(0)
    const [roomName, setRoomName] = useState('')
    const [roomNumber, setRoomNumber] = useState('')
    const memberList = useSelector(memberData)
    const [isLoading, setLoading] = useState(false)
    const [isDeleted, setDeleted] = useState(false)
    const [college, setCollege] = useState('')
    const [fromdate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [error, seterror] = useState({

    })
    const collegelist = useSelector(getCollegeList)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const callback = (response) => {
            setLoading(false)
            if (response?.status_code !== 0) {
                toast.error("Something went worng")
            }
            else {
                console.log(response, "it is from the create room")
            }
        }
        setLoading(true)
        dispatch(Actions.memberAction("", callback))
        dispatch(Actions.CollegeListAction())

    }, [dispatch])
    const addAuthorizedUser = (e) => {
        setAuthorizedUserCount(authorizedUserCount + 1)
        authorizedUserList()

    }
    const addCamera = (e) => {
        setCameraCount(cameraCount + 1)
        cameraCountList()
    }

    const cameraCountList = () => {
        if (cameraCount >= 0) {
            cameraList.push({
                id: "",
                type: ""
            })
        }
    }
    const authorizedUserList = () => {
        if (authorizedUserCount >= 0) {
            authorizedUser.push({
                user: ""
            })
        }
    }
    const selectCamera = (i, e) => {
        seterror({ cameraList: "" })
        cameraList[i].id = e.target.value
    }
    const selectType = (i, e) => {
        cameraList[i].type = e.target.value

    }
    const selectAuthorizedUser = (i, e) => {
        authorizedUser[i].user = e.target.value
    }
    // function hasDuplicates(array) {
    //     for (var i = 0; i < array.length; i++) {
    //         for (var j = i + 1; j < array.length; j++) {
    //             if (array[i] === array[j]) {
    //                 return true;
    //             }
    //         }
    //     }
    //     return false;
    // }
    const submit = () => {
        if (!roomName) {
            seterror({ roomName: "Please enter the room name" })
        }
        else if (validatemaxLengthZipReg(roomName)) {
            seterror({ roomName: "Room name must be within 30 characters" })
        }
        else if (!roomNumber) {
            seterror({ roomNumber: "Please enter the room number" })
        }
        else if (validatemaxLengthZipReg(roomNumber)) {
            seterror({ roomNumber: "Please enter a valid room number" })
        }
        // else if ( authorizedUser[0].user === "") {
        //     seterror({ authorizedUser: "Please enter the authorized user" })
        // }
        else if (cameraList[0].id === "") {
            seterror({ cameraList: "Please enter the camera list" })
        }
        else if (!onlyCharactersRegex.test(roomName)) {
            seterror({ roomName: "Special case are not allowed for room name" })
        }
        else if (!onlyNumberRegex.test(roomNumber)) {
            seterror({ roomNumber: "Special characters are not allowed for room number" })
        }
        // else if (!negativeNumberRegex.test(roomNumber)) {
        //     seterror({ roomName: "Negative values are not allowed for room number" })
        // }
        else if (!college) {
            seterror({ college: "Please select college" })
        } else if (!fromdate) {
            seterror({ fromdate: "Please select from date" })
        }
        else if (!toDate) {
            seterror({ toDate: "Please select to date" })
        } else if ((moment(fromdate).isAfter(moment(toDate)))) {
            seterror({ fromDate: "From date should not be greater than To date" })
        }
        else {
            seterror('')
            const createCallback = (response) => {
                if (response?.status_code === 0) {
                    cameraList = [{
                        id: "",
                        type: ""
                    }]
                    authorizedUser = [{
                        user: ""
                    }]
                    setLoading(false)
                    close(false);
                    toast.success("Created Successfully")
                    navigate('/roomlist')
                } else {
                    toast.error(response?.message)
                    setLoading(false)
                    // toast.error("Something went worng")
                }
            }
            let params = {
                room_name: roomName,
                room_no: roomNumber,
                cameras: cameraList,
                authorized_members: authorizedUser,
                college_id: college,
                from_date: fromdate,
                to_date: toDate
            }
            setLoading(true)
            dispatch(Actions.createRoomAction(params, createCallback))
        }

    }
    const deleteAuthorized = (index, authorizedUser) => {
        authorizedUser.splice(index, 1)
        setDeleted(!isDeleted)
    }
    const deleteCamera = (index) => {
        cameraList.splice(index, 1)
        setDeleted(!isDeleted)
    }

    const currentDate = new Date().toISOString().split('T')[0];

    // const MemberList = (item) => {

    //     let data
    //     let list = authorizedUser?.forEach((authobj) => {
    //         // debugger
    //         if (authobj?.user) {
    //             data = memberList?.persons?.filter((o) => o?.id != parseInt(authobj?.user))
    //         }
    //     })
    //     return data
    // }
    return (
        <>
            {
                isLoading && <AppLoader />
            }
            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl planogram-div">
                    <div className="modal-content rounded-0">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() => {
                                close(false)
                            }
                            } />
                        <div className="modal-header rounded-0 align-items-center py-4 px-4">
                            <div className="col">
                                <h4 className="modal-title">Add Room</h4>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Room name</label>
                                            <input type="text"
                                                className="form-control w-75"
                                                id="exampleInputEmail1"
                                                placeholder="Please enter the room name"
                                                onChange={(e) => {
                                                    setRoomName(e.target.value)
                                                    seterror({ roomName: "" })
                                                }
                                                } />
                                            {
                                                error?.roomName && <span className="errorSpan">{error?.roomName}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Room number</label>
                                            <input
                                                type="number"
                                                className="form-control w-75"
                                                id="exampleInputEmail1"
                                                onChange={(e) => setRoomNumber(e.target.value)}
                                                placeholder="Please enter the room number"
                                            />
                                            {
                                                error?.roomNumber && <span className="errorSpan">{error?.roomNumber}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="mb-3 appendList">
                                                <div className="authorizedLabel">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Authorized User</label>
                                                    <button className="btn btn-primary authorizedButton" onClick={(e) => addAuthorizedUser(e)} >
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                        Add
                                                    </button>
                                                </div>
                                                {
                                                    authorizedUser && authorizedUser?.map((item, index) => {
                                                        return <div className="authorizedSelect mb-3">
                                                            <select className={"form-select w-75 "} aria-label="Default select example" key={index} onChange={(e) => selectAuthorizedUser(index, e)}>
                                                                <option value="none" selected disabled hidden>Select User</option>
                                                                {
                                                                    memberList && memberList?.persons?.map((list, index) =>
                                                                        <option value={list?.id} key={index}>{list?.name}</option>
                                                                    )
                                                                }
                                                            </select>
                                                            {error?.authorizedUser && <span className="errorSpan">{error?.authorizedUser}</span>}
                                                            {index !== 0 && <i className="fa fa-trash fa-xl" aria-hidden="true" onClick={() => deleteAuthorized(index, authorizedUser)}></i>}
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div> */}
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3 appendList">
                                            <div className="authorizedLabel">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Add Camera</label>
                                                <button className="btn btn-primary cameraButton" onClick={(e) => addCamera(e)} >
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                    Add
                                                </button>
                                            </div>
                                            {
                                                cameraList && cameraList?.map((item, index) =>
                                                    <>
                                                        <div className={index > 0 ? "cameraDownList cameraChangeList" : "cameraList cameraChangeList"} key={index}>
                                                            <select className="form-select w-50" aria-label="Default select example" defaultValue={'none'} onChange={(e) => selectCamera(index, e)}>
                                                                <option value="none" selected disabled hidden>Select Camera</option>
                                                                {
                                                                    memberList && memberList?.cameras?.map((list, index) =>
                                                                        <option value={list?.id} key={index}>{list?.camera_name} {list?.camera_type === "EX" ? "EXIT" : "ENTRY"}</option>

                                                                    )
                                                                }
                                                            </select>
                                                            {/* <select className="form-select w-50 entry" aria-label="Default select example" onChange={(e) => selectType(index, e)}>
                                                                <option value="none" selected disabled hidden>Select Type</option>
                                                                <option value="EN">Entry</option>
                                                                <option value="EX">Exit</option>
                                                            </select> */}

                                                            {index !== 0 && <i className="fa fa-trash fa-xl mx-2" aria-hidden="true" onClick={() => deleteCamera(index, cameraList)}></i>}
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {error?.cameraList && <span className="errorSpan">{error?.cameraList}</span>}

                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">From Date</label>
                                            <input type="date"
                                                className="form-control w-75"
                                                value={fromdate} id="exampleInputEmail1"
                                                onChange={(e) => {
                                                    setFromDate(e.target.value)
                                                    setToDate('')
                                                    seterror({ fromdate: "" })
                                                }}
                                                min={currentDate}
                                            />
                                            {
                                                error?.fromdate && <span className="errorSpan">{error?.fromdate}</span>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">To Date</label>
                                            <input type="date" className="form-control w-75"
                                                value={toDate}
                                                id="exampleInputEmail1"
                                                onChange={(e) => {
                                                    setToDate(e.target.value)
                                                    seterror({ toDate: "" })
                                                }}
                                                min={fromdate} />
                                            {
                                                error?.toDate && <span className="errorSpan">{error?.toDate}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">College</label>
                                            <select value={college} onChange={(e) => {
                                                setCollege(e?.target?.value)
                                                seterror({ college: "" })
                                            }}
                                                className="form-select w-75">
                                                <option value='' hidden>Select College</option>
                                                {Array.isArray(collegelist) && collegelist?.map((o) => {
                                                    return <option value={o?.id} >{o?.name}</option>
                                                })
                                                }

                                            </select>
                                            {
                                                error?.college && <span className="errorSpan">{error?.college}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row-3">
                                    <button className="btn btn-primary submitRoom" onClick={() => submit()}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}
import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { DELETE_ROOM } from '../action/DeleteRoomAction'
import { doPost } from '../../service/index';
import END_POINTS from './EndPoints';

export function* deleteRoomSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.DELETE_ROOM_API, params)
        yield put(Actions.deleteRoomSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.deleteRoomFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* deleteRoomWatcher() {
    yield all([
        takeLatest(DELETE_ROOM, deleteRoomSaga),
    ]);
}
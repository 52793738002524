export const ImagePopup = (props) => {
    return (
        <>
            <div>
                <div className="modal d-block" id="loader" tabIndex="-1" aria-labelledby="loader" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-4" style={{ width: "700px", height: "500px" }}>
                            <i className="fa fa-times fa-2x closeIcon" aria-hidden="true" onClick={() => props.close(false)}></i>
                            <div className="modal-body text-center imagePopup">
                                <img src={props.image} alt="popupImage" className="PopUp_Image" width={500} height={400} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show loaderBackGround"></div>
            </div>
        </>
    )
}

import { Routes, Route } from 'react-router-dom'
import LoginScreen from './container/LoginScreen'
import { ToastContainer } from 'react-toastify';
import SignUpScreen from './SignUpScreen';
import ForgotPassword from './ForgotPassword';
import { Layout } from './component/Layout';
import { DashBoard } from './container/Dashboard';
import { Attendance } from './container/Attendance';
import { OnboardingList } from './container/OnboardingList';
import { Createroom } from './container/Createroom'
import { NotificationHistory } from './container/NotificationHistory';
import { RoomList } from './container/RoomList';
import { CameraList } from './container/CameraOnBoarding';
import { WorkerList } from './container/WorkerOnBoarding';
import { WorkerRoomList } from './container/WorkerRoomOnBoarding ';
import Schedular from './container/Schedular';
import {SchedulerList} from './container/SchedulerList';
import SectionTimeTable from './container/SectionTimeTable';
import MasterSlots from './container/MasterSlot';
import { HostelList } from './container/HostelList';
import { HostelAttendance } from './container/HostelAttendance';
import { NotificationList } from './container/NotificationList';
import { HostelSpecialAccess } from './container/HostelSpecialAccess';
import { HostelDashBoard } from './container/HostelDashboard';
import { HostelReport } from './container/HostelReport';
import { DaywiseReport } from './container/DaywiseReport';

function App() {
    return (
        <div className="App">
            <ToastContainer />
            <Routes>
                <Route path={'/'} element={<LoginScreen />} />
                <Route path={'/SignUpScreen'} element={<SignUpScreen />} />
                <Route path={'/ForgotPassword'} element={<ForgotPassword />} />
                <Route path={'/'} element={<Layout />}>
                    {/* <Route path={'/dashboard'} element={<DashBoard />} /> */}
                    {/* <Route path={'/hosteldashboard'} element={<HostelDashBoard />} /> */}
                    <Route path={'/dashboard'} element={<HostelDashBoard />}/>
                    <Route path={'/attendance'} element={<Attendance />} />
                    <Route path={'/hostelAttendance'} element={<HostelAttendance />} />
                    <Route path={'/hostelreport'} element={<HostelReport />} />
                    <Route path={'/daywisereport/:hostelId/:date'} element={<DaywiseReport />} />
                    <Route path={'/onboardedlist'} element={<OnboardingList />} />
                    <Route path={'/createroom'} element={<Createroom />} />
                    <Route path={'/notificationlist'} element={<NotificationList />} />
                    <Route path={'/hostelspecialaccess'} element={<HostelSpecialAccess />} />
                    <Route path={'/notification'} element={<NotificationHistory />} />
                    <Route path={'/roomlist'} element={<RoomList />} />
                    <Route path={'/cameraList'} element={<CameraList />} />
                    <Route path={'/workerList'} element={<WorkerList />} />
                    <Route path={'/hostelList'} element={<HostelList />} />
                    <Route path={'/workerRoomList'} element={<WorkerRoomList />} />
                    <Route path={'/schedular'} element={<Schedular />} />
                    <Route path={'/masterslot'} element={<MasterSlots />} />
                    <Route path={'/schedularList'} element={<SchedulerList />} />
                    <Route path={'/sections/table'} element={<SectionTimeTable />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;

import { useEffect } from 'react'
import Close from '../asset/img/modal-close.svg'
export const ViewNotificationVideoPopup = ({ onClose, data }) => {
    useEffect(()=>{
        console.log(data,'url')
    })
    return (
        <>
            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg planogram-div">
                    <div className="modal-content rounded-0 p-2">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() => onClose()}
                        />
                        <div className="modal-header rounded-0 align-items-center py-4 px-4">
                            <div className="col">
                                <h4 className="modal-title">Notification Video </h4>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container" style={{
                                'height': '60vh'
                            }}>
                                <div className="row mt-5">

                                <video controls style={{ width: '100%' }}>
                                            <source src={data} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                </div>
                            </div>
                        </div>
                        <div className="text-end">
                          

                            <button className="btn btn-primary submitRoom" style={{ marginLeft: '20px' }} onClick={() => onClose()}>Close</button>

                        </div>
                    </div>

                </div>

            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}
import Actions from "../action";

const initialState = {
    isLoading: false,
    searchData: {},
    searchListData: {},
    error: false
}
const SearchReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.SEARCH: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SEARCH_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                searchData: action.data,
                error: false
            };
        }
        case Actions.SEARCH_FAILURE: {
            return {
                ...state,
                isLoading: false,
                searchData: undefined,
                error: true,
            };
        }
        case Actions.SEARCH_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SEARCH_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                searchListData: action.data,
                error: false
            };
        }
        case Actions.SEARCH_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                searchListData: {},
                error: true
            };
        }
        default:
            return state;
    }
};
export const searchList = (state) => state?.SEARCHLIST_CRED?.searchData?.response
export default SearchReducer;
import { doAction, doResponseAction } from ".";

const ATTENDANCE = 'ATTENDANCE';
const ATTENDANCE_SUCCESS = 'ATTENDANCE_SUCCESS';
const ATTENDANCE_FAILURE = 'ATTENDANCE_FAILURE';

const HOSTEL_ATTENDANCE = 'HOSTEL_ATTENDANCE';
const HOSTEL_ATTENDANCE_SUCCESS = 'HOSTEL_ATTENDANCE_SUCCESS';
const HOSTEL_ATTENDANCE_FAILURE = 'HOSTEL_ATTENDANCE_FAILURE';

const CLEAR_REPORTS="CLEAR_REPORTS"
export {
    ATTENDANCE,
    ATTENDANCE_FAILURE,
    ATTENDANCE_SUCCESS,
    HOSTEL_ATTENDANCE,
    HOSTEL_ATTENDANCE_SUCCESS,
    HOSTEL_ATTENDANCE_FAILURE,
    CLEAR_REPORTS
}
// ATTENDANCE
export const clearAttendanceReports=data=>doAction(CLEAR_REPORTS,data)
export const attendanceAction = (params, callback) => doAction(ATTENDANCE, params, callback);
export const attendanceSuccessAction = data => doResponseAction(ATTENDANCE_SUCCESS, data);
export const attendanceFailureAction = data => doResponseAction(ATTENDANCE_FAILURE, data);

export const hostelAttendanceAction = (params, callback) => doAction(HOSTEL_ATTENDANCE, params, callback);
export const hostelAttendanceSuccessAction = data => doResponseAction(HOSTEL_ATTENDANCE_SUCCESS, data);
export const hostelAttendanceFailureAction = data => doResponseAction(HOSTEL_ATTENDANCE_FAILURE, data);

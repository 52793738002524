import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaginationComponent } from "../component/PaginationComponent";
import { toast } from "react-toastify";
import nodatafound from '../asset/img/datanotfound_black.png';
import Actions from "../Redux/action";
import { NotificationData } from "../Redux/reducer/NotificationHistoryReducer";
import AppLoader from "../component/AppLoader";
import { IMAGE_URL } from "../Redux/saga/EndPoints";
import { ImagePopup } from "../component/ImagePopup";
import moment from "moment/moment";
import { roomData } from "../Redux/reducer/GetRoomDataReducer";

export const NotificationHistory = () => {
    const dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(1)
    const [filterPerson, setFilterPerson] = useState(1)
    const [searchbyMemberId, setSearchByMemberId] = useState('')
    const [searchbyRoomId, setSearchByRoomId] = useState('')
    const [isloading, setLoading] = useState(false)
    const [count, setcount] = useState();
    const notifiactionData = useSelector(NotificationData);
    const [popup, setPopup] = useState(false);
    const [popupImage, setPopUpImage] = useState();
    const [room, setRoom] = useState('')
    const roomList = useSelector(roomData)

    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    useEffect(() => {
        let params = {
            page: pageNumber,
            flag: filterPerson,
            room: room === "All" ? '' : room,
            person_id: searchbyMemberId
        }
        const callback = (response) => {
            setLoading(false)
            if (response) {
                setcount(response?.count)
            }
            else {
                toast.error("Something went worng")
            }
        }
        setLoading(true)
        dispatch(Actions.notificationHistoryAction(params, callback))
    }, [pageNumber, searchbyRoomId, searchbyMemberId, filterPerson, dispatch, room])

    useEffect(() => {
        const callback = (response) => {
            if (response?.status_code !== 0) {
                toast.error("Something went worng")
            }
            setLoading(false)
        }
        setLoading(true)
        let params = {
            room_id: room ? room : ''
        }
        dispatch(Actions.dashboardAction(params, callback))
    }, [dispatch, room])
    const handlePopImage = (image) => {
        setPopup(true)
        setPopUpImage(image)
    }
    const changeFilterPerson = (value) => {
        setFilterPerson(value)
        setPageNumber(1)
    }
    const onChangeRoom = (value) => {
        setRoom(value)
        setPageNumber(1)
    }
    return (
        <>
            {
                isloading && <AppLoader />
            }
            <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                <div className="card">
                    <h5 className="card-header">Notification History</h5>
                    <div>
                        <div className="row d-flex justify-content-center align-items-center searchBar">
                            <div className="col-2 text-end">
                                <select className={"form-select "}
                                    aria-label="Default select example"
                                    value={room}
                                    onChange={(e) => {
                                        onChangeRoom(e.target.value)
                                    }}>
                                    <option onChange={(e) => {
                                        setRoom("")
                                    }}>All</option>
                                    {
                                        Array.isArray(roomList) && roomList?.map((item, index) =>
                                            <option value={item?.room_name} key={index}>{item?.room_name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="">
                                    <select
                                        value={filterPerson}
                                        onChange={(e) => changeFilterPerson(e?.target?.value)}
                                        className="form-select"
                                    >
                                        <option value='' hidden>Select Person Type</option>
                                        <option value={1}>Unknown Person</option>
                                        <option value={2}>Known Person</option>
                                    </select>
                                </div>
                            </div>
                          { filterPerson !== 1 && <div className="col-md-3">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search" value={filterPerson === 1 ? searchbyRoomId : searchbyMemberId} onChange={filterPerson === 1 ? (e) => setSearchByRoomId(e?.target?.value) : (e) => setSearchByMemberId(e?.target?.value)} />
                                    <div className="input-group-text">
                                        <i className="fa fa-search fa-1x"></i>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>

                    <div className="card-body p-0">
                        <div className="table-responsive">
                            {
                                notifiactionData && notifiactionData.length > 0 ?
                                    <table className="table onboardTable">
                                        <thead className="bg-light">
                                            <tr className="border-0">
                                                <th className="border-0">S.No</th>
                                                <th className="border-0">Person ID</th>
                                               {filterPerson !== 1 ? <th className="border-0">Person Name</th> :<th className="border-0">Person Type</th>}
                                                <th className="border-0">Room name</th>
                                                <th className="border-0"> Date and Time</th>
                                                <th className="border-0">Image</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                notifiactionData?.map((item, index) =>
                                                    <tr>
                                                        <td>{((pageNumber - 1) * 10) + (index + 1)}</td>
                                                        <td>{item?.person_id}</td>
                                                        <td>
                                                            <span className={item?.person === "Unknown" ? "badge-dot badge-brand mr-1" : "badge-dot badge-success mr-1"}></span>{item?.person}
                                                        </td>
                                                        <td>{item?.room}</td>
                                                        <td>
                                                            {/* {item?.time && new Date(item?.time).toLocaleTimeString()} */}
                                                            {moment(item?.time)?.format("DD/MM/YYYY HH:mm a")}
                                                        </td>
                                                        <td>
                                                            <div className="m-r-10">
                                                                <span onClick={() => handlePopImage(`${IMAGE_URL + item.img_url}`)}>
                                                                    <img src={`${IMAGE_URL + item.img_url}`} alt="user" style={{ width: "70px" }} className='thumbnailImage' />
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                            <tr>
                                                <td colSpan="9">
                                                    <PaginationComponent onChangeHandle={onChangeHandle} pageCount={count} pageNumber={pageNumber} setpage={setPageNumber} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    :
                                    <div className="d-flex justify-content-center mt-5">
                                        <div className="NodataFound">
                                            <h3>No Data Found</h3>
                                        </div>
                                        <div className="NodataImage">
                                            <img src={nodatafound} alt="No Data Found" className="NoData" />
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                popup && <ImagePopup image={popupImage} close={() => setPopup(false)} />
            }
        </>
    )
}
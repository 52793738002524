import { all } from 'redux-saga/effects'
import loginSagaListener from './LoginSaga'
import attendanceListener from './AttendanceSaga'
import onboardingListener from './onboardingListSaga'
import memberWatcher from './MemberSaga'
import createRoomWatcher from './CreateRoomSaga'
import notificationHistoryWatcher from './NotificationHistorySaga'
import serachWatcher from './searchSaga'
import toggleWatcher from './ToggleSaga'
import roomListSagaWatcher from './RoomListSaga'
import roomSagaWatcher from './GetRoomSaga'
import updateRoomWatcher from './UpdateRoomSaga'
import deleteRoomWatcher from './DeleteRoomSaga'
import editMemberListWatcher from './EditMemberListSaga'
import dashboardWatcher from './DashboardSaga'
import cameraWatcher from './cameraSaga'
import workerWatcher from './workerSaga'
import workerRoomWatcher from './workerRoomSaga'
import schedulerWatcher from './SchedulerSaga'
import masterSlotwatcher from './MasterSlotSaga'
import hostelwatcher from './HostelListSaga'
import notificationListWatcher from './NotificationListSaga'
import hostelSpecialAccessWatcher from './HostelSpecialAccessSaga'
import hostelDashboardWatcher from './HostelDashboardSaga'
import hostelReportWatcher from './HostelReportSaga'
import daywiseReportWatcher from './DaywiseReportSaga'
export default function* sagaWatchers() {
    yield all([
        loginSagaListener(),
        attendanceListener(),
        onboardingListener(),
        memberWatcher(),
        createRoomWatcher(),
        notificationHistoryWatcher(),
        serachWatcher(),
        toggleWatcher(),
        roomListSagaWatcher(),
        roomSagaWatcher(),
        updateRoomWatcher(),
        deleteRoomWatcher(),
        editMemberListWatcher(),
        dashboardWatcher(),
        cameraWatcher(),
        workerWatcher(),
        workerRoomWatcher(),
        schedulerWatcher(),
        masterSlotwatcher(),
        hostelwatcher(),
        notificationListWatcher(),
        hostelSpecialAccessWatcher(),
        hostelDashboardWatcher(),
        hostelReportWatcher(),
        daywiseReportWatcher(),
    ])
}
import { doAction, doResponseAction } from ".";

const HOSTEL_DASHBOARD = 'HOSTEL_DASHBOARD';
const HOSTEL_DASHBOARD_SUCCESS = 'HOSTEL_DASHBOARD_SUCCESS';
const HOSTEL_DASHBOARD_FAILURE = 'HOSTEL_DASHBOARD_FAILURE';
export {
    HOSTEL_DASHBOARD,
    HOSTEL_DASHBOARD_FAILURE,
    HOSTEL_DASHBOARD_SUCCESS,
}
// HOSTEL_dashboard
export const hostelDashboardAction = (params, callback) => doAction(HOSTEL_DASHBOARD, params, callback);
export const hostelDashboardSuccessAction = data => doResponseAction(HOSTEL_DASHBOARD_SUCCESS, data);
export const hostelDashboardFailureAction = data => doResponseAction(HOSTEL_DASHBOARD_FAILURE, data);
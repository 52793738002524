import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';
import { CREATE_HOSTEL_SPECIAL_ACCESS, HOSTEL_SPECIAL_ACCESS_DELETE, HOSTEL_SPECIAL_ACCESS_LIST } from '../action/HostelSpecialAccessAction';

export function* hostelSpecialAccessListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.HOSTEL_SPECIAL_ACCESS_LIST, params)
        yield put(Actions.hostelSpecialAccessListSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.hostelSpecialAccessListFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* hostelSpecialAccessCreateSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_HOSTEL_SPECIAL_ACCESS, params)
        yield put(Actions.createhostelSpecialAccessSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.createhostelSpecialAccessFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* hostelSpecialAccessDeleteSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.DELETE_HOSTEL_SPECIAL_ACCESS, params)
        yield put(Actions.hostelSpecialAccessDeleteSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.hostelSpecialAccessDeleteFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* hostelSpecialAccessWatcher() {
    yield all([
        takeLatest(HOSTEL_SPECIAL_ACCESS_LIST, hostelSpecialAccessListSaga),
        takeLatest(CREATE_HOSTEL_SPECIAL_ACCESS, hostelSpecialAccessCreateSaga),
        takeLatest(HOSTEL_SPECIAL_ACCESS_DELETE, hostelSpecialAccessDeleteSaga),
    ]);
}
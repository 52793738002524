import * as loginActions from './LoginActions';
import * as ClientCredentialAction from './ClientCrendentialAction';
import * as attendance from './AttendanceAction';
import * as onboarding from './OnBoardingList';
import * as searchList from './SearchAction';
import * as member from './MemeberListAction';
import * as createRoom from './CreateRoomAction';
import * as notificationHistory from './NotificationHistoryAction';
import * as toggle from './ToggleAction';
import * as roomlist from './RoomListAction';
import * as getRoomList from './GetRoomData';
import * as updateRoom from './UpdateRoom';
import * as deleteRoom from './DeleteRoomAction';
import * as editMemberList from './EditMemberListActions';
import * as dashboard from './DashBoardAction'
import * as cameraList from './CameraAction'
import * as workerList from './WorkerAction'
import * as workerRoomList from './WorkerRoomAction'
import * as Scheduler from './ScheduleAction'
import * as MasterSlot from './MasterSLotUploadAction'
import * as HostelAction from './HostelAction'
import * as NotificationListAction from './NotificationListAction'
import * as HostelSpecialAccessAction from './HostelSpecialAccessAction'
import * as hostelDashboardAction from './HostelDashboardAction'
import * as hostelReport from './HostelReportAction'
import * as daywiseReport from './DayWiseReportAction'
const Actions = {
    ...loginActions,
    ...ClientCredentialAction,
    ...attendance,
    ...onboarding,
    ...searchList,
    ...member,
    ...createRoom,
    ...notificationHistory,
    ...toggle,
    ...roomlist,
    ...getRoomList,
    ...updateRoom,
    ...deleteRoom,
    ...editMemberList,
    ...dashboard,
    ...cameraList,
    ...workerList,
    ...workerRoomList,
    ...Scheduler,
    ...MasterSlot,
    ...HostelAction,
    ...NotificationListAction,
    ...HostelSpecialAccessAction,
    ...hostelDashboardAction,
    ...hostelReport,
    ...daywiseReport,
}

export default Actions;

export const doAction = (type, params = {}, callback) => ({ type, params, callback });
export const doResponseAction = (type, data = {}) => ({ type, data });

import { doAction, doResponseAction } from ".";

const DELETE_ROOM = 'DELETE_ROOM';
const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS';
const DELETE_ROOM_FAILURE = 'DELETE_ROOM_FAILURE';
export {
    DELETE_ROOM,
    DELETE_ROOM_FAILURE,
    DELETE_ROOM_SUCCESS,
}
// DELETE_ROOM
export const deleteRoomAction = (params, callback) => doAction(DELETE_ROOM, params, callback);
export const deleteRoomSuccessAction = data => doResponseAction(DELETE_ROOM_SUCCESS, data);
export const deleteRoomFailureAction = data => doResponseAction(DELETE_ROOM_FAILURE, data);
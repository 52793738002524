import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { CREATE_ROOM } from '../action/CreateRoomAction'
import { doPost } from '../../service/index';
import END_POINTS from './EndPoints';

export function* createRoomSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_ROOM_API, params)
        yield put(Actions.createRoomSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.createRoomFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* createRoomWatcher() {
    yield all([
        takeLatest(CREATE_ROOM, createRoomSaga),
    ]);
}
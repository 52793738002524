import { doAction, doResponseAction } from ".";
const ROOM = 'ROOM';
const ROOM_SUCCESS = 'ROOM_SUCCESS';
const ROOM_FAILURE = 'ROOM_FAILURE';

const CLEAR_ROOMS="CLEAR_ROOMS"
export {
    ROOM,
    ROOM_FAILURE,
    ROOM_SUCCESS,
    CLEAR_ROOMS
}

//ROOM
export const clearRooms = data => doAction(CLEAR_ROOMS, data)
export const roomAction = (params, callback) => doAction(ROOM, params, callback);
export const roomSuccessAction = data => doResponseAction(ROOM_SUCCESS, data);
export const roomFailureAction = data => doResponseAction(ROOM_FAILURE, data);
import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { GENERATE_PASSWORD, ONBOARDINGLIST, ONBOARDINGSTAFFLIST } from '../action/OnBoardingList'
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';
 
export function* onboardingListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ONBOARDING_API, params)
        yield put(Actions.onboardingSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.onboardingFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
 
export function* onboardingStaffListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ONBOARDINGSTAFF_API, params)
        yield put(Actions.onboardingStaffSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.onboardingStaffFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
 
export function* generatePassword({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.GENERATE_PASSWORD, params)
        yield put(Actions.GeneratePasswordSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.GeneratePasswordFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* onboardingWatcher() {
    yield all([
        takeLatest(ONBOARDINGLIST, onboardingListSaga),
        takeLatest(ONBOARDINGSTAFFLIST, onboardingStaffListSaga),
        takeLatest(GENERATE_PASSWORD, generatePassword),
 
    ]);
}
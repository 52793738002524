import Actions from '../action';

const initialState = {
    isLoading: false,
    user: {},
    logout: undefined
}

const LoginReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.LOGIN: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.LOGIN_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                user: action.data
            };
        }
        case Actions.LOGIN_FAILURE: {
            return {
                ...state,
                isLoading: false,
                user: {},
                error: action.error,
            };
        }
        case Actions.LOGOUT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.LOGOUT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                logout: action.data
            };
        }
        case Actions.LOGOUT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                logout: {},
                error: action.error,
            };
        }
        default:
            return state;
    }
};


export default LoginReducer;
export const getToken = (state) => state?.LOGIN_CREDS?.user?.token;
export const getUserData = (state) => state?.LOGIN_CREDS?.user;

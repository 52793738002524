import { Outlet ,Navigate, useLocation} from "react-router-dom"
import { SideBar } from './Sidebar'
import { Header } from './Header'
import ScrollToTop from '../Utils/ScrollToTop'
import { store } from "../../src/Redux/store";

export const Layout = () => {
    const location=useLocation()
    let token = store?.getState()?.LOGIN_CREDS?.user?.token
    return (
        <>

            {token ? <div className={"dashboard-main-wrapper"}>
                <ScrollToTop />
                <SideBar />
                <Header />
                <div className={location.pathname === "/schedular" || location?.pathname === '/sections/table' ? "dashboard-wrapper-hide" : "dashboard-wrapper"}>
                    <div className="dashboard-ecommerce">
                        <div className="container-fluid dashboard-content ">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div> : <Navigate to='/' />}
        </>


    )
}
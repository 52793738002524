import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { ATTENDANCE, HOSTEL_ATTENDANCE } from '../action/AttendanceAction'
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';

export function* attendanceSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ATTENDANCE_API, params)
        yield put(Actions.attendanceSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.attendanceFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* hostelAttendanceSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.HOSTEL_ATTENDANCE_API, params)
        yield put(Actions.hostelAttendanceSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.hostelAttendanceFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* deleteMemberSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.DELETE_MEMEBER, params)
        yield put(Actions.deleteMemberSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.deleteMemberFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* attendanceWatcher() {
    yield all([
        takeLatest(ATTENDANCE, attendanceSaga),
        takeLatest(HOSTEL_ATTENDANCE, hostelAttendanceSaga),
        takeLatest(Actions.DELETE_MEMBER_LIST, deleteMemberSaga),

    ]);
}
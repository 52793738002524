import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import AppLoader from "../component/AppLoader";
import Actions from "../Redux/action";
import { getCollegeList, getCourseList, getYearList, getdepartmentList, getdepartmentSearch, getSelectedData } from "../Redux/reducer/SchedulerReducer";
import nodatafound from '../asset/img/datanotfound_black.png';
// import DepartmentTypehead from "../component/DepartmentTypehead";
// import CourseTypehead from "../component/CourseTypehead";
// import YearTypehead from "../component/YearTypehead";
// import CollegeTypehead from "../component/CollegeTypehead";
import { useLocation, useNavigate } from "react-router";
import { SectionEditModal } from "../component/SectionEditModal";


export const SchedulerList = () => {
    const location = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const collegeList = useSelector(getCollegeList)
    const courseList = useSelector(getCourseList)
    const departmentList = useSelector(getdepartmentList)
    const yearList = useSelector(getYearList)
    // const [pageNumber, setPageNumber] = useState(1);
    const departmentlist = useSelector(getdepartmentSearch);
    const selctedFormData = useSelector(getSelectedData);
    const allEmpty = departmentlist?.every((course) => course?.sections?.length === 0);
    const [isloading, setLoading] = useState(false)
    // const [formDetails, setFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState(location.state?.formDetails || {});
    const [editSectionModal, setEditSectionModal] = useState(false);
    const [sectionData, setSectionData] = useState({});


    const DepartmentSearch = useCallback(() => {
        setLoading(true)
        const callback = (res) => {
            setLoading(false)
        }
        let params = {
            course_id: formDetails?.course_id,
            year: formDetails?.year_id,
            college: formDetails?.college_id,
            department: formDetails?.department_id
        }
        dispatch(Actions.DepartmentSearchSectionList(params, callback))
    }, [dispatch, formDetails?.college_id, formDetails?.course_id, formDetails?.department_id, formDetails?.year_id])

    useEffect(() => {
        if (location.state?.formDetails) {
            setFormDetails(location.state.formDetails);
        }
    }, [location.state?.formDetails]);

    useEffect(() => {
        DepartmentSearch()
    }, [DepartmentSearch])

    useEffect(() => {
        dispatch(Actions.CollegeListAction())
    }, [dispatch])
    useEffect(() => {
        const params = {
            id: formDetails?.college_id
        }
        dispatch(Actions.CoursetAction(params))
    }, [dispatch, formDetails?.college_id])

    useEffect(() => {
        const params = {
            id: formDetails?.course_id
        }
        dispatch(Actions.DepartmentAction(params))
    }, [dispatch, formDetails?.course_id])

    useEffect(() => {
        const params = {
            id: formDetails?.course_id
        }
        dispatch(Actions.YearAction(params))
    }, [dispatch, formDetails?.course_id])

    useEffect(() => {
        return () => {
            if (location?.pathname === '/sections/table' || location?.pathname === '/schedularList') {
                return
            } else {
                dispatch(Actions.selectedDataList({}))
            }
        }
    }, [dispatch, location?.pathname])
    const handleCollegeSelect = (selected) => {
        setFormDetails({ ...formDetails, 'course_id': '', 'year_id': '', 'department_id': '', 'college_id': selected })
    };
    const handleDepartmentSelect = (selected) => {
        setFormDetails({ ...formDetails, 'year_id': '', 'department_id': selected })
    };
    const handleCourseSelect = (selected) => {
        setFormDetails({ ...formDetails, 'department_id': '', 'course_id': selected,'year_id':'' })
    };
    const handleYearSelect = (selected) => {
        setFormDetails({ ...formDetails, 'year_id': selected })
    };


    // const deletedepartment = () => {
    //     const callback = (response) => {
    //         if (response?.status_code === 0) {
    //             onCloseModel()
    //             toast.success("department successfully deleted")
    //             DepartmentSearch()
    //             if (departmentlist?.length === 1) {
    //                 setPageNumber(pageNumber - 1)
    //             } else {
    //                 setPageNumber(pageNumber)
    //             }

    //         } else {
    //             toast.error(response?.message)
    //         }
    //     }
    //     let params = {
    //         id: itemObj?.id
    //     }
    //     dispatch(Actions.departmentDelete(params, callback))
    // }


    const viewScheduler = (data, main) => {
        dispatch(Actions.selectedDataList(formDetails))
        data.timeTable = true
        data.department = main.department.name
        data.course = main?.course?.name
        data.year = data?.years?.name
        data.section = data.name
        data.department_id = main?.department?.id
       navigate(`/sections/table?collegeId=${data?.years?.college}`, { state: { data, formDetails } })

    }

    const EditSection = (data) => {
        setSectionData(data)
        setEditSectionModal(true)
    }
    return (
        <>
            {
                isloading && <AppLoader />
            }
            <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                {/* <div className="mb-2" style={{ textAlign: 'end' }}>
                    <button type="button" className="btn btn-primary" onClick={() => setPopup(true)}>Add department</button>
                </div> */}

                <div className="card">
                    <h5 className="card-header">Scheduler List</h5>
                    <div className="card-body">
                        <div className="row mb-3">
                            {/* <div className="col-sm-3 ">
                                <CollegeTypehead
                                    formDetails={formDetails}
                                    id="CollegeTypeAhead"
                                    style={{ margin: '10px' }}
                                    placeholder="Select College"
                                    isMulti={false}
                                    // defaultValue={optionsData}
                                    onChange={handleCollegeSelect}
                                />
                            </div> */}


                            {/* {formDetails?.college_id && <div className="col-sm-3 mb-3">
                                <CourseTypehead
                                    formDetails={formDetails}
                                    id="CourseTypehead"
                                    style={{ margin: '10px' }}
                                    placeholder="Select Course"
                                    isMulti={false}
                                    // defaultValue={optionsData}
                                    onChange={handleCourseSelect}
                                // isDisabled={formDetails.department_id ? false : true}
                                />
                            </div>} */}

                            {/* {formDetails?.course_id && <div className="col-sm-3 mb-3">
                                <DepartmentTypehead
                                    formDetails={formDetails}
                                    id="DepartmentTypehead"
                                    style={{ margin: '10px' }}
                                    placeholder="Select Department"
                                    isMulti={false}
                                    // defaultValue={optionsData}
                                    onChange={handleDepartmentSelect}
                                // isDisabled={formDetails?.college_id ? false : true}
                                />
                            </div>} */}

                            {/* {formDetails?.course_id && <div className="col-sm-3 mb-3">
                                <YearTypehead
                                    formDetails={formDetails}
                                    id="YearTypehead"
                                    style={{ margin: '10px' }}
                                    placeholder="Select Year"
                                    isMulti={false}
                                    // defaultValue={optionsData}
                                    onChange={handleYearSelect}
                                // isDisabled={formDetails.course_id ? false : true}

                                />
                            </div>} */}
                            <div className="col-sm-3 mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">College</label>
                                <select value={formDetails?.college_id} onChange={(e) => handleCollegeSelect(e?.target?.value)}
                                    className="form-select">
                                    <option value='' hidden>Select College </option>
                                    {Array.isArray(collegeList) && collegeList?.map((o) => {
                                        return <option value={o?.id}>{o?.name}</option>

                                    })
                                    }

                                </select>
                            </div>
                            <div className="col-sm-3 mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Course</label>
                                <select value={formDetails?.course_id} disabled={formDetails?.college_id ? false : true} onChange={(e) => handleCourseSelect(e?.target?.value)}
                                    className="form-select">
                                    <option value='' hidden>Select Course</option>
                                    {Array.isArray(courseList) && courseList?.map((o) => {
                                        return <option value={o?.id}>{o?.name}</option>

                                    })
                                    }

                                </select>
                            </div>
                            <div className="col-sm-3 mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Department</label>
                                <select disabled={formDetails?.course_id ? false : true} value={formDetails?.department_id} onChange={(e) => handleDepartmentSelect(e?.target?.value)}
                                    className="form-select">
                                    <option value='' hidden>Select Department</option>
                                    {Array.isArray(departmentList) && departmentList?.map((o) => {
                                        return <option value={o?.id}>{o?.name}</option>
                                    })
                                    }
                                </select>
                            </div>
                            <div className="col-sm-3 mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Year</label>
                                <select value={formDetails?.year_id} disabled={formDetails?.department_id ? false : true} onChange={(e) => handleYearSelect(e?.target?.value)}
                                    className="form-select">
                                    <option value='' hidden>Select Year</option>
                                    {Array.isArray(yearList) && yearList?.map((o) => {
                                        return <option value={o?.id}>{o?.name}</option>
                                    })}
                                </select>
                            </div>

                        </div>
                    </div>
                    <div className="card-body p-0">
                        {

                            (departmentlist && departmentlist.length > 0 && !allEmpty) ?
                                <div className="table-responsive">
                                    <table className="table onboardTable">
                                        <thead className="bg-light">
                                            <tr className="border-0">
                                                {/* <th className="border-0">S.No</th> */}
                                                <th className="border-0">Course Name</th>
                                                <th className="border-0">Department Name</th>
                                                <th className="border-0">Year</th>
                                                <th className="border-0">Sections</th>
                                                <th className="border-0">Compreface</th>
                                                <th className="border-0">Scheduler</th>
                                                <th className="border-0">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                departmentlist && departmentlist?.map((mainObj, index) => {
                                                    return mainObj.sections?.map((childObj, childIndex) => {
                                                        return (<tr>
                                                            {/* <td>{(childIndex + 1)}</td> */}
                                                            <td>{mainObj?.course?.name}</td>
                                                            <td>{mainObj?.department?.name} </td>
                                                            <td>{childObj?.years?.name} </td>
                                                            <td>{childObj?.name} </td>
                                                            <td>{childObj?.compre_face} </td>
                                                            <td className="text-center">
                                                                <i className="fa fa-calendar cursor-pointer"
                                                                    style={{ 'color': '#000' }}
                                                                    aria-hidden="true" onClick={() => viewScheduler(childObj, mainObj)}></i>
                                                            </td>
                                                            <td className="text-center">
                                                                <i className="fas fa-edit text-center cursor-pointer" onClick={() => EditSection(childObj)}></i>
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                                )
                                            }
                                            <tr>
                                                {/* <td colspan="9">
                                                    <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                                </td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="d-flex justify-content-center mt-5 mb-5">
                                    <div className="NodataFound">
                                        <h3>No Data Found</h3>
                                    </div>
                                    <div className="NodataImage">
                                        <img src={nodatafound} alt="No Data Found" className="NoData" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            {editSectionModal && <SectionEditModal
                sectionData={sectionData}
                onClose={() => {
                    setEditSectionModal(false)
                    DepartmentSearch()
                }}

            />}
        </>
    )
}
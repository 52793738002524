import Actions from "../action";

const initialState = {
    isLoading: false,
    dashboard: undefined,
    error: false
}
const DashboardReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.DASHBOARD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DASHBOARD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                dashboard: action.data,
                error: false
            };
        }
        case Actions.DASHBOARD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                dashboard: undefined,
                error: true,
            };
        }
        default:
            return state;
    }
};
export const dashboardData = (state) => state?.DASHBOARD_CRED?.dashboard?.response
export default DashboardReducer;
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppLoader from "../component/AppLoader";
import { AddWorkerPopup } from "../component/AddWorker";
import { PaginationComponent } from "../component/PaginationComponent"
import Actions from "../Redux/action";
import { getWorkerListSelector, getWorkerListLoadingSelector, getWorkerCountSelector } from "../Redux/reducer/WorkerReducer";
import nodatafound from '../asset/img/datanotfound_black.png';
import { DeclinePopup } from "../component/DeclinePopup";
import { getHostelCountSelector, getHostelListLoadingSelector, getHostelListSelector } from "../Redux/reducer/HostelReducer";
import { AddHostelPopup } from "../component/AddHostel";
import { getCollegeList } from "../Redux/reducer/SchedulerReducer";


export const HostelList = () => {
    const loading = useSelector(getHostelListLoadingSelector);
    const totalCount = useSelector(getHostelCountSelector);
    const [pageNumber, setPageNumber] = useState(1);
    const hostelList = useSelector(getHostelListSelector);
    const [popupData, setPopupData] = useState();
    const [popup, setPopup] = useState(false);
    const dispatch = useDispatch();
    const [itemObj, setItemObj] = useState('')
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);
    const collegeList = useSelector(getCollegeList)

    const getHostelList = useCallback(() => {
        let params = {
            page: pageNumber
        }
        dispatch(Actions.hostelList(params))
    }, [dispatch, pageNumber])

    useEffect(() => {
        getHostelList()
    }, [getHostelList])

    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const editHostel = (hostelData) => {
        setPopupData(hostelData)
        setPopup(true)
    }
    const deleteHostel = () => {
        const callback = (response) => {
            if (response?.status_code === 0) {
                onCloseModel()
                toast.success("Hostel successfully deleted")
                getHostelList()
                if (hostelList?.length === 1) {
                    setPageNumber(pageNumber - 1)
                } else {
                    setPageNumber(pageNumber)
                }
            } else {
                toast.error(response?.message)
            }
        }
        let params = {
            id: itemObj?.id
        }
        dispatch(Actions.hostelDelete(params, callback))
    }
    useEffect(() => {
        dispatch(Actions.CollegeListAction())
    }, [dispatch])
    
    const onClosePopUp = () => {
        setPopupData('')
        setPopup(false)
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const handleClick = (item) => {
        setItemObj(item);
        setOpenDeclineModal(true);
    };
    return (
        <>
            {
                loading && <AppLoader />
            }
            <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                <div className="mb-2" style={{ textAlign: 'end' }}>
                    <button type="button" className="btn btn-primary" onClick={() => setPopup(true)}>Add Hostel</button>
                </div>
                <div className="card">
                    <h5 className="card-header">Hostel List</h5>

                    <div className="card-body p-0">
                        {
                            hostelList && Array.isArray(hostelList) && hostelList?.length > 0 ?
                                <div className="table-responsive">
                                    <table className="table onboardTable">
                                        <thead className="bg-light">
                                            <tr className="border-0">
                                                <th className="border-0">S.No</th>
                                                <th className="border-0">Hostel Name</th>
                                                {/* <th className="border-0">Hostel No</th> */}
                                                <th className="border-0">Compreface</th>
                                                <th className="border-0">Rooms</th>
                                                <th className="border-0">Cameras</th>
                                                <th className="border-0">Edit</th>
                                                <th className="border-0">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                hostelList && Array.isArray(hostelList) && hostelList.length > 0 && hostelList?.map((item, index) => {
                                                    const totalCamerasCount = item?.rooms?.reduce((acc, curr) => {
                                                        return acc + curr?.cameras?.length;
                                                    }, 0);
                                                    return (<tr>
                                                        <td>{((pageNumber - 1) * 10) + (index + 1)}</td>
                                                        <td>{item?.name}</td>
                                                        {/* <td>{item?.hostel_no} </td> */}
                                                        <td>{item?.compre_face} </td>
                                                        <td className="authorizedBox">
                                                            {
                                                                item?.rooms.map((obj, i) => {
                                                                    return (<div key={i}>
                                                                        {obj?.room_name}
                                                                    </div>
                                                                    )
                                                                }
                                                                )}
                                                        </td>
                                                        <td>{totalCamerasCount} </td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" onClick={() => editHostel(item)}>Edit</button>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" onClick={() => handleClick(item)}>Delete</button>
                                                        </td>
                                                    </tr>)
                                                }
                                                )
                                            }
                                            <tr>
                                                <td colspan="9">
                                                    <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="d-flex justify-content-center mt-5">
                                    <div className="NodataFound">
                                        <h3>No Data Found</h3>
                                    </div>
                                    <div className="NodataImage">
                                        <img src={nodatafound} alt="No Data Found" className="NoData" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {
                popup && <AddHostelPopup pageNumber={pageNumber} onClose={() => onClosePopUp()} data={popupData} collegeList={collegeList} />

            }
            {isOpenDeclineModal && <DeclinePopup
                title={`Are you sure you want to delete ${itemObj?.name}?`}
                onClose={() => onCloseModel()}
                onSuccess={() => deleteHostel()}
            />}
        </>
    )
}
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../Redux/action";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { toast } from "react-toastify";
import { roomData } from "../Redux/reducer/GetRoomDataReducer";
import moment from "moment";
import nodatafound from '../asset/img/datanotfound_black.png';
import { getHostelReports } from "../Redux/reducer/AttendanceReducer";
import AppDatePicker from "../component/AppDatePicker";
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import AppLoader from "../component/AppLoader";
import { getCollegeList, getCourseList, getSchedulerList, getSectionList, getSlotList, getYearList, getdepartmentList, getdepartmentSearch } from "../Redux/reducer/SchedulerReducer";
import { getHostelListSelectSelector, getHostelListSelector } from "../Redux/reducer/HostelReducer";
import { getNotificationListSelector } from "../Redux/reducer/NotificationListReducer";
import { ViewNotificationVideoPopup } from "../component/ViewNotificationVideo";
import { PaginationComponent } from "../component/PaginationComponent";
export const NotificationList = () => {
    const EXCEL_EXTENSION = '.xlsx'
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(1);
    const [count, setcount] = useState();
    const courseList = useSelector(getCourseList)
    const hostelList = useSelector(getHostelListSelectSelector)
    const collegeList = useSelector(getCollegeList)
    const slotList = useSelector(getSlotList)
    const yearList = useSelector(getYearList)
    const departmentList = useSelector(getdepartmentList)
    const [formDetails, setFormDetails] = useState({})
    const sectionList = useSelector(getdepartmentSearch);
    const [room, setRoom] = useState('')
    const [status, setStatus] = useState('')
    const [err, setErr] = useState({})
    const [viewReports, setViewReport] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [popup, setPopup] = useState(false);
    const [notificationList,setNotificationList] = useState([]);
    const [fromTimeValue, onChangeFromTimeValue] = useState('');
    const [toTimeValue, onChangeToTimeValue] = useState('');
    const [popupData, setPopupData] = useState();

    useEffect(() => {
        viewReport()

    }, [dispatch])

    const getHostelList = useCallback(() => {
        // let params = {
        //     page: pageNumber
        // }
        dispatch(Actions.hostelListSelect(""))
    }, [dispatch])

    useEffect(() => {
        getHostelList()
    }, [])

    const onClosePopUp = () => {
        setPopupData('')
        setPopup(false)
    }

    const clearReport = () => {
        setRoom("")
        setErr("")
        setViewReport("")
        dispatch(Actions.clearReports())
        setFormDetails({ hostel_id: "" })
        // dispatch(Actions.clearRooms())

    }
    const viewReport = useCallback((hostelId) => { 
        const callback = (response) => {
            if (response) {
                if (response?.results?.length > 0) {
                    setcount(response?.count)  
                    setNotificationList(response?.results)
                    console.log(response.results, 'resulrs')
                    setViewReport(true)
                } else {
                    toast.error("No data found")
                }
                setLoading(false)
                setErr("")
            }
            else {
                toast.error(response?.message)
                setLoading(false)
            }
        }
        const params = {
            hostel_id: hostelId,
            page: pageNumber
        }
        setLoading(true)
        dispatch(Actions.notificationList(params, callback))

    },[dispatch,pageNumber])

    useEffect(() => {
        viewReport(formDetails?.hostel_id);
    }, [viewReport, formDetails?.hostel_id, pageNumber]);
    

    const handleDownload = () => {
        const saveAsExcelFile = (buffer, fileName) => {
            const data = new Blob([buffer], { type: EXCEL_TYPE }) || [];
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
        }
        const callback = (response) => {
            if (response?.status_code === 0) {
                const { Absentees, Presentees } = response?.response || {}
                // let modifiedReportJSON = notificationList?.map((obj) => ({ ...obj}))
                const worksheet = XLSX.utils.json_to_sheet(notificationList);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'Report')
                toast.success("Downloaded Successfully")
                setLoading(false)

            }
            else {
                toast.error("Something went worng")
                setLoading(false)
            }
        }
        const params = {
            hostel_id: formDetails?.hostel_id,
        }
        setLoading(true)
        dispatch(Actions.hostelAttendanceAction(params, callback))

    }

    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }

    const handleClick = (url) => {
        setPopup(true);
        setPopupData(url)

    }

    const handleHostelSelect = (selected) => {
        setViewReport(false)
        setFormDetails({ ...formDetails, 'hostel_id': parseInt(selected) })
        setErr({ ...err, "hostel_id": "" })
        viewReport(parseInt(selected))
    };


    return (
        <>
            {
                isLoading && <AppLoader />
            }
            <div className="ecommerce-widget">
                <h5 className="card-header">Notifications</h5>
                <div className="card border-3 border-top border-top-primary">
                    <div className="row">
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-6 selectDate mx-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Hostel</label>
                            <select value={formDetails?.hostel_id} onChange={(e) => handleHostelSelect(e?.target?.value)}
                                className="form-select">
                                <option value='' hidden>Select Hostel</option>
                                {Array.isArray(hostelList) && hostelList?.map((o) => {
                                    return <option value={o?.id}>{o?.name}</option>
                                })
                                }
                            </select>
                            <p style={{ 'color': 'red' }}>{err?.hostel_id}</p>
                        </div>
                        {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-6 selectDate1">
                            {!viewReports ? <button type="button" className="btn btn-primary" onClick={() => viewReport()}>View</button>
                                : <button type="button"
                                    className="btn btn-primary" onClick={() => handleDownload()}>Download</button>}
                            &nbsp;
                            {viewReports && <button className="btn btn-primary" onClick={() => clearReport()}>CLEAR</button>}
                        </div> */}
                    </div>

                </div>
                <div className="card">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    {/* {
                                        (Array.isArray(notificationList)) && <h4 className="mt-2 p-2">Presents-{Presentees?.length}</h4>
                                    } */}
                                    {(Array.isArray(notificationList) && notificationList?.length > 0) &&
                                        <table className="table onboardTable">
                                            <thead className="bg-light">
                                                <tr className="border-0">
                                                    <th className="border-0">S.No</th>
                                                    <th className="border-0">Date</th>
                                                    <th className="border-0">Time</th>
                                                    <th className="border-0">Camera</th>
                                                    <th className="border-0">Hostel</th>
                                                    <th className="border-0">Message</th>
                                                    <th className="border-0">Video</th>
                                                    {/* <th className="border-0">Room</th> */}

                                                    {/* <th className="border-0">Remarks</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(Array.isArray(notificationList) && notificationList?.length > 0) && notificationList?.map((item, index) => {
                                                    return <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{moment(item?.time).format('YYYY-MM-DD')}</td>
                                                        <td>{moment(item?.time).format('HH:mm:ss')}</td>
                                                        <td>{item?.camera}</td>
                                                        <td>{item?.hostel}</td>
                                                        <td>{item?.message}</td>
                                                        {item?.video_url && <td>
                                                            <button type="button" className="btn btn-primary" onClick={() => handleClick(item?.video_url)}>View</button>
                                                        </td>}

                                                    </tr>
                                                })}
                                                <tr>
                                                    <td colspan="9">
                                                        <PaginationComponent onChangeHandle={onChangeHandle} pageCount={count} pageNumber={pageNumber} setpage={setPageNumber} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>}
                                    {notificationList?.length === 0 && <div className="d-flex justify-content-center mt-5 mb-5">
                                        <div className="NodataFound mb-3">
                                            <h3>No Data Found</h3>
                                        </div>
                                        <div className="NodataImage">
                                            <img src={nodatafound} alt="No Data Found" className="NoData" />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                popup && <ViewNotificationVideoPopup onClose={() => onClosePopUp(false)} data={popupData} />

            }


        </>
    )
}
import Actions from "../action";

const initialState = {
    isLoading: false,
    workerList: [],
    error: false,    
    totalCount:null
}
const workerReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        //WORKER_LIST
        case Actions.WORKER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.WORKER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                workerList: action.data?.results || [],
                totalCount:action.data?.count,
                error: false
            };
        }
        case Actions.WORKER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                workerList: [],
                error: action.error,
            };
        }
        //WORKER_DETAILS
        case Actions.WORKER_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.WORKER_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                workerDetails: action.data,
                error: false
            };
        }
        case Actions.WORKER_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                workerDetails: {},
                error: action.error,
            };
        }
        //CREATE_WORKER
        case Actions.CREATE_WORKER: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CREATE_WORKER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CREATE_WORKER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // WORKER_DELETE
        case Actions.WORKER_DELETE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.WORKER_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.WORKER_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // WORKER_UPDATE
        case Actions.WORKER_UPDATE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.WORKER_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.WORKER_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
    // WORKER_ACTIVE
    case Actions.WORKER_ACTIVE: {
        return {
            ...state,
            isLoading: true,
        }
    }
    case Actions.WORKER_ACTIVE_SUCCESS: {
        return {
            ...state,
            isLoading: false,
            activeWorkerDetails: action.data,
            error: false
        }
    }
    case Actions.WORKER_ACTIVE_FAILURE: {
        return {
            ...state,
            isLoading: false,
            error: action.error
        }
    }
        default:
            return state;
    }
};

export default workerReducer;

export const getWorkerListSelector = state => state?.WORKER?.workerList;
export const getActiveWorkerListSelector = state => state?.WORKER?.activeWorkerDetails;
export const getWorkerCountSelector = state => state?.WORKER?.totalCount;
export const getWorkerListLoadingSelector = state => state?.WORKER?.isLoading;
import { doAction, doResponseAction } from ".";

const WORKER_ROOM_LIST = 'WORKER_ROOM_LIST';
const WORKER_ROOM_LIST_SUCCESS = 'WORKER_ROOM_LIST_SUCCESS';
const WORKER_ROOM_LIST_FAILURE = 'WORKER_ROOM_LIST_FAILURE';

const WORKER_ROOM_DETAILS = 'WORKER_ROOM_DETAILS';
const WORKER_ROOM_DETAILS_SUCCESS = 'WORKER_ROOM_DETAILS_SUCCESS';
const WORKER_ROOM_DETAILS_FAILURE = 'WORKER_ROOM_DETAILS_FAILURE';

const CREATE_WORKER_ROOM = 'CREATE_WORKER_ROOM';
const CREATE_WORKER_ROOM_SUCCESS = 'CREATE_WORKER_ROOM_SUCCESS';
const CREATE_WORKER_ROOM_FAILURE = 'CREATE_WORKER_ROOM_FAILURE';

const WORKER_ROOM_DELETE = 'WORKER_ROOM_DELETE';
const WORKER_ROOM_DELETE_SUCCESS = 'WORKER_ROOM_DELETE_SUCCESS';
const WORKER_ROOM_DELETE_FAILURE = 'WORKER_ROOM_DELETE_FAILURE';

const WORKER_ROOM_UPDATE = 'WORKER_ROOM_UPDATE';
const WORKER_ROOM_UPDATE_SUCCESS = 'WORKER_ROOM_UPDATE_SUCCESS';
const WORKER_ROOM_UPDATE_FAILURE = 'WORKER_ROOM_UPDATE_FAILURE';

const WORKER_ROOM_ACTIVE = 'WORKER_ROOM_ACTIVE';
const WORKER_ROOM_ACTIVE_SUCCESS = 'WORKER_ROOM_ACTIVE_SUCCESS';
const WORKER_ROOM_ACTIVE_FAILURE = 'WORKER_ROOM_ACTIVE_FAILURE';

const WORKER_ROOM_MAPPING_LIST = 'WORKER_ROOM_MAPPING_LIST';
const WORKER_ROOM_MAPPING_LIST_SUCCESS = 'WORKER_ROOM_MAPPING_LIST_SUCCESS';
const WORKER_ROOM_MAPPING_LIST_FAILURE = 'WORKER_ROOM_MAPPING_LIST_FAILURE';

export {
    WORKER_ROOM_LIST,
    WORKER_ROOM_LIST_FAILURE,
    WORKER_ROOM_LIST_SUCCESS,

    WORKER_ROOM_DETAILS,
    WORKER_ROOM_DETAILS_FAILURE,
    WORKER_ROOM_DETAILS_SUCCESS,

    CREATE_WORKER_ROOM,
    CREATE_WORKER_ROOM_FAILURE,
    CREATE_WORKER_ROOM_SUCCESS,

    WORKER_ROOM_DELETE,
    WORKER_ROOM_DELETE_FAILURE,
    WORKER_ROOM_DELETE_SUCCESS,

    WORKER_ROOM_UPDATE,
    WORKER_ROOM_UPDATE_FAILURE,
    WORKER_ROOM_UPDATE_SUCCESS,

    WORKER_ROOM_ACTIVE,
    WORKER_ROOM_ACTIVE_FAILURE,
    WORKER_ROOM_ACTIVE_SUCCESS,

    WORKER_ROOM_MAPPING_LIST,
    WORKER_ROOM_MAPPING_LIST_FAILURE,
    WORKER_ROOM_MAPPING_LIST_SUCCESS,
}

// WORKER_ROOM_LIST
export const workerRoomList = (params, callback) => doAction(WORKER_ROOM_LIST, params, callback);
export const workerRoomListSuccess = (data = {}) => doResponseAction(WORKER_ROOM_LIST_SUCCESS, data);
export const workerRoomListFailure = (data = {}) => doResponseAction(WORKER_ROOM_LIST_FAILURE, data);

// WORKER_ROOM_DETAILS
export const workerRoomDetails = (params, callback) => doAction(WORKER_ROOM_DETAILS, params, callback);
export const workerRoomDetailsSuccess = (data = {}) => doResponseAction(WORKER_ROOM_DETAILS_SUCCESS, data);
export const workerRoomDetailsFailure = (data = {}) => doResponseAction(WORKER_ROOM_DETAILS_FAILURE, data);

// CREATE_WORKER_ROOM
export const createWorkerRoom = (params, callback) => doAction(CREATE_WORKER_ROOM, params, callback);
export const createWorkerRoomSuccess = (data = {}) => doResponseAction(CREATE_WORKER_ROOM_SUCCESS, data);
export const createWorkerRoomFailure = (data = {}) => doResponseAction(CREATE_WORKER_ROOM_FAILURE, data);

// WORKER_ROOM_DELETE
export const workerRoomDelete = (params, callback) => doAction(WORKER_ROOM_DELETE, params, callback);
export const workerRoomDeleteSuccess = (data = {}) => doResponseAction(WORKER_ROOM_DELETE_SUCCESS, data);
export const workerRoomDeleteFailure = (data = {}) => doResponseAction(WORKER_ROOM_DELETE_FAILURE, data);

// WORKER_ROOM_UPDATE
export const workerRoomUpdate = (params, callback) => doAction(WORKER_ROOM_UPDATE, params, callback);
export const workerRoomUpdateSuccess = (data = {}) => doResponseAction(WORKER_ROOM_UPDATE_SUCCESS, data);
export const workerRoomUpdateFailure = (data = {}) => doResponseAction(WORKER_ROOM_UPDATE_FAILURE, data);

// WORKER_ROOM_LIST_Mapping
export const workerRoomMappingList = (params, callback) => doAction(WORKER_ROOM_MAPPING_LIST, params, callback);
export const workerRoomMappingListSuccess = (data = {}) => doResponseAction(WORKER_ROOM_MAPPING_LIST_SUCCESS, data);
export const workerRoomMappingListFailure = (data = {}) => doResponseAction(WORKER_ROOM_MAPPING_LIST_FAILURE, data);

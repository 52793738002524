import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { ROOM_LIST } from '../action/RoomListAction'
import { doGet } from '../../service/index';
import END_POINTS from './EndPoints';

export function* roomListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ROOM_LIST_API, params)
        yield put(Actions.roomListSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.roomListFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export default function* roomListSagaWatcher() {
    yield all([
        takeLatest(ROOM_LIST, roomListSaga),
    ]);
}
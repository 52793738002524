import Actions from "../action";

const initialState = {
    isLoading: false,
    cameraList: [],
    totalCount:null,
    error: false,    
}
const cameraReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        //CAMERA_LIST
        case Actions.CAMERA_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CAMERA_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cameraList: action.data?.results,
                totalCount:action.data?.count,
                error: false
            };
        }
        case Actions.CAMERA_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                cameraList: [],
                error: action.error,
            };
        }
        //CAMERA_DETAILS
        case Actions.CAMERA_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CAMERA_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cameraDetails: action.data,
                error: false
            };
        }
        case Actions.CAMERA_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                cameraDetails: {},
                error: action.error,
            };
        }
        //CREATE_CAMERA
        case Actions.CREATE_CAMERA: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CREATE_CAMERA_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CREATE_CAMERA_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // CAMERA_DELETE
        case Actions.CAMERA_DELETE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CAMERA_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CAMERA_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // CAMERA_UPDATE
        case Actions.CAMERA_UPDATE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CAMERA_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CAMERA_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
  
        default:
            return state;
    }
};

export default cameraReducer;

export const getCameraListSelector = state => state?.CAMERA?.cameraList;
export const getCameraCountSelector = state => state?.CAMERA?.totalCount;
export const getCameraListLoadingSelector = state => state?.CAMERA?.isLoading;
import { doAction, doResponseAction } from ".";

const HOSTEL_LIST = 'HOSTEL_LIST';
const HOSTEL_LIST_SUCCESS = 'HOSTEL_LIST_SUCCESS';
const HOSTEL_LIST_FAILURE = 'HOSTEL_LIST_FAILURE';

const HOSTELLER_LIST = 'HOSTELLER_LIST';
const HOSTELLER_LIST_SUCCESS = 'HOSTELLER_LIST_SUCCESS';
const HOSTELLER_LIST_FAILURE = 'HOSTELLER_LIST_FAILURE';

const HOSTEL_LIST_SELECT = 'HOSTEL_LIST_SELECT'
const HOSTEL_LIST_SELECT_SUCCESS = "HOSTEL_LIST_SELECT_SUCCESS"
const HOSTEL_LIST_SELECT_FAILURE = "HOSTEL_LIST_SELECT_FAILURE"

const HOSTEL_DETAILS = 'HOSTEL_DETAILS';
const HOSTEL_DETAILS_SUCCESS = 'HOSTEL_DETAILS_SUCCESS';
const HOSTEL_DETAILS_FAILURE = 'HOSTEL_DETAILS_FAILURE';

const CREATE_HOSTEL = 'CREATE_HOSTEL';
const CREATE_HOSTEL_SUCCESS = 'CREATE_HOSTEL_SUCCESS';
const CREATE_HOSTEL_FAILURE = 'CREATE_HOSTEL_FAILURE';

const HOSTEL_DELETE = 'HOSTEL_DELETE';
const HOSTEL_DELETE_SUCCESS = 'HOSTEL_DELETE_SUCCESS';
const HOSTEL_DELETE_FAILURE = 'HOSTEL_DELETE_FAILURE';

const HOSTEL_UPDATE = 'HOSTEL_UPDATE';
const HOSTEL_UPDATE_SUCCESS = 'HOSTEL_UPDATE_SUCCESS';
const HOSTEL_UPDATE_FAILURE = 'HOSTEL_UPDATE_FAILURE';

const HOSTEL_ACTIVE = 'HOSTEL_ACTIVE';
const HOSTEL_ACTIVE_SUCCESS = 'HOSTEL_ACTIVE_SUCCESS';
const HOSTEL_ACTIVE_FAILURE = 'HOSTEL_ACTIVE_FAILURE';
export {
    HOSTEL_LIST,
    HOSTEL_LIST_FAILURE,
    HOSTEL_LIST_SUCCESS,

    HOSTEL_DETAILS,
    HOSTEL_DETAILS_FAILURE,
    HOSTEL_DETAILS_SUCCESS,

    CREATE_HOSTEL,
    CREATE_HOSTEL_FAILURE,
    CREATE_HOSTEL_SUCCESS,

    HOSTEL_DELETE,
    HOSTEL_DELETE_FAILURE,
    HOSTEL_DELETE_SUCCESS,

    HOSTEL_UPDATE,
    HOSTEL_UPDATE_FAILURE,
    HOSTEL_UPDATE_SUCCESS,

    HOSTEL_ACTIVE,
    HOSTEL_ACTIVE_FAILURE,
    HOSTEL_ACTIVE_SUCCESS,

    HOSTEL_LIST_SELECT,
    HOSTEL_LIST_SELECT_SUCCESS,
    HOSTEL_LIST_SELECT_FAILURE,

    HOSTELLER_LIST,
    HOSTELLER_LIST_SUCCESS,
    HOSTELLER_LIST_FAILURE
}

export const hostelList = (params, callback) => doAction(HOSTEL_LIST, params, callback);
export const hostelListSuccess = (data = {}) => doResponseAction(HOSTEL_LIST_SUCCESS, data);
export const hostelListFailure = (data = {}) => doResponseAction(HOSTEL_LIST_FAILURE, data);

export const hostellerList = (params, callback) => doAction(HOSTELLER_LIST, params, callback);
export const hostellerListSuccess = (data = {}) => doResponseAction(HOSTELLER_LIST_SUCCESS, data);
export const hostellerListFailure = (data = {}) => doResponseAction(HOSTELLER_LIST_FAILURE, data);

export const hostelListSelect = (params, callback) => doAction(HOSTEL_LIST_SELECT, params, callback);
export const hostelListSelectSuccess = (data = {}) => doResponseAction(HOSTEL_LIST_SELECT_SUCCESS, data);
export const hostelListSelectFailure = (data = {}) => doResponseAction(HOSTEL_LIST_SELECT_FAILURE, data);

export const hostelDetails = (params, callback) => doAction(HOSTEL_DETAILS, params, callback);
export const hostelDetailsSuccess = (data = {}) => doResponseAction(HOSTEL_DETAILS_SUCCESS, data);
export const hostelDetailsFailure = (data = {}) => doResponseAction(HOSTEL_DETAILS_FAILURE, data);

export const createHostel = (params, callback) => doAction(CREATE_HOSTEL, params, callback);
export const createHostelSuccess = (data = {}) => doResponseAction(CREATE_HOSTEL_SUCCESS, data);
export const createHostelFailure = (data = {}) => doResponseAction(CREATE_HOSTEL_FAILURE, data);

export const hostelDelete = (params, callback) => doAction(HOSTEL_DELETE, params, callback);
export const hostelDeleteSuccess = (data = {}) => doResponseAction(HOSTEL_DELETE_SUCCESS, data);
export const hostelDeleteFailure = (data = {}) => doResponseAction(HOSTEL_DELETE_FAILURE, data);

export const hostelUpdate = (params, callback) => doAction(HOSTEL_UPDATE, params, callback);
export const hostelUpdateSuccess = (data = {}) => doResponseAction(HOSTEL_UPDATE_SUCCESS, data);
export const hostelUpdateFailure = (data = {}) => doResponseAction(HOSTEL_UPDATE_FAILURE, data);

export const hostelActiveList = (params, callback) => doAction(HOSTEL_ACTIVE, params, callback);
export const hostelActiveListSuccess = (data = {}) => doResponseAction(HOSTEL_ACTIVE_SUCCESS, data);
export const hostelActiveListFailure = (data = {}) => doResponseAction(HOSTEL_ACTIVE_FAILURE, data);

import { doAction, doResponseAction } from ".";

const EDIT_MEMBER_LIST = 'EDIT_MEMBER_LIST';
const EDIT_MEMBER_LIST_SUCCESS = 'EDIT_MEMBER_LIST_SUCCESS';
const EDIT_MEMBER_LIST_FAILURE = 'EDIT_MEMBER_LIST_FAILURE';
export {
    EDIT_MEMBER_LIST,
    EDIT_MEMBER_LIST_FAILURE,
    EDIT_MEMBER_LIST_SUCCESS,
}
// EDIT_MEMBER_LIST
export const editMemberListAction = (params, callback) => doAction(EDIT_MEMBER_LIST, params, callback);
export const editMemberListSuccessAction = data => doResponseAction(EDIT_MEMBER_LIST_SUCCESS, data);
export const editMemberListFailureAction = data => doResponseAction(EDIT_MEMBER_LIST_FAILURE, data);
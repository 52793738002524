import { doAction, doResponseAction } from ".";

const MEMBER = 'MEMBER';
const MEMBER_SUCCESS = 'MEMBER_SUCCESS';
const MEMBER_FAILURE = 'MEMBER_FAILURE';
export {
    MEMBER,
    MEMBER_FAILURE,
    MEMBER_SUCCESS,
}
// member
export const memberAction = (params, callback) => doAction(MEMBER, params, callback);
export const memberSuccessAction = data => doResponseAction(MEMBER_SUCCESS, data);
export const memberFailureAction = data => doResponseAction(MEMBER_FAILURE, data);
import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';
import { DAYWISE_REPORT } from '../action/DayWiseReportAction';



export function* daywiseReportSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.DAYWISE_REPORT_API, params)
        yield put(Actions.daywiseReportSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.daywiseReportFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}


export default function* daywiseReportWatcher() {
    yield all([

        takeLatest(DAYWISE_REPORT, daywiseReportSaga),


    ]);
}
import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { ROOM } from '../action/GetRoomData'
import { doGet } from '../../service/index';
import END_POINTS from './EndPoints';

export function* GetRoomSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ROOM_API, params)
        yield put(Actions.roomSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.roomFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export default function* roomSagaWatcher() {
    yield all([
        takeLatest(ROOM, GetRoomSaga)
    ]);
}
import Actions from "../action";

const initialState = {
    isLoading: false,
    notificationList: [],
    error: false,
    totalCount: null
}
const NotificationListReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        //WORKER_LIST
        case Actions.NOTIFICATION_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.NOTIFICATION_LIST_SUCCESS: {
            console.log(action.data?.results,'notification')
            return {
                ...state,
                isLoading: false,
                notificationList: action.data?.results || [],
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.NOTIFICATION_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                notificationList: [],
                error: action.error,
            };
        }
                default:
            return state;
    }
};

export default NotificationListReducer;

export const getNotificationListSelector = state => state?.NOTIFICATION?.notificationList;

import React, { useEffect, useState } from "react";
import Close from '../asset/img/modal-close.svg'
import { getCourseList,getSectionList,getYearList, getdepartmentList } from "../Redux/reducer/SchedulerReducer";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../Redux/action";
import { toast } from "react-toastify";
import { days } from "../Utils/Constant";
import { useLocation } from "react-router";

const FilterSectionModal = ({ search, collegeId, onClose, year, setYear, course, setCourse,department, setDepartment,onSlotDataChange,setSelectSlot, selectSlot  }) => {
    const courseList = useSelector(getCourseList)
    const departmentList = useSelector(getdepartmentList)
    const yearList = useSelector(getYearList)
    const [slotData,setSlotData]=useState()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const sectionList = useSelector(getSectionList)

    useEffect(() => {
        const params = {
            id: course
        }
        dispatch(Actions.DepartmentAction(params))
    }, [dispatch, course])


    useEffect(() => {
        const params = {
            search,
            college: collegeId,
            course_id: course,
            department: department,
            year: year

        }
        dispatch(Actions.DepartmentSearchSectionList(params))

    }, [search, year, department, course])


    useEffect(() => {
        const params = {
            id: course
        }
        dispatch(Actions.YearAction(params))
    }, [department])

    // const submit = () => {
    //     const params = {
    //         search,
    //         college: collegeId,
    //         course_id: course,
    //         department_id: department,
    //         year: year

    //     }
    //     const callback = (res) => {
    //         if (res?.status_code === 0) {
    //             setCourse("")
    //             setDepartment("")
    //             setYear("")
    //             onClose()
    //             setSlotData(res?.response);
    //             onSlotDataChange(res?.response);
    //         }else{
    //             toast.error(res?.message)
    //         }
    //     }

    //     // setLoader(true)
        
    //     dispatch(Actions.SchedulerListAction(params, callback))
        
    // }


    const submit = () =>{
        const callback = (res) => {
            // setLoader(false)
            let mainData = {}
            if (res?.status_code === 0) {
                setCourse("")
                setDepartment("")
                setYear("")
                onClose()
                days?.forEach((dObj, dIndex) => {
                    res?.response?.forEach((cObj, cIndex) => {
                        if (dObj.type === cObj.day) {
                            if (mainData[dObj.day]) {
                                mainData[dObj.day].push(cObj)
                            } else {
                                mainData[dObj.day] = [cObj]
                            }
                        }
                    })
                })
            }
            if (state?.room_slots?.length > 0) {
                let slotsArr = []
                Object.entries(mainData).forEach(([day, items]) => {
                    items?.forEach(element => {
                        state?.room_slots?.forEach((rs) => {
                            if (rs?.slot === element?.id) {
                                const checkSlot = selectSlot?.findIndex((c) => c?.slot === rs.slot)
                                if (checkSlot === -1) {
                                    const slot = {
                                        section_id: Number(rs?.section),
                                        slot_id: Number(element?.id),
                                        day: Number(rs?.day)
                                    }
                                    slotsArr.push(slot)
                                }

                                element['section_id'] = rs?.section
                                element['section_name'] = Array.isArray(sectionList) && sectionList?.find((f) => f?.id === rs?.section)?.name
                                element['department_id'] = rs?.department_id
                                element['department_name'] = Array.isArray(departmentList) && departmentList?.find((f) => f?.id === rs?.department_id)?.name
                                element['year_id'] = rs?.year_id
                                element['year_name'] = Array.isArray(yearList) && yearList?.find((f) => f?.id === rs?.year_id)?.name
                            }
                        })
                    })
                })
                setSelectSlot(slotsArr)
            }
            setSlotData(mainData);
            onSlotDataChange(mainData);
        }
        const params = {
            search,
            college: collegeId,
            course_id: course,
            department_id: department,
            year: year

        }
        dispatch(Actions.SchedulerListAction(params, callback))
    }

    return <>
        <div className="modal d-block"
            id="view-multi-modal"
            tabIndex="-1"
            aria-labelledby="view-multi-modal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg planogram-div">
                <div className="modal-content rounded-0 p-2">
                    <img id="close"
                        src={Close}
                        alt="X"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="modal-close"
                        onClick={() => onClose()}
                    />
                    <div className="modal-header rounded-0 align-items-center py-4 px-4">
                        <div className="col">
                            <h4 className="modal-title">Section filter</h4>
                        </div>
                    </div>
                    <div className="modal-body font14 p-40">
                        <div className="container">
                            <div className="row">
                                <div className="row">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Course</label>
                                            <select value={course} onChange={(e) => setCourse(e?.target?.value)}
                                                className="form-select">
                                                <option value='' hidden>Select Course</option>
                                                {Array.isArray(courseList) && courseList?.map((o) => {
                                                    return <option value={o?.id}>{o?.name}</option>

                                                })
                                                }

                                            </select>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Department</label>
                                            <select disabled={course ? false : true} value={department} onChange={(e) => setDepartment(e?.target?.value)}
                                                className="form-select">
                                                <option value='' hidden>Select Department</option>
                                                {Array.isArray(departmentList) && departmentList?.map((o) => {
                                                    return <option value={o?.id}>{o?.name}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Year</label>
                                            <select value={year} disabled={department ? false : true} onChange={(e) => setYear(e?.target?.value)}
                                                className="form-select">
                                                <option value='' hidden>Select Year</option>
                                                {Array.isArray(yearList) && yearList?.map((o) => {
                                                    return <option value={o?.id}>{o?.name}</option>
                                                })}
                                            </select>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="text-end">
                        <button
                            type="button"
                            className="btn"
                            style={{ backgroundColor: 'lightgray' }}
                            onClick={()=>onClose()}
                        >Cancel
                        </button>

                        <button className="btn btn-primary submitRoom" style={{ marginLeft: '20px' }} onClick={() => submit()}>Submit</button>

                    </div>
                </div>

            </div>

        </div>
        <div className="modal-backdrop show"></div>
    </>
}
export default FilterSectionModal
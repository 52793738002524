import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { LOGIN, LOGOUT } from '../action/LoginActions'
import { doPost, login } from '../../service/index';
import END_POINTS from './EndPoints';

export function* loginSaga({ params, callback }) {
    try {
        const response = yield login(END_POINTS.LOGIN_API, params)
        yield put(Actions.loginSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.loginFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* logOutSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.LOGOUT_API, params)
        yield put(Actions.logoutSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.logoutFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* loginWatcher() {
    yield all([
        takeLatest(LOGIN, loginSaga),
        takeLatest(LOGOUT, logOutSaga)
    ]);
}
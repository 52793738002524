import Actions from "../action";

const initialState = {
    isLoading: false,
    hostelList: [],
    hostelListSelect: [],
    hostellerList: [],
    error: false,
    totalCount: null
}
const HostelReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        //WORKER_LIST
        case Actions.HOSTEL_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HOSTEL_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                hostelList: action.data?.results || [],
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.HOSTEL_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                hostelList: [],
                error: action.error,
            };
        }
        case Actions.HOSTEL_LIST_SELECT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HOSTEL_LIST_SELECT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                hostelListSelect: action.data?.message || [],
                error: false
            };
        }
        case Actions.HOSTEL_LIST_SELECT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                hostelListSelect: [],
                error: action.error,
            };
        }
        case Actions.HOSTELLER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HOSTELLER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                hostellerList: action.data?.results || [],
                error: false
            };
        }
        case Actions.HOSTELLER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                hostellerList: [],
                error: action.error,
            };
        }
        //WORKER_DETAILS
        case Actions.HOSTEL_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HOSTEL_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                hostelDetails: action.data,
                error: false
            };
        }
        case Actions.HOSTEL_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                hostelDetails: {},
                error: action.error,
            };
        }
        //CREATE_WORKER
        case Actions.CREATE_HOSTEL: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CREATE_HOSTEL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CREATE_HOSTEL_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // WORKER_DELETE
        case Actions.HOSTEL_DELETE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.HOSTEL_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.HOSTEL_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // WORKER_UPDATE
        case Actions.HOSTEL_UPDATE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.HOSTEL_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.HOSTEL_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // WORKER_ACTIVE
        case Actions.HOSTEL_ACTIVE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.HOSTEL_ACTIVE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                activeHostelDetails: action.data,
                error: false
            }
        }
        case Actions.HOSTEL_ACTIVE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        default:
            return state;
    }
};

export default HostelReducer;

export const getHostelListSelector = state => state?.HOSTEL?.hostelList;
export const getHostellerListSelector = state => state?.HOSTEL?.hostellerList;
export const getHostelListSelectSelector = state => state?.HOSTEL?.hostelListSelect;
export const getHostelCountSelector = state => state?.HOSTEL?.totalCount;
export const getHostelListLoadingSelector = state => state?.HOSTEL?.isLoading;

export const isValidEmail = (email) => {
    const validEmailRegex = RegExp(/^[a-zA-Z0-9+.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/)
    return validEmailRegex.test(email);
}

export const isFieldEmpty = (field) => {
    return (!field || field?.trim().length === 0)
}
export const isSelectEmpty = (field) => {
    return (Object?.keys(field)?.length === 0)
}

export const validatePriceValue = (name) => {
    let re = /^\d{0,8}(\.\d{1,4})?$/;
    return re.test(name);
}

export const skuRegex = /^[A-Z]{3}-[0-9]{5}$/;
export const uppercaseRegExp = /(?=.*?[A-Z])/;
export const lowercaseRegExp = /(?=.*?[a-z])/;
export const digitsRegExp = /(?=.*?\d)/;
export const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
export const minLengthRegExp = /.{8,}/;
export const whiteSpaceValidation = (str) => {
    const validEmailRegex = RegExp('^[a-zA-Z0-9_]*$')
    return validEmailRegex.test(str);
}

export const validateName = (name) => {
    let re = /^[a-zA-Z ]{3,30}$/;
    return re.test(String(name).toLowerCase());
}
export const validateMaxName = (name) => {
    let re = /^[a-zA-Z ]{4,150}$/;
    return re.test(String(name).toLowerCase());
}
export const validateMaxProduct = (name) => {
    let re = /^[a-zA-Z ]{4,50}$/;
    return re.test(String(name).toLowerCase());
}
export const validateSku = (name) => {
    let re = /^[a-zA-Z0-9\s,.'-]{4,50}$/;
    return re.test(name);
}
export const validateMaxAddress = (name) => {
    let re = /^[a-zA-Z0-9\s,.'-]{4,200}$/;
    return re.test(name);
}
export const validateMobileNumnber = (number) => {
    let re = /^(\+91[\-\s]?)?[0]?(91)?[789654]\d{9}$/;
    return re.test(number);
}
export const validateuppercaseRegExp = (name) => {
    let re = /(?=.*?[A-Z])/;
    return re.test(String(name));
}
export const validatelowercaseRegExp = (name) => {
    let re = /(?=.*?[a-z])/;
    return re.test(String(name));
}
export const validatedigitsRegExp = (name) => {
    let re = /(?=.*?\d)/;
    return re.test(name);
}
export const validatespecialCharRegExp = (name) => {
    let re = /(?=.*?[#?!@$%^&*-])/;
    return re.test(name);
}
export const validateminLengthRegExp = (name) => {
    let re = /.{5,}/;
    return re.test(name);
}
export const validateminLength = (name) => {
    let re = /.{4,}/;
    return re.test(name);
}
export const validateminserverLengthRegExp = (name) => {
    let re = /.{3,}/;
    return re.test(name);
}
export const validateminLengthReg = (name) => {
    let re = /.{4,}/;
    return re.test(name);
}
export const validatemaxLengthZipReg = (name) => {
    let re = /^.{30,}$/;
    return re.test(name);
}
export const validatemaxLength = (name) => {
    let re = /^.{200,}$/;
    return re.test(name);
}
export const validatemaxLengthZipRegExp = (name) => {
    let re = /^.{10,}$/;
    return re.test(name);
}

export const validatemaxLengthCameraName = (name) => {
    let re = /^.{15,}$/;
    return re.test(name);
}
export const validatemaxLengthNumeberRegExp = (name) => {
    let re = /^.{10,}$/;
    return re.test(name);
}
export const validateCameraPoints = (name) => {
    let re = /^(0(\.\d+)?|1(\.0+)?)$/;
    return re.test(name);
}
export const checkPercentage = (field) => {
    return (Math.ceil(field) > 100)
}
// Regular expression for IPv4 address
export const validateIPV4Regex = (name) => {
    let re = /^(\d{1,3}\.){3}\d{1,3}$/;
    return re.test(name);
}
export const validateIPV6Regex = (name) => {
    let re = /^(?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}$/i;
    return re.test(name);
}
// Regular expression for MAC address
export const validateMacRegex = (name) => {
    let re = /^([0-9A-Fa-f]{2}[:-]){5}[0-9A-Fa-f]{2}$/;
    return re.test(name);
}


export const HostelSpecialAccessValidation = (validationObj, fieldArray) => {
    let validationError = {}
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please enter the valid Member id'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[1]:
                if (value == '') {
                    validationError[key] = 'Please select hostel'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[2]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please select the start time'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[3]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please select the end time'
                    break;
                }case fieldArray[4]: // fromDate
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please select the from date';
                } else {
                    validationError[key] = '';
                }
                break;
            case fieldArray[5]: // toDate
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please select the to date';
                } else {
                    validationError[key] = '';
                }
                break;
                case fieldArray[6]: // toDate
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please select the entry type';
                } 
                else {
                    validationError[key] = '';
                }
                break;
        
            default:
                break;
        }
    }
    return validationError;
}

export const CameraValidation = (validationObj, fieldArray) => {
    let validationError = {}
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please enter the valid Name'
                    break;
                } else if (validatemaxLengthCameraName(value)) {
                    validationError[key] = 'Camera name should be less than 15 characters'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[1]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please enter the valid URL'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[2]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please enter the Divider x1'
                    break;
                } else if (!validateCameraPoints(value)) {
                    validationError[key] = 'Please enter the valid Divider x1'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[3]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please enter the Divider y1'
                    break;
                } else if (!validateCameraPoints(value)) {
                    validationError[key] = 'Please enter the valid Divider y1'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[4]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please enter the Divider x2'
                    break;
                } else if (!validateCameraPoints(value)) {
                    validationError[key] = 'Please enter the valid Divider x2'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[5]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please enter the Divider y2'
                    break;
                } else if (!validateCameraPoints(value)) {
                    validationError[key] = 'Please enter the valid Divider y2'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            default:
                break;
        }
    }
    return validationError;
}
export const WorkerValidation = (validationObj, fieldArray) => {
    let validationError = {}
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isFieldEmpty(value) || !validateName(value)) {
                    validationError[key] = 'Please enter the valid worker name'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[1]:
                if (isFieldEmpty(value) || !validateMacRegex(value)) {
                    validationError[key] = 'Please enter the valid mac address'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[2]:
                if (isFieldEmpty(value) || !validateIPV4Regex(value)) {
                    validationError[key] = 'Please enter the valid ip address'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            default:
                break;

        }
    }
    return validationError;
}
export const HostelValidation = (validationObj, fieldArray) => {
    let validationError = {}
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please enter the valid hostel name'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[1]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please enter the valid hostel number'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[2]:
                if (!value) {
                    validationError[key] = 'Please enter the valid college name'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            default:
                break;

        }
    }
    return validationError;
}
export const WorkerRoomValidation = (validationObj, fieldArray) => {
    let validationError = {}
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please enter the valid Name'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[1]:
                if (isFieldEmpty(value)) {
                    validationError[key] = 'Please enter the valid URL'
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            default:
                break;
        }
    }
    return validationError;
}

export const convertToBase64 = (file) => new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        console.log(reader.result);
        resolve(reader.result)
    };
    reader.onerror = (error) => {
        console.log('Error: ', error);
        reject(error)
    };

})

import React from "react";
const SignUpScreen = () => {
    return (
        <>
            <div className='login-body'>
                <div className="splash-container">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="mb-1">SignUp</h3>
                            <p>Please enter your user information.</p>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <input className="form-control form-control-lg" type="text" name="nick" required="" placeholder="Username" autocomplete="off" />
                            </div>
                            <div className="form-group">
                                <input className="form-control form-control-lg" type="email" name="email" required="" placeholder="E-mail" autocomplete="off" />
                            </div>
                            <div className="form-group">
                                <input className="form-control form-control-lg" id="pass1" type="password" required="" placeholder="Password" />
                            </div>
                            <div className="form-group">
                                <input className="form-control form-control-lg" required="" placeholder="Confirm" />
                            </div>
                            <div className="form-group pt-2">
                                <button className="btn btn-block btn-primary" type="submit">Register My Account</button>
                            </div>
                        </div>
                        <div className="card-footer bg-white">
                            <p>Already member? <a href="/" className="text-secondary">Login Here.</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default SignUpScreen;

import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { DASHBOARD } from '../action/DashBoardAction'
import { doGet } from '../../service/index';
import END_POINTS from './EndPoints';

export function* dashboardSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.DASHBOARD_API, params)
        yield put(Actions.dashboardSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.dashboardFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* dashboardWatcher() {
    yield all([
        takeLatest(DASHBOARD, dashboardSaga),
    ]);
}
import Actions from "../action";

const initialState = {
    isLoading: false,
    HostelSpecialAccessList: [],
    totalCount:null,
    error: false,    
}
const HostelSpecialAccessReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        //CAMERA_LIST
        case Actions.HOSTEL_SPECIAL_ACCESS_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HOSTEL_SPECIAL_ACCESS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                HostelSpecialAccessList: action.data?.results,
                totalCount:action.data?.count,
                error: false
            };
        }
        case Actions.HOSTEL_SPECIAL_ACCESS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                HostelSpecialAccessList: [],
                error: action.error,
            };
        }
        //HOSTEL_SPECIAL_ACCESS_DETAILS
        case Actions.HOSTEL_SPECIAL_ACCESS_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HOSTEL_SPECIAL_ACCESS_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                hostelSpecialAccessDetails: action.data,
                error: false
            };
        }
        case Actions.HOSTEL_SPECIAL_ACCESS_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                hostelSpecialAccessDetails: {},
                error: action.error,
            };
        }
        //CREATE_HOSTEL_SPECIAL_ACCESS
        case Actions.CREATE_HOSTEL_SPECIAL_ACCESS: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CREATE_HOSTEL_SPECIAL_ACCESS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CREATE_HOSTEL_SPECIAL_ACCESS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // HOSTEL_SPECIAL_ACCESS_DELETE
        case Actions.HOSTEL_SPECIAL_ACCESS_DELETE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.HOSTEL_SPECIAL_ACCESS_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.HOSTEL_SPECIAL_ACCESS_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
    
  
        default:
            return state;
    }
};

export default HostelSpecialAccessReducer;

export const getHostelSpecialAccessListSelector = state => state?.HOSTEL_SPECIAL_ACCESS?.HostelSpecialAccessList;
export const getHostelSpecialAccessCountSelector = state => state?.HOSTEL_SPECIAL_ACCESS?.totalCount;
export const getHostelSpecialAccessListLoadingSelector = state => state?.HOSTEL_SPECIAL_ACCESS?.isLoading;
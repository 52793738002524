import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Close from '../asset/img/modal-close.svg';
import Actions from '../Redux/action';
import { HostelSpecialAccessValidation } from '../Utils/Validations';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { IMAGE_URL } from '../Redux/saga/EndPoints';
import { getHostelListSelectSelector } from '../Redux/reducer/HostelReducer';
import { getCollegeList } from '../Redux/reducer/SchedulerReducer';
import { onboardingData } from '../Redux/reducer/OnboardingList';
import { searchList } from '../Redux/reducer/SearchList';
import AppLoader from './AppLoader';

export const AddHostelSpecialAccessPopup = ({ onClose, data, pageNumber }) => {
    const dispatch = useDispatch();
    const [formDetails, setFormDetails] = useState({ ...data });
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [timeError, setTimeError] = useState('');
    const hostelList = useSelector(getHostelListSelectSelector);
    const currentDate = new Date().toISOString().split('T')[0];
    const collegelist = useSelector(getCollegeList);
    const [memberList, setMemberList] = useState([]);
    const onboardedList = useSelector(onboardingData);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const searchData = useSelector(searchList);
    const [isLoading, setLoading] = useState(false);
    const [showList, setShowList] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormDetails((prevState) => ({
            ...prevState,
            [name]: value,
            ...(name === 'fromDate' && { toDate: '', end_time: '' }) 
        }));
        setErrors((prevState) => ({
            ...prevState,
            [name]: ''
        }));

        if (name === 'member_id') {
            filterMembers(value);
            setShowSuggestions(true);
        }
    };

    const handleOnchangeSearch = (event) => {
        const callback = (response) => {
            dispatch(Actions.onboardingSearchAction(response));
            setLoading(false);
        };
        const callBack = (response) => {
            dispatch(Actions.searchSuccessAction(response));
            setLoading(false);
        };
        if ((event.target.value)?.length >= 3) {
            setShowList(true);
            let params = {
                searching_text: event.target.value,
                hostel_id: formDetails?.hostel_id,
                person_type: 1
            };

            setLoading(true);
            dispatch(Actions.searchAction(params, callback));
            dispatch(Actions.hostellerList(params, callBack));
        }
    };

    const filterMembers = (input) => {
        if (input) {
            const filtered = onboardedList?.results?.filter(member =>
                member.member_id.toString().toLowerCase().includes(input.toLowerCase()) ||
                member.name.toLowerCase().includes(input.toLowerCase())
            );
            setFilteredMembers(filtered);
        } else {
            setFilteredMembers([]);
        }
    };

    const handleSuggestionClick = (id) => {
        setFormDetails({ ...formDetails, member_id: id });
        setShowSuggestions(false);
    };

    useEffect(() => {
        if (Object.keys(formDetails)?.length !== 0) {
            let validatedError = HostelSpecialAccessValidation(
                formDetails,
                ["member_id", "hostel_id", "start_time", "end_time", "fromDate", "toDate", "entry_type"]
            );
            if (validatedError) {
                setErrors(validatedError);
            }
        }
    }, [formDetails]);

    useEffect(() => {
        const isValid = ["member_id", "hostel_id", "start_time", "end_time", "fromDate", "toDate", "entry_type"].every(
            (field) => formDetails[field]
        );
        setIsFormValid(isValid);
    }, [formDetails]);

    const handleHostelSelect = (selected) => {
        setFormDetails({ ...formDetails, 'hostel_id': parseInt(selected) });
        setErrors({ ...errors, "hostel_id": "" });
        memberFilterList(parseInt(selected));
    };

    const getHostelList = useCallback(() => {
        dispatch(Actions.hostelListSelect(""));
    }, [dispatch]);

    useEffect(() => {
        getHostelList();
    }, [getHostelList]);

    const handleTimeChange = (value, time) => {
        if (value) {
            setFormDetails((prevState) => ({
                ...prevState,
                [time]: moment(value).format('HH:mm:ss')
            }));

            setErrors((prevState) => ({
                ...prevState,
                [time]: ''
            }));

            // Time range validation when end time is selected
            if (time === 'end_time') {
                const startDateTime = moment(`${formDetails?.fromDate} ${formDetails?.start_time}`);
                const endDateTime = moment(`${formDetails?.toDate} ${moment(value).format('HH:mm:ss')}`);
                const timeDifference = endDateTime.diff(startDateTime, 'days', true);

                if (timeDifference > 1) {
                    setTimeError("Start time and end time must be within a one-day range.");
                } else {
                    setTimeError('');
                }
            }
        }
    };

    const memberFilterList = (hostel_id) => {
        const callback = (response) => {
            dispatch(Actions.onboardingSearchAction(response));
        };
        let params = {
            hostel_id: hostel_id,
            page_size: 1000
        };
        dispatch(Actions.hostellerList(params, callback));
    };

    const onSearch = (searchProduct) => {
        const callback = (response) => {
            dispatch(Actions.onboardingSearchAction(response));
            setLoading(false);
        };
        setShowList(false);
        setLoading(true);
        let params = {
            id: searchProduct
        };
        dispatch(Actions.searchListAction(params, callback));
    };

    useEffect(() => {
        if (formDetails?.hostel_id) {
            memberFilterList(formDetails?.hostel_id);
        }
    }, [formDetails?.hostel_id]);

    const createHostelSpecialAccess = () => {
        const startDateTime = moment(`${formDetails?.fromDate} ${formDetails?.start_time}`);
        const endDateTime = moment(`${formDetails?.toDate} ${formDetails?.end_time}`);
        const timeDifference = endDateTime.diff(startDateTime, 'days', true);

        if (timeDifference > 1) {
            setTimeError("Start time and end time must be within a one-day range.");
            return;
        } else {
            setTimeError('');
        }

        let validatedError = HostelSpecialAccessValidation(formDetails, ["member_id", "hostel_id", "start_time", "end_time", "fromDate", "toDate", "entry_type"]);
        if (Object.keys(validatedError).some(key => validatedError[key])) {
            setErrors(validatedError);
            return;
        }

        const createCallBack = (response) => {
            if (response?.status_code === 0) {
                toast.success("Hostel special access successfully created");
                onClose();
                let params = {
                    page: pageNumber
                };
                dispatch(Actions.hostelSpecialAccessList(params));
            } else {
                toast.error(response?.message);
            }
        };

        let params = {
            member_id: formDetails?.member_id,
            hostel_id: formDetails?.hostel_id,
            start_time: `${moment(formDetails?.fromDate).format('YYYY-MM-DD')} ${formDetails?.start_time}`,
            end_time: `${moment(formDetails?.toDate).format('YYYY-MM-DD')} ${formDetails?.end_time}`,
            entry_type: formDetails?.entry_type
        };
        dispatch(Actions.createhostelSpecialAccess(params, createCallBack));
    };

    const onClosePopUp = () => {
        setFormDetails('');
        onClose();
    };

    return (
        <>
            {isLoading && <AppLoader />}
            <div className="modal cameraModel d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg planogram-div">
                    <div className="modal-content rounded-0">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() => onClosePopUp()} />
                        <div className="modal-header rounded-0 align-items-center py-4 px-4">
                            <div className="col">
                                <h4 className="modal-title">Add Hostel Special Access</h4>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Hostel</label>
                                            <select value={formDetails?.hostel_id} onChange={(e) => handleHostelSelect(e?.target?.value)}
                                                className="form-select w-75">
                                                <option value='' hidden>Select Hostel</option>
                                                {Array.isArray(hostelList) && hostelList?.map((o) => {
                                                    return <option key={o?.id} value={o?.id}>{o?.name}</option>;
                                                })}
                                            </select>
                                            {errors?.hostel_id && <p className='errorMessage'>{errors?.hostel_id}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3 position-relative">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Member Id</label>
                                            <input value={formDetails?.member_id} name='member_id' disabled={!formDetails?.hostel_id} onChange={handleChange} type="text" className="form-control w-75" id="exampleInputEmail1"
                                                placeholder='Type here' />
                                            {errors?.member_id && <p className='errorMessage'>{errors?.member_id}</p>}
                                            {showSuggestions && filteredMembers?.length > 0 && (
                                                <div className="dropdown dropdown-position">
                                                    {filteredMembers?.map((member, index) => (
                                                        <div key={index} className="dropdown-item" onClick={() => handleSuggestionClick(member?.member_id)}>
                                                            <img src={IMAGE_URL + member.img_url} alt="search" style={{ width: "40px", height: "40px", marginRight: "10px" }} />
                                                            {member?.name}, {member?.member_id}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">From Date</label>
                                            <input type="date" className="form-control w-75" value={formDetails?.fromDate} min={currentDate} disabled={!formDetails?.member_id} name='fromDate' id="exampleInputEmail1" onChange={handleChange} />
                                            {errors?.fromDate && <p className='errorMessage'>{errors?.fromDate}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <label className="dateLabel">Start Time</label>
                                        <div className="input-group w-75">
                                            <TimePicker
                                                showSecond={false}
                                                format={'HH:mm'}
                                                disabled={!formDetails?.fromDate}
                                                onChange={(v) => handleTimeChange(v, "start_time")}
                                                value={formDetails?.start_time ? moment(formDetails?.start_time, 'HH:mm') : moment().set({ hour: 0, minute: 0, second: 0 })}
                                                disableClock={true}
                                                className="form-control cursor-pointer"
                                            />
                                        </div>
                                        {errors?.start_time && <p className='errorMessage'>{errors?.start_time}</p>}
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">To Date</label>
                                            <input type="date" disabled={!formDetails?.start_time} className="form-control w-75" max={formDetails?.fromDate ? moment(formDetails.fromDate).add(1, 'days').format('YYYY-MM-DD') : ''} name='toDate' min={formDetails?.fromDate} value={formDetails?.toDate} id="exampleInputEmail1" onChange={handleChange} />
                                            {errors?.toDate && <p className='errorMessage'>{errors?.toDate}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <label className="dateLabel">End Time</label>
                                        <div className="input-group w-75">
                                            <TimePicker
                                                showSecond={false}
                                                format={'HH:mm'}
                                                disabled={!formDetails?.toDate}
                                                clearIcon={false}
                                                onChange={(v) => handleTimeChange(v, "end_time")}
                                                value={formDetails?.end_time ? moment(formDetails?.end_time, 'HH:mm') : moment().set({ hour: 0, minute: 0, second: 0 })}
                                                disableClock={true}
                                                className="form-control dateInput cursor-pointer"
                                            />
                                        </div>
                                        {errors?.end_time && <p className='errorMessage'>{errors?.end_time}</p>}
                                        {timeError && <p className='errorMessage'>{timeError}</p>}
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Entry Type</label>
                                            <select value={formDetails?.entry_type} onChange={handleChange} disabled={!formDetails?.end_time} name="entry_type"
                                                className="form-select w-75">
                                                <option value='' hidden>Select entry type</option>
                                                <option value='0'>Entry</option>
                                                <option value='1'>Exit</option>
                                                <option value='2'>Multiple</option>
                                            </select>
                                            {errors?.entry_type && <p className='errorMessage'>{errors?.entry_type}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row-3">
                                    <button className="btn btn-primary submitRoom" onClick={createHostelSpecialAccess} disabled={!isFormValid || timeError}>{formDetails?.id ? "Update" : "Create"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>
    );
};

import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import Close from '../asset/img/modal-close.svg'
import Actions from '../Redux/action';
import AppLoader from './AppLoader';
import WorkerTypeAhead from './WorkerTypeAhead';
import WorkerRoomTypeAhead from './WorkerRoomTypeAhead';
export const AddWorkerRoomPopup = ({ onClose, data, pageNumber }) => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false)
    const [formDetails, setFormDetails] = useState({ ...data });
    const optionsData = data?.id ? [{ label: data?.worker_name, value: data?.id }] : null
    const optionsRoomData = data?.id ? data?.rooms?.map(d => ({ label: d?.room_name, value: d?.id })) : null
    const [errors, setErrors] = useState();
  
   
    const handleWorkerSelect = (selected) => {
        setFormDetails({ ...formDetails, 'worker_id': selected })
    };
    const handleWorkerRoomSelect = (selected) => {
        setFormDetails({ ...formDetails, 'rooms': selected })
    };
    const createWorkerRoom = () => {
        if (!formDetails?.worker_id && !formDetails?.worker_name) {
            setErrors({ ...errors, worker_id: "Please choose the Worker" })
        } else if (!formDetails?.rooms) {
            setErrors({ ...errors, rooms: "Please choose the select Room" })
        } else {
            if (formDetails?.id) {
                let convertArray = formDetails?.rooms?.map(d => ({ id: d?.value }))
                const hasValue = convertArray.some(obj => {
                    return Object.values(obj).some(val => val !== null && val !== undefined && val !== '');
                });
                let convertedArray = formDetails?.rooms?.map(d => ({ id: d?.id }))
                const updateCallBack = (response) => {
                    setLoading(false)
                    if (response?.status_code === 0) {
                        toast.success("Worker room successfully updated")
                        onClose()
                        let params = {
                            page: pageNumber,
                            mapping:1
                        }
                        dispatch(Actions.workerRoomList(params))
                    } else {
                        toast.error(response?.message)
                    }
                }
                let params;
                if (hasValue) {
                    params = {
                        worker_id: formDetails?.worker_id?.value || formDetails?.id,
                        rooms: convertArray,
                    }
                } else {
                    params = {
                        worker_id: formDetails?.worker_id?.value || formDetails?.id,
                        rooms: convertedArray,
                    }
                }
                setLoading(true)
                dispatch(Actions.workerRoomUpdate(params, updateCallBack))
            } else {
                let convertArray = formDetails?.rooms?.map(d => ({ id: d?.value }))
                const createCallBack = (response) => {
                    setLoading(false)
                    if (response?.status_code === 0) {
                        toast.success("Worker room successfully created")
                        onClose()
                        let params = {
                            page: pageNumber,
                            mapping:1
                        }
                        dispatch(Actions.workerRoomList(params))
                    } else {
                        toast.error(response?.message)
                    }
                }
                let params = {
                    worker_id: formDetails?.worker_id?.value,
                    rooms: convertArray,
                }
                setLoading(true)
                dispatch(Actions.createWorkerRoom(params, createCallBack))
            }
        }
    }

    return (
        <>
            {
                isLoading && <AppLoader />
            }

            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered planogram-div">
                    <div className="modal-content rounded-0">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() => onClose()} />
                        <div className="modal-header rounded-0 align-items-center py-4 px-4">
                            <div className="col">
                                <h4 className="modal-title">Add Worker Room</h4>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Worker Name</label>
                                            <WorkerTypeAhead
                                                id="WorkerTypeAhead"
                                                style={{ margin: '10px' }}
                                                placeholder="Select worker"
                                                isMulti={false}
                                                defaultValue={optionsData}
                                                onChange={handleWorkerSelect}
                                                disabled
                                            />
                                            {errors?.worker_id && <p className='errorMessage'>{errors?.worker_id}</p>}

                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Worker Room</label>
                                            <WorkerRoomTypeAhead
                                                id="WorkerTypeAhead"
                                                style={{ margin: '10px' }}
                                                placeholder="Select Room"
                                                isMulti={true}
                                                defaultValue={optionsRoomData}
                                                onChange={handleWorkerRoomSelect}
                                                disabled
                                            />
                                            {errors?.rooms && <p className='errorMessage'>{errors?.rooms}</p>}

                                        </div>
                                    </div>
                                </div>
                                <div className="row-3">
                                    <button className="btn btn-primary submitRoom" onClick={() => createWorkerRoom()}>{formDetails?.id ? "Update" : "Create"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>

        </>
    )
}
import Actions from "../action";

const initialState = {
    isLoading: false,
    masterSlot: [],
    error: false
}
const MasterSlotReducers = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.MASTER_SLOT_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MASTER_SLOT_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                masterSlot: action.data,
                error: false
            };
        }
        case Actions.MASTER_SLOT_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                masterSlot: undefined,
                error: true,
            };
        }
        case Actions.MASTER_SLOT_SAVE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MASTER_SLOT_SAVE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                masterSlot: action.data,
                error: false
            };
        }
        case Actions.MASTER_SLOT_SAVE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                masterSlot: undefined,
                error: true,
            };
        }
        case Actions.DELETE_SLOT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DELETE_SLOT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                masterSlot: action.data,
                error: false
            };
        }
        case Actions.DELETE_SLOT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                masterSlot: undefined,
                error: true,
            };
        }
        default:
            return state;
    }
};
// export const memberData = (state) => state?.MEMBER_CRED?.member?.response;
export default MasterSlotReducers;
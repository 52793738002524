import Actions from "../action";

const initialState = {
    isLoading: false,
    attendance: undefined,
    hostel_attendance: undefined,
    error: false
}
const AttendanceReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.ATTENDANCE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ATTENDANCE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                attendance: action.data?.response || [],
                error: false
            };
        }
        case Actions.ATTENDANCE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                attendance: undefined,
                error: true,
            };
        }
        case Actions.HOSTEL_ATTENDANCE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HOSTEL_ATTENDANCE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                hostel_attendance: action.data?.response || [],
                error: false
            };
        }
        case Actions.HOSTEL_ATTENDANCE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                hostel_attendance: undefined,
                error: true,
            };
        }

        case Actions.CLEAR_REPORTS: {
            return {
                ...state,
                isLoading: false,
                attendance: [],
               
            };
        }
        default:
            return state;
    }
};
export const getReports = (state) => state?.ATTENDANCE_CREDS?.attendance;
export const getHostelReports = (state) => state?.ATTENDANCE_CREDS?.hostel_attendance;


export default AttendanceReducer;
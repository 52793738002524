import React, { useState } from "react"
import AddMasterSlot from "../component/AddMasterSlot";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../Redux/action";
import { getCollegeList, getCourseList, getSchedulerList, getSelectedData, getdepartmentList } from "../Redux/reducer/SchedulerReducer";
import { useEffect } from "react";
import { days } from "../Utils/Constant";
import { toast } from "react-toastify";
import { DeclinePopup } from "../component/DeclinePopup";
import nodatafound from '../asset/img/datanotfound_black.png';
import AppLoader from "../component/AppLoader";

const MasterSlots = () => {
    const dispatch = useDispatch()
    const [schedulerData, setSchedulerData] = useState([])
    const [openaddMasterSlotModal, setOpenAddMasterSlotMoal] = useState(false)
    const [openEditMasterSlotModal, setOpenEditMasterSlotMoal] = useState(false)
    const [updateData, setUpdateData] = useState({})
    const [height, setHeight] = useState(window.innerHeight * 0.56)
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);
    const [itemObj, setItemObj] = useState('')
    const collegeList = useSelector(getCollegeList)
    const courseList = useSelector(getCourseList)
    const departmentList = useSelector(getdepartmentList)
    const selctedFormData = useSelector(getSelectedData);
    const [formDetails, setFormDetails] = useState({});
    const [isloading, setLoading] = useState(false)

    const getMasterSLotList = () => {
        setLoading(true)
        const callback = (res) => {
            let mainData = {}
            setLoading(false)
            if (res?.status_code === 0) {
                days?.forEach((dObj, dIndex) => {
                    res?.response?.forEach((cObj, cIndex) => {
                        if (dObj.type === cObj.day) {
                            if (mainData[dObj.day]) {
                                mainData[dObj.day].push(cObj)
                            } else {
                                mainData[dObj.day] = [cObj]
                            }
                        }
                    })
                })
            }
            setSchedulerData(mainData)
        }
        let params = {
            department_id: formDetails?.department_id
        }
        dispatch(Actions.SchedulerListAction(params, callback))
    }


    const EditSlot = (data) => {
        setOpenEditMasterSlotMoal(true)
        setUpdateData(data)
    }
    useEffect(() => {
        getMasterSLotList()
        //
    }, [formDetails?.college_id, formDetails?.course_id, formDetails?.department_id])
    useEffect(() => {
        dispatch(Actions.CollegeListAction())
    }, [dispatch])

    useEffect(() => {
        const params = {
            id: formDetails?.college_id
        }
        dispatch(Actions.CoursetAction(params))
    }, [dispatch, formDetails?.college_id])

    useEffect(() => {
        const params = {
            id: formDetails?.course_id
        }
        dispatch(Actions.DepartmentAction(params))
    }, [dispatch, formDetails?.course_id])

    const onClose = () => {
        getMasterSLotList()
        if (openaddMasterSlotModal) {
            setOpenAddMasterSlotMoal(false)
        } else {
            setOpenEditMasterSlotMoal(false)
        }
    }
    const handleClick = (item) => {
        setItemObj(item);
        setOpenDeclineModal(true);
    };
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const deleteSlot = () => {
        setLoading(true)
        const callback = (res) => {
            setLoading(false)
            if (res) {
                toast.success(res?.message)
                onCloseModel()
                getMasterSLotList()
            } else {
                toast.error("Something went wrong!")
            }
        }
        const param = {
            id: itemObj?.id
        }
        dispatch(Actions.DeleteSlot(param, callback))
    }
    const handleCollegeSelect = (selected) => {
        setFormDetails({ ...formDetails, 'course_id': '', 'year_id': '', 'department_id': '', 'college_id': selected })
    };
    const handleDepartmentSelect = (selected) => {
        setFormDetails({ ...formDetails, 'year_id': '', 'department_id': selected })
    };
    const handleCourseSelect = (selected) => {
        setFormDetails({ ...formDetails, 'department_id': '', 'course_id': selected })
    };
    return <React.Fragment>
        {
            isloading && <AppLoader />
        }
        <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
            <div className="mb-2" style={{ textAlign: 'end' }}>
                <button type="button" className="btn btn-primary" onClick={() => setOpenAddMasterSlotMoal(true)}>Add Slot</button>
            </div>
            <div className="card">
                <h5 className="card-header">Scheduler List</h5>
                <div className="card-body">
                    <div className="">
                        <div className="row">
                            <div className="col-sm-4">
                                <label htmlFor="exampleInputEmail1" className="form-label">College</label>
                                <select value={formDetails?.college_id} onChange={(e) => handleCollegeSelect(e?.target?.value)}
                                    className="form-select">
                                    <option value='' hidden>Select College </option>
                                    {Array.isArray(collegeList) && collegeList?.map((o) => {
                                        return <option value={o?.id}>{o?.name}</option>

                                    })
                                    }
                                </select>
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="exampleInputEmail1" className="form-label">Course</label>
                                <select value={formDetails?.course_id} disabled={formDetails?.college_id ? false : true} onChange={(e) => handleCourseSelect(e?.target?.value)}
                                    className="form-select">
                                    <option value='' hidden>Select Course</option>
                                    {Array.isArray(courseList) && courseList?.map((o) => {
                                        return <option value={o?.id}>{o?.name}</option>

                                    })
                                    }
                                </select>
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="exampleInputEmail1" className="form-label">Department</label>
                                <select disabled={formDetails?.course_id ? false : true} value={formDetails?.department_id} onChange={(e) => handleDepartmentSelect(e?.target?.value)}
                                    className="form-select">
                                    <option value='' hidden>Select Department</option>
                                    {Array.isArray(departmentList) && departmentList?.map((o) => {
                                        return <option value={o?.id}>{o?.name}</option>
                                    })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body py-3 px-0">
                    <div className="table-responsive" style={{ height: `${height}px` }}>
                        {Object.keys(schedulerData)?.length > 0 ?
                            <table className="table onboardTable">
                                <thead className="bg-light">
                                    <tr className="">
                                        {Object.keys(schedulerData)?.map((obj) => <td>{obj}</td>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="">

                                        <> {Object.entries(schedulerData).map(([day, items]) => (
                                            <td className='border-1' >
                                                {items?.map((item, index) => {
                                                    return (
                                                        <div className={`card mb-3 ${item?.section_id ? 'scheduler-card' : 'scheduler-card-inactive'}`}
                                                        >
                                                            <div className='row'>
                                                                <div className='col-sm-6 text-end'>
                                                                </div>
                                                                <div className='col-sm-6 text-end mr-0'>
                                                                    <span className='cursor-pointer'
                                                                        onClick={() => EditSlot(item)} >
                                                                        <i className="fas fa-edit cursor-pointer" ></i>
                                                                    </span>
                                                                    <i class="fa-solid fa-circle-xmark text-end cursor-pointer" onClick={() => handleClick(item)} style={{ color: 'red', marginRight: '-1px' }} ></i>

                                                                </div>

                                                            </div>
                                                            <div className='card-body p-0 text-center margin'>{item.start_time} - {item.end_time}</div>

                                                        </div>
                                                    )
                                                })}
                                            </td>
                                        ))}</>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            <div className="d-flex justify-content-center mt-5">
                                <div className="NodataFound">
                                    <h3>No Data Found</h3>
                                </div>
                                <div className="NodataImage">
                                    <img src={nodatafound} alt="No Data Found" className="NoData" />
                                </div>
                            </div>}
                    </div>


                </div>
            </div>
            {
                openaddMasterSlotModal && <AddMasterSlot onClose={() => onClose()} college_id={formDetails?.college_id} course_id={formDetails?.course_id} department_id={formDetails?.department_id}/>
            }
            {
                openEditMasterSlotModal && <AddMasterSlot updateData={updateData} onClose={() => onClose()} />
            }
            {isOpenDeclineModal && <DeclinePopup
                title={`Are you sure you want to delete`}
                onClose={() => onCloseModel()}
                onSuccess={() => deleteSlot()}
            />}
        </div>
    </React.Fragment>
}
export default MasterSlots;
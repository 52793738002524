import React from "react";

const ForgotPassword = () => {


    return (
        <>
            <div className='login-body'>
                <div className="splash-container">
                    <div className="card">
                        <div className="card-header text-center"> <img className="logo-img" src={require('./asset/img/proglint.png')} alt="logo" /><span className="splash-description">Please enter your user information.</span></div>
                        <div className="card-body">
                            <form>
                                <p>Don't worry, we'll send you an email to reset your password.</p>
                                <div className="form-group">
                                    <input className="form-control form-control-lg" type="email" name="email" required="" placeholder="Your Email" autocomplete="off" />
                                </div>
                                <div className="form-group pt-1"><a className="btn btn-block btn-primary btn-xl" href="../index.html">Reset Password</a></div>
                            </form>
                        </div>
                        <div className="card-footer text-center">
                            <span>Don't have an account? <a href="/signUp">Sign Up</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default ForgotPassword;

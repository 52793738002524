import { doAction, doResponseAction } from ".";

const ROOM_LIST = 'ROOM_LIST';
const ROOM_LIST_SUCCESS = 'ROOM_LIST_SUCCESS';
const ROOM_LIST_FAILURE = 'ROOM_LIST_FAILURE';
export {
    ROOM_LIST,
    ROOM_LIST_FAILURE,
    ROOM_LIST_SUCCESS,
}
// roomList
export const roomListAction = (params, callback) => doAction(ROOM_LIST, params, callback);
export const roomListSuccessAction = data => doResponseAction(ROOM_LIST_SUCCESS, data);
export const roomListFailureAction = data => doResponseAction(ROOM_LIST_FAILURE, data);

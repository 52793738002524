import Actions from "../action";

const initialState = {
    isLoading: false,
    workerRoomList: [],
    workerRoomMappingList:[],
    totalCount:null,
    error: false,
}
const workerRoomReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        //WORKER_ROOM_LIST
        case Actions.WORKER_ROOM_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.WORKER_ROOM_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                workerRoomList: action.data?.results,
                totalCount:action.data?.count,
                error: false
            };
        }
        case Actions.WORKER_ROOM_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                workerRoomList: [],
                error: action.error,
            };
        }
        //WORKER_ROOM_DETAILS
        case Actions.WORKER_ROOM_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.WORKER_ROOM_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                workerRoomDetails: action.data,
                error: false
            };
        }
        case Actions.WORKER_ROOM_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                workerRoomDetails: {},
                error: action.error,
            };
        }
        //CREATE_WORKER_ROOM
        case Actions.CREATE_WORKER_ROOM: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.CREATE_WORKER_ROOM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.CREATE_WORKER_ROOM_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // WORKER_ROOM_DELETE
        case Actions.WORKER_ROOM_DELETE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.WORKER_ROOM_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.WORKER_ROOM_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        // WORKER_ROOM_UPDATE
        case Actions.WORKER_ROOM_UPDATE: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.WORKER_ROOM_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            }
        }
        case Actions.WORKER_ROOM_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        //WORKER_ROOM_MAPPING_LIST
        case Actions.WORKER_ROOM_MAPPING_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.WORKER_ROOM_MAPPING_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                workerRoomMappingList: action.data?.results,
                error: false
            };
        }
        case Actions.WORKER_ROOM_MAPPING_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                workerRoomMappingList: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export default workerRoomReducer;

export const getWorkerRoomListSelector = state => state?.WORKER_ROOM?.workerRoomList;
export const getWorkerRoomMappingListSelector = state => state?.WORKER_ROOM?.workerRoomMappingList;
export const getWorkerRoomCountSelector = state => state?.WORKER_ROOM?.totalCount;
export const getWorkerRoomListLoadingSelector = state => state?.WORKER_ROOM?.isLoading;
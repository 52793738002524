import React, { useCallback } from 'react'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSectionList, getdepartmentSearch, getYearList, getdepartmentList } from "../Redux/reducer/SchedulerReducer";
import Actions from "../Redux/action";
import { useLocation, useNavigate } from "react-router";
import { days } from "../Utils/Constant";
import AlertModal from './AlertModal';
import { toast } from 'react-toastify';
import AppLoader from '../component/AppLoader';
import FilterSectionModal from './FilterSectionMdal';
import DownloadSectionReport from './DownloadSectionReport';

const Scheduler = () => {
    const [year, setYear] = useState("")
    const [course, setCourse] = useState("")
    const [section, setSection] = useState("")
    const [department, setDepartment] = useState('')
    // const [dragging, setDragging] = useState(false);
    const [dataSelect, setDataSelect] = useState({})
    const [droppedText, setDroppedText] = useState(1);
    const [openFilterSectionModal, setOpenFilterSectionModal] = useState(false)
    const [alertModal, setAlertModal] = useState(false)
    const [selectSlot, setSelectSlot] = useState([])
    const [search, setSearch] = useState("")
    const [openDownloadReportModal, setOpenDownloadReportModal] = useState(false)
    // const isloading=useSelector(getLoading)
    const departmentList = useSelector(getdepartmentList)
    const yearList = useSelector(getYearList)
    const sectionList = useSelector(getSectionList)
    const departmentsearch = useSelector(getdepartmentSearch)
    const location = useLocation();
    const [disable, setDisable] = useState(true)
    const searchParams = new URLSearchParams(location.search);
    const { state } = useLocation()
    const collegeId = searchParams.get('collegeId');
    const dispatch = useDispatch()
    const [schedulerData, setSchedulerData] = useState([])
    const navigate = useNavigate()
    const [isloading, setLoader] = useState(false)
    const [height, setHeight] = useState(window.innerHeight * 0.54)
    const [showDept, setShowDept] = useState(false);
    const departmentSearchList = (value) => {
        const params = {
            course: search,
            college: collegeId

        }
        dispatch(Actions.DepartmentSearchSectionList(params))

    };

    // useEffect(() => {
    //     function handleResize() {
    //       setHeight(window.innerHeight * 0.4);
    //     }

    //     window.addEventListener('resize', handleResize);

    //     // Clean up the event listener when the component unmounts
    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };
    //   }, []);

    useEffect(() => {
        departmentSearchList()
    }, [search])


    useEffect(() => {
        const params = {
            id: collegeId
        }
        dispatch(Actions.CoursetAction(params))

    }, [])
    useEffect(() => {
        setLoader(true);
        const callback = (res) => {
            setLoader(false);
            let mainData = {};
            if (res?.status_code === 0) {
                console.log("Status code is 0, processing response");
                days.forEach((dObj) => {
                    res?.response?.forEach((cObj) => {
                        if (dObj.type === cObj.day) {
                            if (mainData[dObj.day]) {
                                mainData[dObj.day].push(cObj);
                            } else {
                                mainData[dObj.day] = [cObj];
                            }
                        }
                    });
                });
            } else {
                console.log("Status code is not 0, skipping response processing");
            }


            if (state?.room_slots?.length > 0) {
                console.log("Processing room slots");
                let slotsArr = [];
                Object.entries(mainData).forEach(([day, items]) => {
                    items.forEach((element) => {
                        state?.room_slots.forEach((rs) => {
                            if (rs?.slot === element?.id) {
                                const checkSlot = selectSlot?.findIndex((c) => c?.slot === rs.slot);
                                if (checkSlot === -1) {
                                    const slot = {
                                        section_id: Number(rs?.section),
                                        slot_id: Number(element?.id),
                                        day: Number(rs?.day)
                                    };
                                    slotsArr.push(slot);
                                }
                                element['section_id'] = rs?.section;
                                element['section_name'] = sectionList?.find((f) => f?.id === rs?.section)?.name;
                                element['department_id'] = rs?.department_id;
                                element['department_name'] = departmentList?.find((f) => f?.id === rs?.department_id)?.name;
                                element['year_id'] = rs?.year_id;
                                element['year_name'] = yearList?.find((f) => f?.id === rs?.year_id)?.name;
                            }
                        });
                    });
                });
                setSelectSlot(slotsArr);
            } else {
                console.log("No room slots to process");
            }
            setSchedulerData(mainData);
            console.log("Scheduler data set");
        };
        dispatch(Actions.SchedulerListAction('', callback));
    }, [state?.room_slots?.length]);


    useEffect(() => {
        const params = {
            id: course
        }
        dispatch(Actions.DepartmentAction(params))

    }, [])


    useEffect(() => {
        const params = {
            id: course
        }
        dispatch(Actions.YearAction(params))
    }, [])

    useEffect(() => {
        const params = {
            id: department,
            year: year
        }
        dispatch(Actions.SectiontAction(params))
    }, [])



    const handleDragStart = (data, dep) => {

        setSection(data?.id)
        setDepartment(dep?.department?.id)
        setYear(data?.years?.id)
    };

    const handleSlot = (data, dataDay) => {
        if (state.timeTable) {
            if (handleDay(dataDay, data).selected === "prev") {
                toast.error("Please Update in future day")
            } else {
                Object.entries(schedulerData).forEach(([day, items]) => {
                    if (day === dataDay) {
                        if (data.section_id) {
                            setAlertModal(true)
                            setDataSelect(data)
                        } else {
                            handleDrop(data)
                        }
                    }
                })
            }
        } else {
            Object.entries(schedulerData).forEach(([day, items]) => {
                if (day === dataDay) {
                    if (data.section_id) {
                        setAlertModal(true)
                        setDataSelect(data)
                    } else {
                        handleDrop(data)
                    }
                }
            })
        }
    }

    const handleDrop = (data) => {
        let finalArray = schedulerData;
        const checkSlot = selectSlot?.findIndex((c) => c.slot_id === data?.id);
        if (checkSlot === -1) {
            setDisable(false);
            const slot = {
                section_id: Number(section),
                slot_id: Number(data?.id),
                day: Number(data?.day)
            };
            setSelectSlot([...selectSlot, slot]);
        }
        Object.entries(finalArray).forEach(([day, items]) => {
            items.forEach((element) => {
                if (element?.day === data?.day && element?.id === data?.id) {
                    element['section_id'] = section;
                    element['section_name'] = sectionList?.find((f) => f?.id === Number(section))?.name;
                    element['department_id'] = department;
                    element['department_name'] = departmentList?.find((f) => f?.id === Number(department))?.name;
                    element['year_id'] = year;
                    element['year_name'] = yearList?.find((f) => f?.id === Number(year))?.name;
                }
            });
        });
        setSchedulerData(finalArray);
        setDroppedText((prevState) => prevState + 1);
        setAlertModal(false);
        setCourse('');
        setDepartment('');
        setYear('');
        setSection('');
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };


    const CreateScheduler = () => {
        const { rooms_schedulers } = state
        setLoader(true)
        const callback = (res) => {
            setLoader(false)
            if (res?.status_code === 0) {
                navigate('/roomlist')
            } else {
                toast.error(res?.message ? res.message : 'Sorry something went wrong, please try again later')
            }
        }

        const params = {
            "from_date": rooms_schedulers?.from_date,
            "to_date": rooms_schedulers?.to_date,
            "room_schedule_id": rooms_schedulers?.id,
            "room_slots": selectSlot
        }
        if (state?.room_slots?.length) {
            dispatch(Actions.UpdateScheduler(params, callback))
        } else {
            dispatch(Actions.CreateScheduleAction(params, callback))
        }
    }

    const handleSlotDataChange = (slotData) => {
        setShowDept(true)
        const updatedSchedulerData = slotData;
        setSchedulerData(updatedSchedulerData);
    };


    const removeSLot = (data) => {
        Object.entries(schedulerData).forEach(([day, items]) => {
            items?.forEach(element => {
                if (data.id === element.id) {
                    const findSLot = items.findIndex((o) => o?.id === data?.id)
                    items[findSLot].section_id = ""
                }
            }
            )
        })
        const findSLot = selectSlot?.findIndex((o) => o?.slot_id === data?.id)
        selectSlot.splice(findSLot, 1)
        setSchedulerData(schedulerData)
        setDroppedText(prevState => prevState + 1)
        setDisable(false);
    }

    const handleDay = (selectDay, item) => {
        const day = new Date()
        let selected = ''
        const currentDay = day.getDay()
        let currentTime = ''
        let getDateNum = ''
        if (selectDay === "Monday") {
            getDateNum = 1
        } else if (selectDay === "Tuesday") {
            getDateNum = 2
        } else if (selectDay === "Wednesday") {
            getDateNum = 3
        } else if (selectDay === "Thursday") {
            getDateNum = 4
        } else if (selectDay === "Friday") {
            getDateNum = 5
        } else if (selectDay === "Saturday") {
            getDateNum = 6
        }
        if (getDateNum < currentDay) {
            selected = "prev"
        } else if (getDateNum === currentDay) {
            selected = "current"
            const getCurrentTime = day.toLocaleTimeString();
            if (getCurrentTime > item.start_time) {
                currentTime = true
            } else {
                currentTime = false
            }

        } else {
            selected = "future"
        }
        let obj = {}
        obj.selected = selected
        obj.currentTime = currentTime
        return obj
    }


    return <React.Fragment>
        {
            isloading && <AppLoader />
        }
        {/* <h2>Scheduler</h2> */}
        <div className='row'>
            {state?.timeTable ? <>
                <div className='col-sm-3'>
                    <i className="fa fa-arrow-left cursor-pointer" onClick={() => navigate("/roomlist")} aria-hidden="true"></i>
                    <span style={{ marginLeft: '10px' }}><b>Course: {state?.course}</b></span>
                </div>
                <div className='col-sm-3 '>
                    <span><b>Department: {state?.department}</b></span>
                </div>
                <div className='col-sm-3 '>
                    <span><b>Year: {state?.year}</b></span>
                </div>
                <div className='col-sm-3 text-end'>
                    <span><b>Section: {state?.section}</b></span>
                </div>
            </>
                : <>
                    <div className='col-sm-6'>
                        <i className="fa fa-arrow-left cursor-pointer margin-left" onClick={() => navigate("/roomlist")} aria-hidden="true"></i>
                        <span style={{ marginLeft: '10px' }}><b>College: {state?.college?.name}</b></span>

                    </div>
                    <div className='col-sm-6 text-end'>
                        <span><b>Room: {state?.name}</b></span>
                    </div>
                </>}
        </div>
        <br />
        <div className='row'>
            <div className='col-sm-10'>
                <div className="card card-schedular">
                    <h5 className="card-header schedular-slot">Slots</h5>
                    <div className="card-body p-3">
                        <div className="table-responsive" style={{ height: `${height}px` }}>
                            {!showDept && <div className='section-filter'>Please Choose the section filter</div>}
                            <table className="table onboardTable">
                                <thead className="bg-light">
                                    <tr className="">
                                        {Object.keys(schedulerData)?.map((obj) => <td>{obj}</td>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="">

                                        <> {Object.entries(schedulerData).map(([day, items]) => (
                                            <td className='border-1' >
                                                {items?.map((item, index) => {
                                                    return (
                                                        <div className={`card mb-3 ${item?.section_id ? 'scheduler-card' : 'scheduler-card-inactive'}`}
                                                            onDragOver={handleDragOver}
                                                            onDrop={() => handleSlot(item, day)}>
                                                            {item?.section_id && <i class="fa-solid fa-circle-xmark text-end cursor-pointer" style={{ color: 'red', marginRight: '-1px' }} onClick={() => removeSLot(item)}></i>}
                                                            <div className='card-body p-0 text-center'>{item?.start_time} - {item?.end_time}</div>
                                                            {item?.section_id ? <p className='text-center'>{item?.department_name} -{item?.year_name} {item?.section_name}</p> : null}
                                                            {/* {handleDay(day)} */}
                                                        </div>
                                                    )
                                                })}
                                            </td>
                                        ))}</>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='text-end' style={{ paddingTop: '10px' }}>
                            <button
                                type="button"
                                className="btn"
                                style={{ backgroundColor: 'lightgray' }}
                                onClick={() => navigate('/roomlist')}>Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                style={{ marginLeft: '20px' }}
                                disabled={disable}
                                onClick={() => CreateScheduler()}>{state?.room_slots?.length ? 'Update' : 'Create'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-2'>
                <div className="card schedular-col">
                    <div className="card-body p-2">
                        <div className='d-flex'>
                            <div className="search">
                                <i className="fa fa-search fa-1x"></i>
                                <input type="text" className="form-control" id="exampleInputEmail1" value={search} onChange={(e) => setSearch(e?.target?.value)} placeholder="Search by course" />
                            </div>
                            <div className='btn btn-primary' onClick={() => setOpenFilterSectionModal(true)}>
                                <i class="fa-solid fa-filter text-center"></i>
                            </div>
                        </div>
                        <br />
                        {showDept && <div className='departmentList' style={{ height: `${height + 35}px` }}>
                            {departmentsearch?.map((mainObj, index) => {
                                return mainObj.sections?.map((childObj, childIndex) => {
                                    return (
                                        <div className={'card mb-3 scheduler-card-inactive cursor-pointer'} id="draggable"
                                            draggable="true" onDragStart={() => handleDragStart(childObj, mainObj)}>
                                            <div className='card-body p-2 text-center'>
                                                {mainObj?.course?.name}({mainObj?.department?.name}) {childObj?.name} - {childObj?.years?.name}</div>
                                            <div className='text-center'>
                                                Drag Here
                                            </div>
                                        </div>
                                    )
                                })
                            })}
                            {departmentsearch?.length === 0 && <p className='text-center'>No match found</p>}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        {
            alertModal && <AlertModal
                onClose={() => setAlertModal(false)}
                dataSelect={dataSelect}
                handleDrop={(e) => handleDrop(e)} />
        }
        {
            openFilterSectionModal && <FilterSectionModal
                onClose={() => {
                    setOpenFilterSectionModal(false)
                }}
                year={year} setYear={(e) => setYear(e)} course={course}
                setCourse={(e) => setCourse(e)} department={department}
                setDepartment={(e) => setDepartment(e)} setSection={(e) => setSection(e)}
                section={section} collegeId={collegeId} search={search}
                setDroppedText={(e) => setDroppedText(e)}
                droppedText={droppedText}
                onSlotDataChange={handleSlotDataChange}
                setSelectSlot={setSelectSlot}
                selectSlot={selectSlot}
            />
        }
        {openDownloadReportModal && <DownloadSectionReport onClose={() => setOpenDownloadReportModal()} />}
    </React.Fragment>
}
export default Scheduler;
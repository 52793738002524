import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { WORKER_ROOM_LIST,CREATE_WORKER_ROOM,WORKER_ROOM_UPDATE,WORKER_ROOM_DELETE,WORKER_ROOM_MAPPING_LIST } from '../action/WorkerRoomAction'
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';

export function* workerRoomListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.WORKER_ROOM_LIST, params)
        yield put(Actions.workerRoomListSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.workerRoomListFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* workerRoomCreateSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_WORKER_ROOM, params)
        yield put(Actions.createWorkerRoomSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.createWorkerRoomFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* workerRoomUpdateSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.UPDATE_WORKER_ROOM, params)
        yield put(Actions.workerRoomUpdateSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.workerRoomUpdateFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* workerRoomDeleteSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.DELETE_WORKER_ROOM, params)
        yield put(Actions.workerRoomDeleteSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.workerRoomDeleteFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* workerRoomMappingSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.WORKER_ROOM_MAPPING_LIST, params)
        yield put(Actions.workerRoomMappingListSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.workerRoomMappingListFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* workerRoomWatcher() {
    yield all([
        takeLatest(WORKER_ROOM_LIST, workerRoomListSaga),
        takeLatest(CREATE_WORKER_ROOM, workerRoomCreateSaga),
        takeLatest(WORKER_ROOM_UPDATE, workerRoomUpdateSaga),
        takeLatest(WORKER_ROOM_DELETE, workerRoomDeleteSaga),
        takeLatest(WORKER_ROOM_MAPPING_LIST, workerRoomMappingSaga),
        
    ]);
}
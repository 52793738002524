import { doAction, doResponseAction } from ".";

const DASHBOARD = 'DASHBOARD';
const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
const DASHBOARD_FAILURE = 'DASHBOARD_FAILURE';
export {
    DASHBOARD,
    DASHBOARD_FAILURE,
    DASHBOARD_SUCCESS,
}
// dashboard
export const dashboardAction = (params, callback) => doAction(DASHBOARD, params, callback);
export const dashboardSuccessAction = data => doResponseAction(DASHBOARD_SUCCESS, data);
export const dashboardFailureAction = data => doResponseAction(DASHBOARD_FAILURE, data);
export const URLS = {
    commonLogin: 'http://localhost:3000',
    storeManager: 'http://localhost:3001',
    bistroDisplay: 'http://localhost:3001/bistro/display/orders',
    BackKitchen: 'http://localhost:3001/chef/orders',
    cookieDomain: ''
}
export const days = [{
    day: "Monday",
    type: "1"
}, {
    day: "Tuesday",
    type: "2"
}, {
    day: "Wednesday",
    type: "3"
}, {
    day: "Thursday",
    type: "4"
}, {
    day: "Friday",
    type: "5"
}, {
    day: "Saturday",
    type: "6"
}, {
    day: "Sunday",
    type: "7"
}]
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import Close from '../asset/img/modal-close.svg'
import Actions from '../Redux/action';
import { CameraValidation } from '../Utils/Validations';
export const AddCameraPopup = ({ onClose, data, pageNumber }) => {
    const dispatch = useDispatch();
    const [formDetails, setFormDetails] = useState({ ...data });
    const [errors, setErrors] = useState();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'camera_name' && value.length > 15) {
            setFormDetails({ ...formDetails, [name]: value.substring(0, 15) });
        } else {
            setFormDetails({ ...formDetails, [name]: value });
        }
    }
    
    useEffect(() => {
        if (Object.keys(formDetails)?.length !== 0) {
            let validatedError = CameraValidation(formDetails, ["camera_name", "rtsp_url", "divider_x1", "divider_y1", "divider_x2", "divider_y2"])
            if (validatedError) {
                setErrors(validatedError)
            }
        }
    }, [formDetails])

    const handleCameraType = (event) => {
        setFormDetails({ ...formDetails, 'camera_type': event.target.value })
    }
    const handleCameraSide = (event) => {
        setFormDetails({ ...formDetails, 'entry_side': event.target.value })
    }
    const createCamera = () => {
        if (!formDetails?.camera_name) {
            setErrors({ ...errors, camera_name: "Please enter the valid camera name" })
        } else if (!formDetails?.rtsp_url) {
            setErrors({ ...errors, rtsp_url: "Please enter the valid URL" })
        } else if (!formDetails?.camera_type) {
            setErrors({ ...errors, camera_type: "Please choose the camera type" })
        } else if (!formDetails?.entry_side) {
            setErrors({ ...errors, entry_side: "Please choose the camera side" })
        }
        // else if (!validateCameraPoints(formDetails?.divider_x1)) {
        //     setErrors({ ...errors, divider_x1: "Please enter the valid Divider x1" })
        // } else if (!validateCameraPoints(formDetails?.divider_y1)) {
        //     setErrors({ ...errors, divider_y1: "Please enter the valid Divider y1" })
        // } else if (!validateCameraPoints(formDetails?.divider_x2)) {
        //     setErrors({ ...errors, divider_x2: "Please enter the valid Divider x2" })
        // } else if (!validateCameraPoints(formDetails?.divider_y2)) {
        //     setErrors({ ...errors, divider_y2: "Please enter the valid Divider y2" })
        // }
        else {
            if (formDetails?.id) {
                const updateCallBack = (response) => {
                    if (response?.status_code === 0) {
                        toast.success("Camera successfully updated")
                        onClose()
                        let params = {
                            page: pageNumber
                        }
                        dispatch(Actions.cameraList(params))
                    } else {
                        toast.error(response?.message)
                    }
                }
                let params = {
                    id: formDetails?.id,
                    camera_name: formDetails?.camera_name,
                    rtsp_url: formDetails?.rtsp_url,
                    camera_type: formDetails?.camera_type,
                    entry_side: formDetails?.entry_side,
                    divider_x1: formDetails?.divider_x1,
                    divider_y1: formDetails?.divider_y1,
                    divider_x2: formDetails?.divider_x2,
                    divider_y2: formDetails?.divider_y2,
                }
                dispatch(Actions.cameraUpdate(params, updateCallBack))
            } else {
                const createCallBack = (response) => {
                    if (response?.status_code === 0) {
                        toast.success("Camera successfully created")
                        onClose()
                        let params = {
                            page: pageNumber
                        }
                        dispatch(Actions.cameraList(params))
                    } else {
                        toast.error(response?.message)
                    }
                }
                let params = {
                    camera_name: formDetails?.camera_name,
                    rtsp_url: formDetails?.rtsp_url,
                    camera_type: formDetails?.camera_type,
                    entry_side: formDetails?.entry_side,
                    divider_x1: formDetails?.divider_x1,
                    divider_y1: formDetails?.divider_y1,
                    divider_x2: formDetails?.divider_x2,
                    divider_y2: formDetails?.divider_y2,
                }
                dispatch(Actions.createCamera(params, createCallBack))
            }
        }
    }
    const onClosePopUp = () => {
        setFormDetails('')
        onClose()
    }
    return (
        <>
            <div className="modal cameraModel d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg planogram-div">
                    <div className="modal-content rounded-0">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() => onClosePopUp()} />
                        <div className="modal-header rounded-0 align-items-center py-4 px-4">
                            <div className="col">
                                <h4 className="modal-title">Add Camera</h4>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Camera Name</label>
                                            <input value={formDetails?.camera_name} maxLength={14} name='camera_name' onChange={handleChange} type="text" className="form-control w-75" id="exampleInputEmail1"
                                                placeholder='Type here' />
                                            {errors?.camera_name && <p className='errorMessage'>{errors?.camera_name}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">RTSP URL</label>
                                            <input placeholder='Type here' value={formDetails?.rtsp_url} name='rtsp_url' onChange={handleChange} type="text" className="form-control w-75" id="exampleInputEmail1" />
                                            {errors?.rtsp_url && <p className='errorMessage'>{errors?.rtsp_url}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Camera Type</label>
                                            <select
                                                value={formDetails?.camera_type}
                                                onChange={(event) => handleCameraType(event)}
                                                className="form-select w-75">
                                                <option value='' hidden>Select</option>
                                                <option value="EN" >Entry</option>
                                                <option value="EX">Exit</option>
                                            </select>
                                            {errors?.camera_type && <p className='errorMessage'>{errors?.camera_type}</p>}

                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Camera Side</label>
                                            <select
                                                value={formDetails?.entry_side}
                                                onChange={(event) => handleCameraSide(event)}
                                                className="form-select w-75">
                                                <option value='' hidden>Select</option>
                                                <option value="LE" >Left</option>
                                                <option value="RI">Right</option>
                                            </select>
                                            {errors?.entry_side && <p className='errorMessage'>{errors?.entry_side}</p>}

                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Divider x1</label>
                                            <input value={formDetails?.divider_x1} name='divider_x1' onChange={handleChange} type="number" step="0.01" min="0" max="1" className="form-control w-75" id="exampleInputEmail1"
                                                placeholder='Type here' />
                                            {errors?.divider_x1 && <p className='errorMessage'>{errors?.divider_x1}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Divider y1</label>
                                            <input value={formDetails?.divider_y1} name='divider_y1' onChange={handleChange} type="number" step="0.01" min="0" max="1" className="form-control w-75" id="exampleInputEmail1"
                                                placeholder='Type here' />
                                            {errors?.divider_y1 && <p className='errorMessage'>{errors?.divider_y1}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Divider x2</label>
                                            <input value={formDetails?.divider_x2} name='divider_x2' onChange={handleChange} type="number" step="0.01" min="0" max="1" className="form-control w-75" id="exampleInputEmail1"
                                                placeholder='Type here' />
                                            {errors?.divider_x2 && <p className='errorMessage'>{errors?.divider_x2}</p>}
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Divider y2</label>
                                            <input value={formDetails?.divider_y2} name='divider_y2' onChange={handleChange} type="number" step="0.01" min="0" max="1" className="form-control w-75" id="exampleInputEmail1"
                                                placeholder='Type here' />
                                            {errors?.divider_y2 && <p className='errorMessage'>{errors?.divider_y2}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row-3">
                                    <button className="btn btn-primary submitRoom" onClick={() => createCamera()}>{formDetails?.id ? "Update" : "Create"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>

        </>
    )
}
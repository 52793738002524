import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../Redux/action";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { toast } from "react-toastify";
import { roomData } from "../Redux/reducer/GetRoomDataReducer";
import moment from "moment";
import nodatafound from '../asset/img/datanotfound_black.png';
import { getReports } from "../Redux/reducer/AttendanceReducer";
import AppDatePicker from "../component/AppDatePicker";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import AppLoader from "../component/AppLoader";
import { getCollegeList, getCourseList, getSchedulerList, getSectionList, getSlotList, getYearList, getdepartmentList, getdepartmentSearch } from "../Redux/reducer/SchedulerReducer";
export const Attendance = () => {
    const EXCEL_EXTENSION = '.xlsx'
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const dispatch = useDispatch();
    const courseList = useSelector(getCourseList)
    const collegeList = useSelector(getCollegeList)
    const slotList = useSelector(getSlotList)
    const yearList = useSelector(getYearList)
    const departmentList = useSelector(getdepartmentList)
    const [fromDate, setFromDate] = useState('')
    const [formDetails, setFormDetails] = useState({})
    const sectionList = useSelector(getSectionList);
    const [toDate, setToDate] = useState('')
    const [room, setRoom] = useState('')
    const [status, setStatus] = useState('')
    const [err, setErr] = useState({})
    const [viewReports, setViewReport] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const roomList = useSelector(roomData)
    const reportList = useSelector(getReports)
    const [fromTimeValue, onChangeFromTimeValue] = useState('');
    const [toTimeValue, onChangeToTimeValue] = useState('');
    useEffect(() => {
        dispatch(Actions.clearAttendanceReports())
        // dispatch(Actions.clearRooms())

        const callback = () => {
            setLoading(false)
        }
        setLoading(true)
        dispatch(Actions.roomAction("", callback))
        dispatch(Actions.CollegeListAction())
    }, [dispatch])

    useEffect(() => {
        const params = {
            id: formDetails?.college_id
        }
        dispatch(Actions.CoursetAction(params))
    }, [dispatch, formDetails?.college_id])

    useEffect(() => {
        const params = {
            id: formDetails?.department_id,
            year: formDetails?.year_id,
        }
        dispatch(Actions.SectiontAction(params))
    }, [dispatch, formDetails?.year_id])

    useEffect(() => {
        const params = {
            id: formDetails?.course_id
        }
        dispatch(Actions.YearAction(params))
    }, [dispatch, formDetails?.department_id])


    useEffect(() => {
        const params = {
            id: formDetails?.course_id
        }
        dispatch(Actions.DepartmentAction(params))
    }, [dispatch, formDetails?.course_id])

    const DepartmentSearch = useCallback(() => {
        setLoading(true)
        const callback = (res) => {
            setLoading(false)
        }
        let params = {
            course_id: formDetails?.course_id,
            year: formDetails?.year_id,
            college: formDetails?.college_id,
            department: formDetails?.department_id
        }
        dispatch(Actions.DepartmentSearchSectionList(params, callback))
    }, [dispatch, formDetails?.college_id, formDetails?.course_id, formDetails?.department_id, formDetails?.year_id])

    useEffect(() => {
        let st_time = moment(fromDate).format('YYYY-MM-DD')
        const params = {
            date: st_time,
            section: formDetails?.section_id
        }
        dispatch(Actions.SlotList(params))
    }, [dispatch, formDetails?.section_id])
    useEffect(() => {
        DepartmentSearch()
    }, [DepartmentSearch])

    const clearReport = () => {
        setFromDate("")
        setToDate("")
        onChangeFromTimeValue("")
        onChangeToTimeValue("")
        setRoom("")
        setErr("")
        setViewReport("")
        setViewReport(false)
        dispatch(Actions.clearAttendanceReports())
        setFormDetails({ 'course_id': '', 'year_id': '', 'department_id': '', 'college_id': '', 'section_id': '', 'slot': '' })

        // dispatch(Actions.clearRooms())

    }
    const viewReport = () => {
        if (!fromDate) {
            setErr({ "fromDate": "Please select the Date" })
        } else if (!formDetails?.college_id) {
            setErr({ "college_id": "Please select the college" })
        } else if (!formDetails?.course_id) {
            setErr({ "course_id": "Please select the course" })
        } else if (!formDetails?.department_id) {
            setErr({ "department_id": "Please select the department" })
        } else if (!formDetails?.year_id) {
            setErr({ "year_id": "Please select the year" })
        } else if (!formDetails?.section_id) {
            setErr({ "section_id": "Please select the section" })
        } else if (!formDetails?.slot) {
            setErr({ "slot": "Please select the slot" })
        }
        else {
            const callback = (response) => {
                if (response?.status_code === 0) {
                    const { Absentees, Presentees } = response?.response || {}
                    if ((Absentees?.length > 0) || (Presentees?.length > 0)) {
                        setViewReport(true)
                    } else {
                        toast.error("No report found")
                    }
                    setLoading(false)
                    setErr("")
                }
                else {
                    toast.error(response?.message)
                    setLoading(false)
                }
            }
            let st_time = moment(fromDate).format('YYYY-MM-DD')
            let et_time = moment(toDate).format('YYYY-MM-DD') + ' ' + toTimeValue
            // let params = {
            //     start_date: moment.utc(st_time).utcOffset(+`-${moment().utcOffset()}`).format('YYYY-MM-DD HH:mm:ss'),
            //     end_date: moment.utc(et_time).utcOffset(+`-${moment().utcOffset()}`).format('YYYY-MM-DD HH:mm:ss'),
            //     room: room,
            //     status: status
            // }

            const params = {
                date: st_time,
                slot: formDetails?.slot,
                section: formDetails?.section_id
            }

            setLoading(true)
            dispatch(Actions.attendanceAction(params, callback))
        }
    }

    const handleDownload = () => {
        const saveAsExcelFile = (buffer, fileName) => {
            const data = new Blob([buffer], { type: EXCEL_TYPE }) || [];
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
        }
        const callback = (response) => {
            if (response?.status_code === 0) {
                const { Absentees, Presentees } = response?.response || {}
                let modifiedPresentJSON = Presentees?.map((obj) => ({ ...obj, Present: "Yes" }))
                let modifiedAbsentJSON = Absentees?.map((obj) => ({ ...obj, Present: "No" }))
                const mergedJSON = modifiedPresentJSON?.concat(modifiedAbsentJSON)
                const worksheet = XLSX.utils.json_to_sheet(mergedJSON);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'Report')
                toast.success("Downloaded Successfully")
                setLoading(false)

            }
            else {
                toast.error("Something went worng")
                setLoading(false)
            }
        }
        let st_time = moment(fromDate).format('YYYY-MM-DD') + ' ' + fromTimeValue
        let et_time = moment(toDate).format('YYYY-MM-DD') + ' ' + toTimeValue
        const params = {
            date: st_time,
            slot: formDetails?.slot,
            section: formDetails?.section_id
        }
        if (fromDate !== '') {
            setLoading(true)
            dispatch(Actions.attendanceAction(params, callback))
        }
    }
    // const disableFutureDate = () => {
    //     var todayDate = new Date();
    //     var month = todayDate.getMonth() + 1;
    //     var year = todayDate.getFullYear();
    //     var tDate = todayDate.getDate();
    //     var diableDate = year + "-0" + month + "-" + tDate
    //     console.log(diableDate, "It is from the diableFutureDate")
    //     return diableDate
    // }
    const handleDateSelect = (selected) => {
        setFromDate(selected)
        setViewReport(false)
        setFormDetails({ ...formDetails, 'course_id': '', 'year_id': '', 'department_id': '', 'college_id': '', 'section_id': '', 'slot': '' })
        setErr("")
    };
    const handleCollegeSelect = (selected) => {
        setViewReport(false)
        setFormDetails({ ...formDetails, 'course_id': '', 'year_id': '', 'department_id': '', 'college_id': selected, 'section_id': '', 'slot': '' })
    };
    const handleDepartmentSelect = (selected) => {
        setViewReport(false)
        setFormDetails({ ...formDetails, 'year_id': '', 'department_id': selected, 'slot': '', 'section_id': '' })
    };
    const handleCourseSelect = (selected) => {
        setViewReport(false)
        setFormDetails({ ...formDetails, 'department_id': '', 'course_id': selected, 'year_id': '', 'section_id': '', 'slot': '' })
    };
    const handleYearSelect = (selected) => {
        setViewReport(false)
        setFormDetails({ ...formDetails, 'year_id': selected })
    };
    const handleSectionSelect = (selected) => {
        setViewReport(false)
        setFormDetails({ ...formDetails, 'section_id': selected })
    };
    const handleSLotSelect = (selected) => {
        setViewReport(false)
        setFormDetails({ ...formDetails, 'slot': selected })
    };

    const { Absentees, Presentees } = reportList || {}
    return (
        <>
            {
                isLoading && <AppLoader />
            }
            <div className="ecommerce-widget">
                <h5 className="card-header">Daily Attendance Sheet</h5>
                <div className="card border-3 border-top border-top-primary">
                    {/* <div className="row">
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">From Date</label>
                            <div className="input-group mb">
                                <AppDatePicker
                                    selected={fromDate}
                                    placeholder="From Date"
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                        setFromDate(date)
                                        setViewReport(false)
                                    }}
                                    notValidTime={true}
                                    id='FormDatePicker'
                                />
                            </div>
                            <p style={{ 'color': 'red' }}>{err?.fromDate}</p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">From Time</label>
                            <div className="input-group mb">
                                <TimePicker onChange={onChangeFromTimeValue} value={fromTimeValue} disableClock={true} className="form-control  cursor-pointer" />
                            </div>
                            <p style={{ 'color': 'red' }}>{err?.fromTimeValue}</p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">To</label>
                            <div className="input-group mb">
                                <AppDatePicker
                                    selected={toDate}
                                    placeholder="To Date"
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                        setToDate(date)
                                        setViewReport(false)
                                    }}
                                    notValidTime={true}
                                    minDate={fromDate}
                                    id='ToDatePicker'
                                />
                            </div>
                            <p style={{ 'color': 'red' }}>{err?.toDate}</p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">To Time</label>
                            <div className="input-group mb">
                                <TimePicker onChange={onChangeToTimeValue} value={toTimeValue} disableClock={true} className="form-control dateInput cursor-pointer" />
                            </div>
                            <p style={{ 'color': 'red' }}>{err?.toTimeValue}</p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">Select Room</label>
                            <div className="authorizedSelect">
                                <select className={"form-select"} aria-label="Default select example" value={room} onChange={(e) => {
                                    setRoom(e.target.value)
                                    setViewReport(false)
                                }}>
                                    <option value="" selected >Select Room</option>
                                    {
                                        Array.isArray(roomList) && roomList?.map((item, index) =>
                                            <option value={item?.id} key={index}>{item?.room_name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">Select Status</label>
                            <div className="authorizedSelect">
                                <select className={"form-select"} aria-label="Default select example" value={status} onChange={(e) => {
                                    setStatus(e.target.value)
                                    setViewReport(false)
                                }}>
                                    <option selected value={'all'}>All</option>
                                    <option value={'in'}>In</option>
                                    <option value={'out'}>Out</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 selectDate1">
                            {!viewReports ? <button type="button" disabled={((fromDate !== '' && fromDate !== null) && (toDate !== '' && toDate !== null) && (room !== '' && room !== null)&&(fromTimeValue !=='' && fromTimeValue !== null) && (toTimeValue !=='' && toTimeValue !==null)) ? false : true} className="btn btn-primary" onClick={() => viewReport()}>View</button>
                                : <button type="button" disabled={(fromDate !== '' && toDate !== '' && room !== '') ? false : true} className="btn btn-primary" onClick={() => handleDownload()}>Download</button>}
                            &nbsp;
                            {(fromDate || toDate || reportList?.length > 0) && <button className="btn btn-primary" onClick={() => clearReport()}>CLEAR</button>}
                        </div>
                    </div> */}
                    <div className="row m-0">
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">Date</label>
                            <div className="input-group mb">
                                <AppDatePicker
                                    selected={fromDate}
                                    placeholder="Select Date"
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                        handleDateSelect(date)
                                    }}
                                    notValidTime={true}
                                    id='FormDatePicker'
                                />
                            </div>
                            <p style={{ 'color': 'red' }}>{err?.fromDate}</p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label htmlFor="exampleInputEmail1" className="form-label">College</label>
                            <select value={formDetails?.college_id} disabled={fromDate ? false : true} onChange={(e) => handleCollegeSelect(e?.target?.value)}
                                className="form-select">
                                <option value='' hidden>Select College</option>
                                {Array.isArray(collegeList) && collegeList?.map((o) => {
                                    return <option value={o?.id}>{o?.name}</option>

                                })
                                }
                            </select>
                            <p style={{ 'color': 'red' }}>{err?.college_id}</p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label htmlFor="exampleInputEmail1" className="form-label">Course</label>
                            <select value={formDetails?.course_id} disabled={formDetails?.college_id ? false : true} onChange={(e) => handleCourseSelect(e?.target?.value)}
                                className="form-select">
                                <option value='' hidden>Select Course</option>
                                {Array.isArray(courseList) && courseList?.map((o) => {
                                    return <option value={o?.id}>{o?.name}</option>

                                })
                                }
                            </select>
                            <p style={{ 'color': 'red' }}>{err?.course_id}</p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label htmlFor="exampleInputEmail1" className="form-label">Department</label>
                            <select value={formDetails?.department_id} disabled={formDetails?.course_id ? false : true} onChange={(e) => handleDepartmentSelect(e?.target?.value)}
                                className="form-select">
                                <option value='' hidden>Select Department</option>
                                {Array.isArray(departmentList) && departmentList?.map((o) => {
                                    return <option value={o?.id}>{o?.name}</option>

                                })
                                }
                            </select>
                            <p style={{ 'color': 'red' }}>{err?.department_id}</p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label htmlFor="exampleInputEmail1" className="form-label">Year</label>
                            <select value={formDetails?.year_id} disabled={formDetails?.department_id ? false : true} onChange={(e) => handleYearSelect(e?.target?.value)}
                                className="form-select">
                                <option value='' hidden>Select Year</option>
                                {Array.isArray(yearList) && yearList?.map((o) => {
                                    return <option value={o?.id}>{o?.name}</option>
                                })}
                            </select>
                            <p style={{ 'color': 'red' }}>{err?.year_id}</p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label htmlFor="exampleInputEmail1" className="form-label">Section</label>
                            <select value={formDetails?.section_id} disabled={formDetails?.year_id ? false : true} onChange={(e) => handleSectionSelect(e?.target?.value)}
                                className="form-select">
                                <option value='' hidden>Select Section</option>
                                {Array.isArray(sectionList) && sectionList?.map((o) => {
                                    return <option value={o?.id}>{o?.name}</option>
                                })

                                }
                            </select>
                            <p style={{ 'color': 'red' }}>{err?.section_id}</p>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label htmlFor="exampleInputEmail1" className="form-label">Time Slot</label>
                            <select value={formDetails?.slot} disabled={formDetails?.section_id ? false : true} onChange={(e) => handleSLotSelect(e?.target?.value)}
                                className="form-select">
                                <option value='' hidden>Select Time Slot</option>
                                {Array.isArray(slotList) && slotList?.map((o) => {
                                    return <option value={o?.id}>{o?.start_time} To {o?.end_time}</option>
                                })}
                            </select>
                            <p style={{ 'color': 'red' }}>{err?.slot}</p>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 selectDate1">
                            {!viewReports ? <button type="button" className="btn btn-primary" onClick={() => viewReport()}>View</button>
                                : <button type="button"
                                    className="btn btn-primary" onClick={() => handleDownload()}>Download</button>}
                            &nbsp;
                            {(fromDate || toDate || reportList?.length > 0) && <button className="btn btn-primary" onClick={() => clearReport()}>Clear</button>}
                        </div>
                    </div>

                </div>
                <div className="card">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="table-responsive">
                                    {
                                        (Array.isArray(Presentees)) && <h4 className="mt-2 p-2">Presents-{Presentees?.length}</h4>
                                    }
                                    {(Array.isArray(Presentees) && Presentees?.length > 0) &&
                                        <table className="table onboardTable">
                                            <thead className="bg-light">
                                                <tr className="border-0">
                                                    <th className="border-0">S.No</th>
                                                    <th className="border-0">Name</th>
                                                    {/* <th className="border-0">Member Id</th> */}
                                                    <th className="border-0">Date</th>
                                                    <th className="border-0">In Time</th>

                                                    {/* <th className="border-0">Remarks</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(Array.isArray(Presentees) && Presentees?.length > 0) && Presentees?.map((item, index) => {
                                                    return <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.name} ({item?.member_id})</td>
                                                        {/* <td>{item?.member_id}</td> */}
                                                        <td>{item?.date}</td>
                                                        <td>{item?.in_time ? item?.in_time : "Not available"}</td>
                                                        {/* <td>{item?.status ? item?.status : "Not available"}</td> */}
                                                    </tr>
                                                })}

                                            </tbody>
                                        </table>}
                                    {Presentees?.length === 0 && <div className="d-flex justify-content-center mt-5">
                                        <div className="NodataFound mb-3">
                                            <h3>No Data Found</h3>
                                        </div>
                                        <div className="NodataImage">
                                            <img src={nodatafound} alt="No Data Found" className="NoData" />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="table-responsive">
                                    {
                                        (Array.isArray(Absentees)) && <h4 className="mt-2 p-2">Absents-{Absentees?.length}</h4>
                                    }
                                    {(Array.isArray(Absentees) && Absentees?.length > 0) &&
                                        <table className="table onboardTable">
                                            <thead className="bg-light">
                                                <tr className="border-0">
                                                    <th className="border-0">S.No</th>
                                                    <th className="border-0">Name</th>
                                                    {/* <th className="border-0">Member Id</th> */}
                                                    <th className="border-0">Date</th>
                                                    <th className="border-0">In Time</th>

                                                    {/* <th className="border-0">Remarks</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(Array.isArray(Absentees) && Absentees?.length > 0) && Absentees?.map((item, index) => {
                                                    return <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.name} ({item?.member_id})</td>
                                                        {/* <td>{item?.member_id}</td> */}
                                                        <td>{item?.date}</td>
                                                        <td>{item?.in_time ? item?.in_time : "Not available"}</td>
                                                        {/* <td>{item?.status ? item?.status : "Not available"}</td> */}
                                                    </tr>
                                                })}

                                            </tbody>
                                        </table>}
                                    {Absentees?.length === 0 && <div className="d-flex justify-content-center mt-5">
                                        <div className="NodataFound mb-3">
                                            <h3>No Data Found</h3>
                                        </div>
                                        <div className="NodataImage">
                                            <img src={nodatafound} alt="No Data Found" className="NoData" />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import Actions from "../action";

const initialState = {
    isLoading: false,
    deleteRoom: undefined,
    error: false
}
const DeleteRoomReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.DELETE_ROOM: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DELETE_ROOM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                deleteRoom: action.data,
                error: false
            };
        }
        case Actions.DELETE_ROOM_FAILURE: {
            return {
                ...state,
                isLoading: false,
                deleteRoom: undefined,
                error: true,
            };
        }
        default:
            return state;
    }
};

export default DeleteRoomReducer;
import Actions from "../action";

const initialState = {
    isLoading: false,
    daywise_report: undefined,
    error: false
}
const DaywiseReport = (state = initialState, action = {}) => {
    switch (action.type) {
    
        case Actions.DAYWISE_REPORT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DAYWISE_REPORT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                daywise_report: action.data?.response || [],
                error: false
            };
        }
        case Actions.DAYWISE_REPORT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                daywise_report: undefined,
                error: true,
            };
        }

    
        default:
            return state;
    }
};
export const getDaywiseReport = (state) => state?.DAYWISE_REPORT?.daywise_report;


export default DaywiseReport;
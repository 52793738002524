import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { TOGGLE, TOGGLE_HOSTELLER } from '../action/ToggleAction'
import { doPost } from '../../service/index';
import END_POINTS from './EndPoints';

export function* toggleSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.TOGGLE_API, params)
        yield put(Actions.toggleSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.toggleFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* toggleHostellerSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.TOGGLE_HOSTELLER_API, params)
        yield put(Actions.toggleSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.toggleFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* toggleWatcher() {
    yield all([
        takeLatest(TOGGLE, toggleSaga),
        takeLatest(TOGGLE_HOSTELLER, toggleHostellerSaga),
    ]);
}
import Actions from "../action";

const initialState = {
    isLoading: false,
    member: undefined,
    error: false
}
const MemberReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.MEMBER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MEMBER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                member: action.data,
                error: false
            };
        }
        case Actions.MEMBER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                member: undefined,
                error: true,
            };
        }
        default:
            return state;
    }
};
export const memberData = (state) => state?.MEMBER_CRED?.member?.response;
export default MemberReducer;
import { doAction, doResponseAction } from ".";
 
const ONBOARDINGLIST = 'ONBOARDINGLIST';
const ONBOARDINGLIST_SUCCESS = 'ONBOARDINGLIST_SUCCESS';
const ONBOARDINGLIST_FAILURE = 'ONBOARDINGLIST_FAILURE';
const ONBOARDING_SEARCH_LIST = 'ONBOARDING_SEARCH_LIST';
 
const ONBOARDINGSTAFFLIST = 'ONBOARDINGSTAFFLIST';
const ONBOARDINGSTAFFLIST_SUCCESS = 'ONBOARDINGSTAFFLIST_SUCCESS';
const ONBOARDINGSTAFFLIST_FAILURE = 'ONBOARDINGSTAFFLIST_FAILURE';
const ONBOARDINGSTAFFLIST_SEARCH_LIST = 'ONBOARDINGSTAFFLIST_SEARCH_LIST';
 
const DELETE_MEMBER_LIST = "DELETE_MEMBER_LIST"
const DELETE_MEMBER_LIST_SUCCESS = "DELETE_MEMBER_LIST_SUCCESS"
const DELETE_MEMBER_LIST_FAILURE = "DELETE_MEMBER_LIST_FAILURE"
 
const GENERATE_PASSWORD = "GENERATE_PASSWORD"
const GENERATE_PASSWORD_SUCCESS = "GENERATE_PASSWORD_SUCCESS"
const GENERATE_PASSWORD_FAILURE = "GENERATE_PASSWORD_FAILURE"
 
export {
    ONBOARDINGLIST,
    ONBOARDINGLIST_FAILURE,
    ONBOARDINGLIST_SUCCESS,
    ONBOARDING_SEARCH_LIST,
    ONBOARDINGSTAFFLIST,
    ONBOARDINGSTAFFLIST_FAILURE,
    ONBOARDINGSTAFFLIST_SUCCESS,
    ONBOARDINGSTAFFLIST_SEARCH_LIST,
    DELETE_MEMBER_LIST,
    DELETE_MEMBER_LIST_SUCCESS,
    DELETE_MEMBER_LIST_FAILURE,
 
    GENERATE_PASSWORD,
    GENERATE_PASSWORD_SUCCESS,
    GENERATE_PASSWORD_FAILURE
}
// ATTENDANCE
export const onboardingAction = (params, callback) => doAction(ONBOARDINGLIST, params, callback);
export const onboardingSuccessAction = data => doResponseAction(ONBOARDINGLIST_SUCCESS, data);
export const onboardingFailureAction = data => doResponseAction(ONBOARDINGLIST_FAILURE, data);
export const onboardingSearchAction = data => doResponseAction(ONBOARDING_SEARCH_LIST, data);
 
export const onboardingStaffAction = (params, callback) => doAction(ONBOARDINGSTAFFLIST, params, callback);
export const onboardingStaffSuccessAction = data => doResponseAction(ONBOARDINGSTAFFLIST_SUCCESS, data);
export const onboardingStaffFailureAction = data => doResponseAction(ONBOARDINGSTAFFLIST_FAILURE, data);
export const onboardingStaffSearchAction = data => doResponseAction(ONBOARDINGSTAFFLIST_SEARCH_LIST, data);
 
export const deleteMember = (params, callback) => doAction(DELETE_MEMBER_LIST, params, callback);
export const deleteMemberSuccess = (params, callback) => doAction(DELETE_MEMBER_LIST_SUCCESS, params, callback);
export const deleteMemberFailure = (params, callback) => doAction(DELETE_MEMBER_LIST_FAILURE, params, callback);
 
export const GeneratePassword = (params, callback) => doAction(GENERATE_PASSWORD, params, callback);
export const GeneratePasswordSuccess = (params, callback) => doAction(GENERATE_PASSWORD_SUCCESS, params, callback);
export const GeneratePasswordFailure = (params, callback) => doAction(GENERATE_PASSWORD_FAILURE, params, callback);
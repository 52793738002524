import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { WORKER_ROOM_LIST,CREATE_WORKER_ROOM,WORKER_ROOM_UPDATE,WORKER_ROOM_DELETE,WORKER_ROOM_MAPPING_LIST } from '../action/WorkerRoomAction'
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';
import { CREATE_HOSTEL, HOSTELLER_LIST, HOSTEL_DELETE, HOSTEL_LIST, HOSTEL_LIST_SELECT, HOSTEL_UPDATE } from '../action/HostelAction';

export function* hostelListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.HOSTEL_LIST, params)
        yield put(Actions.hostelListSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.hostelListFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* hostellerListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.HOSTELLERS, params)
        yield put(Actions.hostellerListSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.hostellerListFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* hostelListSelectSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.HOSTEL_LIST_SELECT, params)
        yield put(Actions.hostelListSelectSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.hostelListSelectFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* hostelCreateSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_HOSTEL, params)
        yield put(Actions.createHostelSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.createHostelFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* hostelUpdateSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.UPDATE_HOSTEL, params)
        yield put(Actions.hostelUpdateSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.hostelUpdateFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* hostelDeleteSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.DELETE_HOSTEL, params)
        yield put(Actions.hostelDeleteSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.hostelDeleteFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export default function* hostelWatcher() {
    yield all([
        takeLatest(HOSTEL_LIST, hostelListSaga),
        takeLatest(HOSTELLER_LIST, hostellerListSaga),
        takeLatest(HOSTEL_LIST_SELECT, hostelListSelectSaga),
        takeLatest(CREATE_HOSTEL, hostelCreateSaga),
        takeLatest(HOSTEL_UPDATE, hostelUpdateSaga),
        takeLatest(HOSTEL_DELETE, hostelDeleteSaga),
    ]);
}
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppLoader from "../component/AppLoader";
import Actions from "../Redux/action";
import { hostelDashboardData } from "../Redux/reducer/HostelDashboardReducer";

export const HostelDashBoard = () => {
    const dispatch = useDispatch();
    const [hostelDashboard, setHostelDashboard] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const data = useSelector(hostelDashboardData);

    useEffect(() => {
        const callback = (response) => {
            if (response?.status_code === 0) {
                setHostelDashboard(response?.response);
            } else {
                toast.error("Something went wrong");
            }
            setLoading(false);
        };

        setLoading(true);
        dispatch(Actions.hostelDashboardAction('', callback));
    }, [dispatch]);

    const renderHostelData = (title, data) => (
        <div>
            <div className="row">
                <div className="col-12">
                    <h3 className="pageheader-title pageheader-spacing">{title}</h3>
                </div>
            </div>
            {Array.isArray(data) && data.map((hostel, index) => (
                <div key={index}>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="pageheader-title">{hostel?.name}</h4>
                        </div>
                    </div>
                    <div className="row">
                        {hostel?.data && hostel.data.map((entry, i) => (
                            Object.keys(entry).map((key, j) => (
                                <div className="dashboard-card-align" key={j}>
                                    <div className="card card-hostel-dashboard border-3 border-top border-top-primary">
                                        <div className="card-body dashboardCards">
                                            <h4 className="text-muted bold card-text-muted">{key}</h4>
                                            <h5 className="text-muted">{entry[key]}</h5>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <>
            {isLoading && <AppLoader />}
            <div className="ecommerce-widget">
                <div className="page-header">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="pageheader-title">Dashboard</h2>
                        </div>
                    </div>
                </div>
                {hostelDashboard?.today && renderHostelData("Today", hostelDashboard.today)}
                {hostelDashboard?.yesterday && renderHostelData("Yesterday", hostelDashboard.yesterday)}
            </div>
        </>
    );
};

import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';
import { NOTIFICATION_LIST } from '../action/NotificationListAction';

export function* notificationListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.NOTIFICATION_LIST, params)
        yield put(Actions.notificationListSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.notificationListFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}



export default function* notificationListWatcher() {
    yield all([
        takeLatest(NOTIFICATION_LIST, notificationListSaga),

    ]);
}

const protocol = window.location.protocol || 'http:';
const hostname = window.location.hostname || 'localhost';
let port = window.location.port;

if (!port) {
    port = protocol === 'http:' ? 80 : protocol === 'https:' ? 443 : ''
}

let baseUrl = protocol + '//' + hostname + ":9000/";
if (hostname === 'localhost' && (port === '3000' || port === '3001')) {
    baseUrl = 'http://faceappapi-dev.proglint.com';
}

if (hostname === 'srm-cvshield.proglint.com') {
    baseUrl = 'https://srm-api.proglint.com'
} else if (hostname === 'faceappweb-dev.proglint.com') {
    baseUrl = 'http://faceappapi-dev.proglint.com'
}

export const BASE_URL = baseUrl
export const IMAGE_URL = baseUrl

const END_POINTS = {

    CAMERA_LIST: '/list/cameras',
    CREATE_CAMERA: '/onboard/camera',
    UPDATE_CAMERA: '/edit/camera',
    DELETE_CAMERA: '/delete/camera',

    NOTIFICATION_LIST: '/notification/list',

    HOSTEL_REPORT_API: 'report/hostel',
    DAYWISE_REPORT_API: 'report/hostel/day/wise',

    WORKER_LIST_ACTIVE: '/list/workers/select',
    WORKER_LIST: '/list/workers',
    CREATE_WORKER: '/onboard/worker',
    UPDATE_WORKER: '/edit/worker',
    DELETE_WORKER: '/delete/worker',
    MASTER_SLOT_SAVE: '/room/slots/master_slots_save',
    MASTER_SLOT_DELETE: '/room/slots/master_slots_delete',
    MASTER_SLOT_UPDATE: '/room/slots/master_slots_update',
    WORKER_ROOM_LIST: '/list/workers',
    CREATE_WORKER_ROOM: '/map/room/worker',
    UPDATE_WORKER_ROOM: '/edit/room/worker',
    DELETE_WORKER_ROOM: '/delete/room/worker',
    WORKER_ROOM_MAPPING_LIST: '/list/room/unmapped',
    HOSTEL_LIST: '/list/hostel',
    HOSTELLERS: '/list/hostellers',
    CREATE_HOSTEL: '/onboard/hostel',
    UPDATE_HOSTEL: '/edit/hostel',
    DELETE_HOSTEL: '/delete/hostel',

    DASHBOARD_API: "dashboard",
    HOSTEL_DASHBOARD_API: "dashboard/hostel",
    CLIENT_CREDENTIALS: '/oauth/token',
    LOGIN_API: 'login',
    LOGOUT_API: "logout",
    ATTENDANCE_API: "attendance_report",
    HOSTEL_ATTENDANCE_API: "attendance_report/hostel",
    HOSTEL_LIST_SELECT: "list/hostel/select",
    DELETE_MEMEBER: "member/offboard",
    ONBOARDING_API: "get_onboarded_customers",
    ONBOARDINGSTAFF_API: "staff/list",
    GENERATE_PASSWORD: "user/forget_password",
    SEARCH_API: "member_search",
    SCHEDULER_SLOTS: 'master/slots/list',
    UPDATE_SCHEDULER: '/room/slots/update',
    CREATE_SCHEDULER: '/room/slots/save',
    COLLEGE_LIST: '/college/list',
    COURSE_LIST: '/course/list',
    SECTION_LIST: '/sections/list',
    DEPARTMENT_LIST: '/department/list',
    DEPARTMENT__SEARCH: "/room/section/search",
    SLOT_LIST: '/slot/list',
    SAVE_COMBRIFACE: '/room/section/compre_face_save',
    YEAR_LIST: '/years/list',
    SEARCH_LIST_API: "get_specific_member",
    MEMBER_LIST_API: "members_list",
    CREATE_ROOM_API: "create_room",
    ROOM_LIST_API: "get_registered_rooms",
    ROOM_API: "get_all_rooms",
    DELETE_ROOM_API: "delete_room",
    EDIT_MEMBER_LIST: "member_list_for_edit",
    UPDATE_ROOM_API: "edit_room",
    NOTIFICATION_HISTORY_API: "get_notification_history",
    TOGGLE_API: "toggle_active_status",
    TOGGLE_HOSTELLER_API: "toggle/hosteller",
    SIGN_OUT_API: '/api/signout',

    HOSTEL_SPECIAL_ACCESS_LIST: 'list/special/access',
    CREATE_HOSTEL_SPECIAL_ACCESS: 'register/special/access',
    DELETE_HOSTEL_SPECIAL_ACCESS: 'delete/special/access',
};

export default END_POINTS;

import React from 'react'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSectionList, getYearList, getdepartmentList } from "../Redux/reducer/SchedulerReducer";
import Actions from "../Redux/action";
import { useLocation, useNavigate } from "react-router";
import { days } from "../Utils/Constant";
import { toast } from 'react-toastify';
import AppLoader from '../component/AppLoader';
import moment from 'moment';
import DownloadSectionReport from './DownloadSectionReport';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver'
const SectionTimeTable = () => {
    const EXCEL_EXTENSION = '.xlsx'
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const [year, setYear] = useState("")
    const [section, setSection] = useState("")
    const [department, setDepartment] = useState('')
    // const [dragging, setDragging] = useState(false);
    // const [dataSelect, setDataSelect] = useState({})
    const [droppedText, setDroppedText] = useState(1);
    // const [alertModal, setAlertModal] = useState(false)
    const [selectSlot, setSelectSlot] = useState([])
    const [openDownloadReportModal, setOpenDownloadReportModal] = useState(false)
    // const isloading=useSelector(getLoading)
    const departmentList = useSelector(getdepartmentList)
    const yearList = useSelector(getYearList)
    const sectionList = useSelector(getSectionList)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { state } = useLocation()
    console.log(state,"state")
    const collegeId = searchParams.get('collegeId');
    const dispatch = useDispatch()
    const [schedulerData, setSchedulerData] = useState([])
    const navigate = useNavigate()
    const [isloading, setLoader] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setLoader(true)
        const params = {
            id: collegeId
        }
        const callback = (res) => {
            setLoader(false)
            let mainData = {}
            if (res?.status_code === 0) {
                days?.forEach((dObj, dIndex) => {
                    res?.response?.forEach((cObj, cIndex) => {
                        if (dObj.type === cObj.day) {
                            if (mainData[dObj.day]) {
                                mainData[dObj.day].push(cObj)
                            } else {
                                mainData[dObj.day] = [cObj]
                            }
                        }
                    })
                })
            }
            if (state?.data?.room_slots?.length > 0) {
                let slotsArr = []
                Object.entries(mainData).forEach(([day, items]) => {
                    items?.forEach(element => {
                        state?.data?.room_slots?.forEach((rs) => {
                            if (rs?.slot_id === element?.id) {
                                const slot = {
                                    section_id: Number(rs?.section_id),
                                    slot_id: Number(element?.id),
                                    day: Number(rs?.day)
                                }
                                slotsArr.push(slot)
                                element['section_id'] = rs?.section_id
                                element['room_name'] = rs?.room?.name
                                element['room_id'] = rs?.room?.id
                                element['fromDate'] = rs?.from_date
                                element['toDate'] = rs?.to_date
                            }
                        })
                    })
                })
                setSelectSlot(slotsArr)
            }
            setSchedulerData(mainData)
        }
        dispatch(Actions.CoursetAction(params))
        const schedulerParams = {
            department_id: state?.data?.department_id
        }
        dispatch(Actions.SchedulerListAction(schedulerParams, callback))
    }, [dispatch, collegeId, state?.data?.room_slots])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //     const params = {
    //         id: course
    //     }
    //     dispatch(Actions.DepartmentAction(params))

    // }, [course])

    // useEffect(() => {
    //     departmentSearchList()
    // }, [dispatch])



    // const departmentSearchList = () => {
    //     const params = {
    //         // course: search,
    //         college: collegeId

    //     }
    //     dispatch(Actions.DepartmentSearchSectionList(params))

    // }
    // useEffect(() => {
    //     const params = {
    //         id: course
    //     }
    //     dispatch(Actions.YearAction(params))
    // }, [department])

    // useEffect(() => {
    //     const params = {
    //         id: department,
    //         year: year
    //     }
    //     dispatch(Actions.SectiontAction(params))
    // }, [year])


    const handleSlot = (data, dataDay) => {
        if (state?.data?.timeTable) {
            if (handleDay(dataDay, data).selected === "prev") {
                toast.error("Please Update in future day")
            } else {
                Object.entries(schedulerData).forEach(([day, items]) => {
                    if (day === dataDay) {
                        if (data.section_id) {
                            // setAlertModal(true)
                            // setDataSelect(data)
                        } else {
                            handleDrop(data)
                        }
                    }
                })
            }
        } else {
            Object.entries(schedulerData).forEach(([day, items]) => {
                if (day === dataDay) {
                    if (data.section_id) {
                        // setAlertModal(true)
                        // setDataSelect(data)
                    } else {
                        handleDrop(data)
                    }
                }
            })
        }
    }

    const handleDrop = (data) => {
        // setDragging(data)
        let finalArray = schedulerData
        const slot = {
            section_id: Number(section),
            slot_id: Number(data?.id),
            day: Number(data?.day)
        }
        setSelectSlot([...selectSlot, slot])
        Object.entries(finalArray).forEach(([day, items]) => {
            items?.forEach(element => {
                if (element?.day === data?.day) {
                    if (element?.id === data?.id) {
                        element['section_id'] = section
                        element['section_name'] = Array.isArray(sectionList) && sectionList?.find((f) => f?.id === Number(section))?.name
                        element['department_id'] = department
                        element['department_name'] = Array.isArray(departmentList) && departmentList?.find((f) => f?.id === Number(department))?.name
                        element['year_id'] = year
                        element['year_name'] = Array.isArray(yearList) && yearList?.find((f) => f?.id === Number(year))?.name
                    }
                }
            });
        })
        setSchedulerData(finalArray)
        setDroppedText(prevState => prevState + 1)
        // setAlertModal(false)
        // setCourse("")
        setDepartment("")
        setYear("")
        setSection("")

    };


    const handleDragOver = (event) => {
        event.preventDefault();
    };






    const handleDay = (selectDay, item) => {
        const day = new Date()
        let selected = ''
        const currentDay = day.getDay()
        let currentTime = ''
        let getDateNum = ''
        if (selectDay === "Monday") {
            getDateNum = 1
        } else if (selectDay === "Tuesday") {
            getDateNum = 2
        } else if (selectDay === "Wednesday") {
            getDateNum = 3
        } else if (selectDay === "Thursday") {
            getDateNum = 4
        } else if (selectDay === "Friday") {
            getDateNum = 5
        } else if (selectDay === "Saturday") {
            getDateNum = 6
        }
        if (getDateNum < currentDay) {
            selected = "prev"
        } else if (getDateNum === currentDay) {
            selected = "current"
            const getCurrentTime = day.toLocaleTimeString();
            if (getCurrentTime > item.start_time) {
                currentTime = true
            } else {
                currentTime = false
            }

        } else {
            selected = "future"
        }
        let obj = {}
        obj.selected = selected
        obj.currentTime = currentTime
        return obj
    }

    const openDownloadReport = (data, day) => {
        const today = new Date();
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayName = daysOfWeek[today.getDay()];
        const findDay = daysOfWeek.findIndex(o => o == day)
        // console.log(dayName, day, findDay, (findDay - today.getDate() + 7) % 7, "dayname")


        // let daysDiff = (findDay - today.getDate() + 7) % 7;
        // console.log(daysDiff, "daysdiff")
        today.setDate(today.getDate() );
        setLoader(true)
        const saveAsExcelFile = (buffer, fileName) => {
            const data = new Blob([buffer], { type: EXCEL_TYPE }) || [];
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
        }
        const callback = (response) => {
            setLoader(false)
            if (response?.status_code === 0) {
                const { Absentees, Presentees } = response?.response || {}
                let modifiedPresentJSON = Presentees?.map((obj) => ({ ...obj, Present: "Yes" }))
                let modifiedAbsentJSON = Absentees?.map((obj) => ({ ...obj, Present: "No" }))
                const mergedJSON = modifiedPresentJSON?.concat(modifiedAbsentJSON)
                const worksheet = XLSX.utils.json_to_sheet(mergedJSON);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'Report')
                toast.success("Downloaded Successfully")
            }
            else {
                toast.error("Something went worng")
            }
        }
        let st_time = moment(data.fromDate).format('YYYY-MM-DD') + ' ' + data.start_time
        let et_time = moment(data.toDate).format('YYYY-MM-DD') + ' ' + data.end_time
        let params = {
            date: moment.utc(today).utcOffset(+`-${moment().utcOffset()}`).format('YYYY-MM-DD'),
            status: "all",
            slot:data?.id,
            section:state?.data?.id
        }

        dispatch(Actions.attendanceAction(params, callback))
    }


    const styleObject = (day, item) => {
        let bgColor = `${(handleDay(day, item).currentTime || handleDay(day, item).selected === "prev") && item?.section_id ? '#000' : item?.section_id ? 'green' : 'lightgray'}`
        let color = `${(handleDay(day, item).currentTime || handleDay(day, item).selected === "prev") && item?.section_id ? 'white' : 'black'}`

        return {
            backgroundColor: bgColor,
            color: color
        }
    }

    return <React.Fragment>
        {
            isloading && <AppLoader />
        }
        {/* <h2>Scheduler</h2> */}
        <div className='row margin-left'>
            <div className='col-sm-6'>
                <i className="fa fa-arrow-left cursor-pointer" onClick={() => navigate("/schedularList", { state: state })} aria-hidden="true"></i>
                <span style={{ marginLeft: '10px' }}><b>Course: {state?.data?.course}</b></span>
            </div>
            <div className='col-sm-6 text-end'>
                <div>
                    <b>{state?.data?.department} ({state?.data?.year})</b>
                </div>
                <div>
                    <b>Section: {state?.data?.section}</b>
                </div>
            </div>
        </div>
        <br />
        <div className='row margin-left'>
            <div className='col-sm-12'>
                <div className="card">
                    <h5 className="card-header">Time Table</h5>
                    <div className="card-body p-3">
                        <div className="table-responsive">
                            <table className="table onboardTable">
                                <thead className="bg-light">
                                    <tr className="">
                                        {Object.keys(schedulerData)?.map((obj) => <td>{obj}</td>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="">
                                        <> {Object.entries(schedulerData).map(([day, items]) => (
                                            <td className='border-1'>
                                                {items?.map((item, index) => {
                                                    return (
                                                        <div
                                                            style={styleObject(day, item)}
                                                            className={`card mb-3 ${item?.section_id ? 'scheduler-card' : 'scheduler-card-inactive'}`}
                                                            onDragOver={handleDragOver}
                                                            onDrop={() => handleSlot(item, day)}>
                                                            <div className='row'>
                                                                <div className='col-sm-6 text-end'>
                                                                </div>
                                                                <div className='col-sm-6 text-end mr-0'>
                                                                    {((handleDay(day, item).currentTime || handleDay(day, item).selected === "prev") && item?.section_id) &&
                                                                        <span className='cursor-pointer'
                                                                            onClick={() => openDownloadReport(item, day)}>
                                                                            <i class="fa-solid fa-download"></i>
                                                                        </span>}
                                                                </div>

                                                            </div>

                                                            <div className='card-body p-0 text-center'>{item.start_time} - {item.end_time}</div>
                                                            {item?.room_name ? <p className='text-center'>{item?.room_name}</p> : null}

                                                        </div>
                                                    )
                                                })}
                                            </td>
                                        ))}
                                        </>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <div className='text-end'>
                            <button
                                type="button"
                                className="btn"
                                style={{ backgroundColor: 'lightgray' }}
                                onClick={() => navigate('/roomlist')}>Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                style={{ marginLeft: '20px' }}
                                onClick={() => CreateScheduler()}>{state?.room_slots?.length ? 'Update' : 'Create'}
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>

        </div>


        {openDownloadReportModal && <DownloadSectionReport onClose={() => setOpenDownloadReportModal()} droppedText={droppedText} />}
    </React.Fragment>
}
export default SectionTimeTable;
import Actions from "../action";
const initialState = {
    isLoading: false,
    room: [],
    error: false
}
const RoomReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.ROOM: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case Actions.ROOM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                room: action.data,
                error: false
            };
        }
        case Actions.ROOM_FAILURE: {
            return {
                ...state,
                isLoading: false,
                room: undefined,
                error: action.error,
            };
        }

        case Actions.CLEAR_ROOMS: {
            return {
                ...state,
                isLoading: false,
                room: [],
               
            };
        }
        default:
            return state;
    }
};
export const roomData = (state) => state?.ROOM_CRED?.room?.response;
export default RoomReducer;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router";
import Actions from "../Redux/action";
import { toast } from "react-toastify";
import nodatafound from '../asset/img/datanotfound_black.png';
import AppLoader from "../component/AppLoader";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_EXTENSION = '.xlsx'
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export const DaywiseReport = () => {
    const { hostelId, date } = useParams();
    const dispatch = useDispatch();
    const [viewReports, setViewReport] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [params, setParams] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const callback = (response) => {
            if (response?.status_code === 0) {
                setParams(response?.response);
                setViewReport(response?.response?.data);
                setLoading(false);
            } else {
                toast.error(response?.message);
                setLoading(false);
            }
        };

        const params = {
            hostel_id: hostelId,
            date: date
        };

        setLoading(true);
        dispatch(Actions.daywiseReportAction(params, callback));
    }, [dispatch, hostelId, date]);

    const handleBack = () => {
        navigate('/hostelreport', {
            state: location.state
        });
    };

    const exportAsExcelFile = (json, excelFileName) => {
        // Extract presentees and absentees data
        const mapCameraType = (data) => {
            return data.map(item => ({
                ...item,
                camera_type: item.camera_type === 'EN' ? 'Entry' : item.camera_type === 'EX' ? 'Exit' : item.camera_type
            }));
        };
        const presentees = mapCameraType(json?.presentees);
        const absentees = mapCameraType(json?.absentees);

        // Convert presentees and absentees to worksheets
        const presenteesSheet = XLSX.utils.json_to_sheet(presentees);
        const absenteesSheet = XLSX.utils.json_to_sheet(absentees);

        // Apply column widths (optional)
        const applyColumnWidths = (worksheet) => {
            const columnLengths = {};
            Object.keys(worksheet[0]).forEach(key => {
                columnLengths[key] = key.length;
            });
            worksheet.forEach(row => {
                Object.keys(row).forEach(key => {
                    const columnLength = (row[key] || '').toString().length;
                    columnLengths[key] = Math.max(columnLengths[key] || 0, columnLength);
                });
            });
            return Object.keys(columnLengths).map(key => ({ wch: columnLengths[key] + 1 }));
        };

        presenteesSheet['!cols'] = applyColumnWidths(presentees);
        absenteesSheet['!cols'] = applyColumnWidths(absentees);

        // Create workbook with two sheets
        const workbook = {
            Sheets: {
                'Presentees': presenteesSheet,
                'Absentees': absenteesSheet
            },
            SheetNames: ['Presentees', 'Absentees']
        };

        // Convert workbook to Excel file buffer
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Save Excel file
        saveAsExcelFile(excelBuffer, excelFileName);
    }

    const saveAsExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
    const handleReportDownload = () => {
        exportAsExcelFile(viewReports, "Reports")
    }
    return (
        <>
            {isLoading && <AppLoader />}
            <div className="ecommerce-widget">
                <h5 className="card-header">Day-wise Report</h5>
                <div className="mb-2 mt-3" style={{ textAlign: 'end' }}>
                    <button type="button" className="btn btn-primary ml-3" onClick={() => handleReportDownload()}>Download Report</button>
                    &nbsp;
                    <button type="button" className="btn btn-primary" onClick={handleBack}>Back</button>
                </div>
                <h5 className="card-day-wise-heading">Report for {params?.hostel} On {params?.date}</h5>
                <div className="card border-3 border-top border-top-primary mb-2">
                    {viewReports && (
                        <div className="row mt-4 row-day-wise-report">
                            <div className="dashboard-card-align pe-2">
                                <div className="card card-hostel-dashboard border-3 border-top border-top-primary">
                                    <div className="card-body dashboardCards">
                                        <h4 className="text-muted bold card-text-muted">Total Students</h4>
                                        <h4 className="text-muted">{viewReports?.total_students ? viewReports?.total_students : 0}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-card-align pe-2">
                                <div className="card card-hostel-dashboard border-3 border-top border-top-primary">
                                    <div className="card-body dashboardCards">
                                        <h4 className="text-muted bold card-text-muted">Presentees</h4>
                                        <h5 className="text-muted">{viewReports?.total_presentees ? viewReports?.total_presentees : 0}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-card-align pe-2">
                                <div className="card card-hostel-dashboard border-3 border-top border-top-primary">
                                    <div className="card-body dashboardCards">
                                        <h4 className="text-muted bold card-text-muted">Absentees</h4>
                                        <h5 className="text-muted">{viewReports?.total_absentees ? viewReports?.total_absentees : 0}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-card-align">
                                <div className="card card-hostel-dashboard border-3 border-top border-top-primary">
                                    <div className="card-body dashboardCards">
                                        <h4 className="text-muted bold card-text-muted">Not appeared but present</h4>
                                        <h5 className="text-muted">{viewReports?.not_appeared_present ? viewReports?.not_appeared_present : 0}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-card-align pe-2">
                                <div className="card card-hostel-dashboard border-3 border-top border-top-primary">
                                    <div className="card-body dashboardCards">
                                        <h4 className="text-muted bold card-text-muted">Special Access</h4>
                                        <h5 className="text-muted">{viewReports?.special_access_entries ? viewReports?.special_access_entries : 0}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {viewReports && (
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="table-responsive table-container">
                                        <table className="table onboardTable">
                                            <thead className="bg-light">
                                                <tr className="border-0">
                                                    <th className="border-0">Present Students</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {viewReports?.presentees?.map((person) => (
                                                    <tr key={person.member_id}>
                                                        <td>{person.name} ({person.member_id})</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="table-responsive table-container">
                                        <table className="table onboardTable">
                                            <thead className="bg-light">
                                                <tr className="border-0">
                                                    <th className="border-0">Absent Students</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {viewReports?.absentees?.map((person) => (
                                                    <tr key={person.member_id}>
                                                        <td>{person.name} ({person.member_id})</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {(!viewReports?.presentees?.length && !viewReports?.absentees?.length) && (
                                <div className="d-flex justify-content-center mt-5">
                                    <div className="NodataFound mb-3">
                                        <h3>No Data Found</h3>
                                    </div>
                                    <div className="NodataImage">
                                        <img src={nodatafound} alt="No Data Found" className="NoData" />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

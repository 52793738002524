import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './reducer/index';
import storage from 'redux-persist/lib/storage';
import sagaWatchers from './saga/index';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';


const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router'],
};
const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(sagaMiddleware, logger);
const persistedReducer = persistReducer(persistConfig, rootReducer);

let composedMiddleware = compose(middleware);
if (!process.env.REACT_APP_DEBUG) {
  composedMiddleware = composeWithDevTools(middleware);
}
export const store = createStore(persistedReducer, {}, composedMiddleware);
export const persistor = persistStore(store);

sagaMiddleware.run(sagaWatchers);
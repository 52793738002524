import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { EDIT_MEMBER_LIST } from '../action/EditMemberListActions'
import { doGet } from '../../service/index';
import END_POINTS from './EndPoints';

export function* editMemberListsaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.EDIT_MEMBER_LIST, params)
        yield put(Actions.editMemberListSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.editMemberListFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* editMemberListWatcher() {
    yield all([
        takeLatest(EDIT_MEMBER_LIST, editMemberListsaga),
    ]);
}
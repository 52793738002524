import { useEffect, useState } from 'react'
import Close from '../asset/img/modal-close.svg'
import { useDispatch } from 'react-redux'
import Actions from '../Redux/action'
import { toast } from 'react-toastify'
export const SectionEditModal = ({ onClose, sectionData }) => {
    const [compreFace, setcompreFace] = useState('')
    const [err, setErr] = useState('')
    const dispatch = useDispatch()
    const submit = () => {
        if (!compreFace) {
            setErr("Please enter Compreface")
        } else {
            const callback = (res) => {
                if (res?.status_code === 0) {
                    toast.success("Updated Successfully")
                    onClose()
                }else{
                    toast.error("Something went wrong")
                }
            }
            const params = {
                "section_id": sectionData?.id,
                "compre_face": compreFace
            }
            dispatch(Actions.SaveCombrifaceAction(params, callback))
        }
    }

    useEffect(() => {
        setcompreFace(sectionData.compre_face)
    }, [dispatch, sectionData?.compre_face])
    return (
        <>
            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered  planogram-div">
                    <div className="modal-content rounded-0 p-2">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() => onClose()}
                        />
                        <div className="modal-header rounded-0 align-items-center py-4 px-4">
                            <div className="col">
                                <h4 className="modal-title">Update Compreface </h4>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container">
                                <div className="row">
                                    <span className="form-label">Section</span>
                                    <input className='form-control' value={sectionData.name} disabled={true}></input>
                                    <span className="form-label" style={{ marginTop: '10px' }}>Compreface</span>
                                    <input className='form-control' value={compreFace} onChange={(e) => setcompreFace(e?.target?.value)}></input>
                                    {err && <p style={{color:'red'}}>{err}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="text-end">
                            <button
                                type="button"
                                className="btn"
                                style={{ backgroundColor: 'lightgray' }}
                                onClick={() => onClose()}
                            >Cancel
                            </button>

                            <button className="btn btn-primary submitRoom"
                                style={{ marginLeft: '20px' }}
                                onClick={() => submit()}>Submit
                            </button>
                        </div>
                    </div>

                </div>

            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}
import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { WORKER_LIST,CREATE_WORKER,WORKER_UPDATE,WORKER_DELETE,WORKER_ACTIVE } from '../action/WorkerAction'
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';

export function* workerListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.WORKER_LIST, params)
        yield put(Actions.workerListSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.workerListFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* workerCreateSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_WORKER, params)
        yield put(Actions.createWorkerSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.createWorkerFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* workerUpdateSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.UPDATE_WORKER, params)
        yield put(Actions.workerUpdateSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.workerUpdateFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* workerDeleteSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.DELETE_WORKER, params)
        yield put(Actions.workerDeleteSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.workerDeleteFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* activeWorkerListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.WORKER_LIST_ACTIVE, params)
        yield put(Actions.workerActiveListSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.workerActiveListFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* workerWatcher() {
    yield all([
        takeLatest(WORKER_LIST, workerListSaga),
        takeLatest(CREATE_WORKER, workerCreateSaga),
        takeLatest(WORKER_UPDATE, workerUpdateSaga),
        takeLatest(WORKER_DELETE, workerDeleteSaga),
        takeLatest(WORKER_ACTIVE, activeWorkerListSaga),
    ]);
}
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppLoader from "../component/AppLoader";
import { AddWorkerPopup } from "../component/AddWorker";
import { PaginationComponent } from "../component/PaginationComponent"
import Actions from "../Redux/action";
import { getWorkerListSelector,getWorkerListLoadingSelector,getWorkerCountSelector } from "../Redux/reducer/WorkerReducer";
import nodatafound from '../asset/img/datanotfound_black.png';
import { DeclinePopup } from "../component/DeclinePopup";
export const WorkerList = () => {
    const loading = useSelector(getWorkerListLoadingSelector);
    const totalCount = useSelector(getWorkerCountSelector);
    const [pageNumber, setPageNumber] = useState(1);
    const workerlist = useSelector(getWorkerListSelector);
    const [popupData, setPopupData] = useState();
    const [popup, setPopup] = useState(false);
    const dispatch = useDispatch();
    const [itemObj, setItemObj] = useState('')
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);

    const getWorkerList = useCallback(() => {
        let params = {
            page: pageNumber
        }
        dispatch(Actions.workerList(params))
    }, [dispatch, pageNumber])

    useEffect(() => {
        getWorkerList()
    }, [getWorkerList])

    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const editWorker = (workerData) => {
        setPopupData(workerData)
        setPopup(true)
    }

    const deleteWorker = () => {
        const callback = (response) => {
            if (response?.status_code === 0) {
                onCloseModel()
                toast.success("Worker successfully deleted")
                getWorkerList()
                if (workerlist?.length === 1) {
                    setPageNumber(pageNumber - 1)
                } else {
                    setPageNumber(pageNumber)
                }
            } else {
                toast.error(response?.message)
            }
        }
        let params = {
            id: itemObj?.id
        }
        dispatch(Actions.workerDelete(params, callback))
    }
    const onClosePopUp = () => {
        setPopupData('')
        setPopup(false)
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const handleClick = (item) => {
        setItemObj(item);
        setOpenDeclineModal(true);
    };
    return (
        <>
            {
                loading && <AppLoader />
            }
            <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                <div className="mb-2" style={{ textAlign: 'end' }}>
                    <button type="button" className="btn btn-primary" onClick={() => setPopup(true)}>Add Worker</button>
                </div>
                <div className="card">
                    <h5 className="card-header">Worker List</h5>

                    <div className="card-body p-0">
                        {
                            workerlist && Array.isArray(workerlist) && workerlist?.length > 0 ?
                                <div className="table-responsive">
                                    <table className="table onboardTable">
                                        <thead className="bg-light">
                                            <tr className="border-0">
                                                <th className="border-0">S.No</th>
                                                <th className="border-0">Worker Name</th>
                                                <th className="border-0">Ip Address</th>
                                                <th className="border-0">Mac Address</th>
                                                <th className="border-0">Edit</th>
                                                <th className="border-0">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                workerlist && Array.isArray(workerlist) && workerlist.length > 0 && workerlist?.map((item, index) => {
                                                    return (<tr>
                                                        <td>{((pageNumber - 1) * 10) + (index + 1)}</td>
                                                        <td>{item?.worker_name}</td>
                                                        <td>{item?.ip_address} </td>
                                                        <td>{item?.mac_address} </td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" onClick={() => editWorker(item)}>Edit</button>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" onClick={() => handleClick(item)}>Delete</button>
                                                        </td>
                                                    </tr>)
                                                }
                                                )
                                            }
                                            <tr>
                                                <td colspan="9">
                                                    <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="d-flex justify-content-center mt-5">
                                    <div className="NodataFound">
                                        <h3>No Data Found</h3>
                                    </div>
                                    <div className="NodataImage">
                                        <img src={nodatafound} alt="No Data Found" className="NoData" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {
                popup && <AddWorkerPopup pageNumber={pageNumber} onClose={() => onClosePopUp()} data={popupData}  />

            }
            {isOpenDeclineModal && <DeclinePopup
                title={`Are you sure you want to delete ${itemObj?.worker_name}?`}
                onClose={() => onCloseModel()}
                onSuccess={() => deleteWorker()}
            />}
        </>
    )
}
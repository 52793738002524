import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { CAMERA_LIST,CREATE_CAMERA,CAMERA_UPDATE,CAMERA_DELETE } from '../action/CameraAction'
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';

export function* cameraListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.CAMERA_LIST, params)
        yield put(Actions.cameraListSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.cameraListFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* cameraCreateSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_CAMERA, params)
        yield put(Actions.createCameraSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.createCameraFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* cameraUpdateSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.UPDATE_CAMERA, params)
        yield put(Actions.cameraUpdateSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.cameraUpdateFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* cameraDeleteSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.DELETE_CAMERA, params)
        yield put(Actions.cameraDeleteSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.cameraDeleteFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* CameraWatcher() {
    yield all([
        takeLatest(CAMERA_LIST, cameraListSaga),
        takeLatest(CREATE_CAMERA, cameraCreateSaga),
        takeLatest(CAMERA_UPDATE, cameraUpdateSaga),
        takeLatest(CAMERA_DELETE, cameraDeleteSaga),
    ]);
}
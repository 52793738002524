import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { UPDATE_ROOM } from '../action/UpdateRoom'
import { doPost } from '../../service/index';
import END_POINTS from './EndPoints';

export function* updateRoomSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.UPDATE_ROOM_API, params)
        yield put(Actions.updateRoomSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.updateRoomFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* updateRoomWatcher() {
    yield all([
        takeLatest(UPDATE_ROOM, updateRoomSaga),
    ]);
}
import { useState } from 'react';
import srmLogo from '../asset/img/cv_shield-logo.svg';
import { LogoutPopup } from './LogoutPopup';

export const Header = () => {
    const [logOut, setLogOut] = useState(false);
    const logout = () => {
        setLogOut(true)
    }
    return (
        <>
            <div className="dashboard-header">
                <nav className="navbar navbar-expand-lg bg-white fixed-top">
                    <img className="header-logo-img" src={srmLogo} alt="logo" />
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className='logoutIcon'>
                        <i className="fa fa-sign-out fa-2x" aria-hidden="true" onClick={() => logout()}></i>
                    </div>
                </nav >
            </div >
            {
                logOut && <LogoutPopup close={() => setLogOut(false)} />
            }
        </>
    )
}

import React from "react"
import Close from '../asset/img/modal-close.svg'
const AlertModal = ({ onClose, handleDrop, dataSelect }) => {
    return <>
        <div className="modal d-block"
            id="view-multi-modal"
            tabIndex="-1"
            aria-labelledby="view-multi-modal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sl planogram-div alert-schedule-modal">
                <div className="modal-content rounded-0">
                    <img id="close"
                        src={Close}
                        alt="X"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="modal-close"
                        onClick={() => onClose()}
                    />
                    <div className="modal-header rounded-0 align-items-center py-2 px-4">
                        <div className="col">
                            <h4 className="modal-title">Alert </h4>
                        </div>
                    </div>
                    <div className="modal-body font14 p-40">
                        <div className="container">
                            <h4>Would you like to reschedule this slot?</h4>
                            <div className="row-3">
                                <button
                                    className="btn btn-primary submitRoom"
                                    onClick={() => handleDrop(dataSelect)}>Yes
                                </button>
                                <button
                                    className="btn submitRoom"
                                    style={{ backgroundColor: 'lightgray' ,marginLeft:'10px'}}
                                    onClick={() => onClose()}>No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop show"></div>
    </>
}
export default AlertModal
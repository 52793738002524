import Actions from "../action";

const initialState = {
    isLoading: false,
    notificationList: undefined,
    error: false
}
const NotificationHistoryReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.NOTIFICATION_HISTORY: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.NOTIFICATION_HISTORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                notificationList: action.data,
                error: false
            };
        }
        case Actions.NOTIFICATION_HISTORY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                notificationList: undefined,
                error: true,
            };
        }
        default:
            return state;
    }
};
export const NotificationData = (state) => state?.NOTIFICATION_CRED?.notificationList?.results;

export default NotificationHistoryReducer;
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppLoader from "../component/AppLoader";
import { EditPopup } from "../component/EditPopup";
import { PaginationComponent } from "../component/PaginationComponent"
import Actions from "../Redux/action";
import { roomListData } from "../Redux/reducer/RoomListReducer";
import nodatafound from '../asset/img/datanotfound_black.png';
import { DeclinePopup } from "../component/DeclinePopup";
import { useNavigate } from "react-router";
import { ViewUserModal } from "../component/ViewuserModal";
import { Createroom } from "./Createroom";
export const RoomList = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const navigate = useNavigate()
    const [count, setcount] = useState();
    const [isloading, setLoading] = useState(false);
    const roomlist = useSelector(roomListData);
    const [popupData, setPopupData] = useState();
    const [popup, setPopup] = useState(false);
    const [createPopup, setCreatePopup] = useState(false);
    const [OpenViewUserModal, setOpenViewModal] = useState(false);

    const [roomId, setRoomId] = useState()
    const dispatch = useDispatch();
    const [itemObj, setItemObj] = useState('')
    const [userData, setUserData] = useState([])
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);



    const getroomlist = useCallback(() => {
        let params = {
            page: pageNumber
        }
        const callback = (response) => {
            if (response) {
                setcount(response?.count)
            }
            else {
                toast.error("Something went worng")
            }

        }
        dispatch(Actions.roomListAction(params, callback))
    }, [dispatch, pageNumber])

    useEffect(() => {
        getroomlist()
    }, [pageNumber, dispatch, getroomlist])

    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const EditList = (roomData) => {
        setRoomId(roomData?.id)
        setPopupData(roomData)
        setPopup(true)
    }
    const DeleteRoomList = () => {
        const callback = (response) => {
            if (response?.status_code === 0) {
                onCloseModel()
                const RoomCallback = (response) => {
                    setLoading(false)
                    if (response) {
                        setcount(response?.count)
                        getroomlist()
                    }
                    else {
                        toast.error("Something went worng")
                    }
                }
                toast.success("Deleted Successfully")
                let params = {
                    page: pageNumber
                }
                dispatch(Actions.roomListAction(params, RoomCallback))
            }
            else {
                toast.error(response?.message)
                setLoading(false)
            }
        }
        let params = {
            id: itemObj?.id
        }
        setLoading(true)
        dispatch(Actions.deleteRoomAction(params, callback))
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const handleClick = (item) => {
        setItemObj(item);
        setOpenDeclineModal(true);
    };

    const viewScheduler = (data) => {
        navigate(`/schedular?collegeId=${data?.college?.id}`, { state: data })
    }

    const remainingNameCount = (item) => {
        const count = item.authorized_persons?.length - 5
        return count
    }

    const openViewModal = (user) => {
        setUserData(user)
        setOpenViewModal(true)
    }
    return (
        <>
            {
                isloading && <AppLoader />
            }
            <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                <div className="mb-2" style={{ textAlign: 'end' }}>
                    <button type="button" className="btn btn-primary" onClick={() => setCreatePopup(true)}>Add Room</button>
                </div>
                <div className="card">
                    <h5 className="card-header">Room List</h5>

                    <div className="card-body p-0">
                        {
                            roomlist && roomlist.length > 0 ?
                                <div className="table-responsive">
                                    <table className="table onboardTable">
                                        <thead className="bg-light">
                                            <tr className="border-0">
                                                <th className="border-0">S.No</th>
                                                <th className="border-0">Room Number</th>
                                                <th className="border-0">Room Name</th>
                                                {/* <th className="border-0">Authorized Persons</th> */}
                                                <th className="border-0">Entry Camera</th>
                                                <th className="border-0">Exit Camera</th>
                                                <th className="border-0">College</th>
                                                <th className="border-0">Available Dates</th>
                                                <th className="border-0">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                roomlist && roomlist?.map((item, index) =>
                                                    <tr>
                                                        <td>{((pageNumber - 1) * 10) + (index + 1)}</td>
                                                        <td>{item.room_no}</td>
                                                        <td>{item?.name} </td>
                                                        {/* <td className="authorizedBox">
                                                            {item?.authorized_persons?.length > 1 ?
                                                                <>  <div >
                                                                    {item?.authorized_persons[0]?.name}
                                                                </div>
                                                                    <div >
                                                                        {item?.authorized_persons[1]?.name}
                                                                    </div>
                                                                    <div >
                                                                        {item?.authorized_persons[2]?.name}
                                                                    </div>
                                                                    <div >
                                                                        {item?.authorized_persons[3]?.name}
                                                                    </div><div >
                                                                        {item?.authorized_persons[4]?.name}
                                                                    </div><span>{`..+(${remainingNameCount(item)})`}<span style={{ color: 'red' }} className="cursor-pointer" onClick={()=>openViewModal(item?.authorized_persons)}>Others</span></span></>
                                                                : item?.authorized_persons.map((user, i) =>
                                                                    <div key={i}>
                                                                        {user?.name}
                                                                    </div>)}
                                                        </td> */}
                                                        <td>
                                                            {
                                                                item?.cameras.map((cameraEntry, innerindex) =>
                                                                    <div key={innerindex}>
                                                                        {
                                                                            cameraEntry?.camera_type === 'EN' && cameraEntry?.camera_name
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                item?.cameras.map((cameraEntry, innerindex) =>
                                                                    <div key={innerindex}>
                                                                        {
                                                                            cameraEntry?.camera_type === 'EX' && cameraEntry?.camera_name
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </td>
                                                        <td>{item?.college?.name}</td>
                                                        <td>
                                                            {item?.rooms_schedulers?.from_date} to {item?.rooms_schedulers?.to_date}
                                                        </td>
                                                        <td><div className="d-flex">
                                                            {
                                                                item?.rooms_schedulers?.id ? <i className="fa fa-calendar cursor-pointer"
                                                                    style={{ 'color': '#000' }}
                                                                    aria-hidden="true" onClick={() => viewScheduler(item)}></i>
                                                                    : <p style={{ 'color': 'red' }}>Not Scheduled</p>}&nbsp;&nbsp;
                                                            <i className="fas fa-edit cursor-pointer" style={{ 'color': '#000' }} onClick={() => EditList(item)}></i>&nbsp;&nbsp;
                                                            <i className="fa fa-trash fa-trash-room cursor-pointer" style={{ 'color': '#000' }} onClick={() => handleClick(item)}></i>
                                                        </div>
                                                        </td>

                                                    </tr>
                                                )
                                            }
                                            <tr>
                                                <td colspan="9">
                                                    <PaginationComponent onChangeHandle={onChangeHandle} pageCount={count} pageNumber={pageNumber} setpage={setPageNumber} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="d-flex justify-content-center mt-5">
                                    <div className="NodataFound">
                                        <h3>No Data Found</h3>
                                    </div>
                                    <div className="NodataImage">
                                        <img src={nodatafound} alt="No Data Found" className="NoData" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {
                popup && <EditPopup close={() => {
                    setPopup(false)
                    getroomlist()
                }} data={popupData} roomId={roomId} />

            }
            {
                createPopup && <Createroom close={() => {
                    setCreatePopup(false)
                }} data={popupData} roomId={roomId} />

            }
            {isOpenDeclineModal && <DeclinePopup
                title={`Are you sure you want to delete ${itemObj?.name}?`}
                onClose={() => onCloseModel()}
                onSuccess={() => DeleteRoomList()}
            />}
            {
                OpenViewUserModal && <ViewUserModal onClose={() => setOpenViewModal(false)} userData={userData} />
            }
        </>
    )
}
import Actions from "../action";

const initialState = {
    isLoading: false,
    scheduleLoader:false,
    createschedule:[],
    schedulerList: [],
    collegeList:[],
    departmentList:[],
    departmentSearch:[],
    selectedData:{},
    error: false,
    slotList:[]
}
const SchedulerReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.SCHEDULER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SCHEDULER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                schedulerList: action.data?.response,
                error: false
            };
        }
        case Actions.SCHEDULER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                schedulerList: [],
                error: true,
            };
        }
        case Actions.CREATE_SCHEDULE: {
            return {
                ...state,
                isLoading: false,
                createschedule: [...state?.createschedule, action.params],
            };
        }
        case Actions.CREATE_SCHEDULE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                schedulerList: action.data,
                error: false
            };
        }
        case Actions.CREATE_SCHEDULE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                schedulerList: {},
                error: true
            };
        }
        case Actions.COLLEGE_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.COLLEGE_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                collegeList: action.data.response,
                error: false
            };
        }
        case Actions.COLLEGE_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                collegeList: [],
                error: true,
            };
        }

        case Actions.COURSE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.COURSE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                Course: action.data?.response,
                error: false
            };
        }
        case Actions.COURSE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                Course: [],
                error: true,
            };
        }
        case Actions.DEPARTMENT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DEPARTMENT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                departmentList: action.data?.response,
                error: false
            };
        }
        case Actions.DEPARTMENT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                departmentList: [],
                error: true,
            };
        }
        case Actions.SECTION_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SECTION_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                getSectionList: action.data?.response,
                error: false
            };
        }
        case Actions.SECTION_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                getSectionList: [],
                error: true,
            };
        }
        case Actions.YEAR_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.YEAR_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                yearList: action.data?.response,
                error: false
            };
        }
        case Actions.YEAR_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                yearList: [],
                error: true,
            };
        }
        case Actions.UPDATE_SCHEDULER: {
         
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_SCHEDULER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
               
                error: false
            };
        }
        case Actions.UPDATE_ROOM_FAILURE: {
            return {
                ...state,
                isLoading: false,
               
                error: true,
            };
        }

        case Actions.DEPERTMENT_SECTION_SEARCH: {

            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DEPERTMENT_SECTION_SEARCH_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                departmentSearch: action?.data?.list,
                error: false
            };
        }
        case Actions.DEPERTMENT_SECTION_SEARCH_FAILURE: {
            return {
                ...state,
                isLoading: false,
                departmentSearch:[],
                error: true,
            };
        }
        case Actions.SAVE_COMBRIFACE: {

            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SAVE_COMBRIFACE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.SAVE_COMBRIFACE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: true,
            };
        }
        case Actions.SLOT_LIST: {

            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SLOT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                slotList:action.data?.response,
                error: false
            };
        }
        case Actions.SLOT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: true,
            };
        }
        case Actions.SELECTED_DATA: {
            return {
                ...state,
                isLoading: false,
                selectedData: action.data,
                error: false
            };
        }
        
        default:
            return state;
    }
};
export const getSchedulerList = (state) => state?.SCHEDULER?.schedulerList
export const getCollegeList = (state) => state?.SCHEDULER?.collegeList
export const getCourseList = (state) => state?.SCHEDULER?.Course
export const getSectionList = (state) => state?.SCHEDULER?.getSectionList
export const getYearList = (state) => state?.SCHEDULER?.yearList
export const getdepartmentList = (state) => state?.SCHEDULER?.departmentList
export const getdepartmentSearch = (state) => state?.SCHEDULER?.departmentSearch
export const getSelectedData = (state) => state?.SCHEDULER?.selectedData
export const getSlotList = (state) => state?.SCHEDULER?.slotList

export const getLoading = (state) => state?.SCHEDULER?.isLoading

export default SchedulerReducer;
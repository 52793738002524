import Actions from "../action";

const initialState = {
    isLoading: false,
    hostelDashboard: undefined,
    error: false
}
const HostelDashboardReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.HOSTEL_DASHBOARD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HOSTEL_DASHBOARD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                hostelDashboard: action.data,
                error: false
            };
        }
        case Actions.HOSTEL_DASHBOARD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                hostelDashboard: undefined,
                error: true,
            };
        }
        default:
            return state;
    }
};
export const hostelDashboardData = (state) => state?.HOSTEL_DASHBOARD?.hostelDashboard?.response
export default HostelDashboardReducer;
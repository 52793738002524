import { doAction, doResponseAction } from ".";

const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';

const LOGOUT = 'LOGOUT';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export {
    LOGIN,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    LOGOUT_FAILURE,
    LOGOUT_SUCCESS,

}

// LOGIN
export const loginAction = (params, callback) => doAction(LOGIN, params, callback);
export const loginSuccessAction = data => doResponseAction(LOGIN_SUCCESS, data);
export const loginFailureAction = data => doResponseAction(LOGIN_FAILURE, data);

// LOGOUT
export const logoutAction = (params, callback) => doAction(LOGOUT, params, callback);
export const logoutSuccessAction = (data) => doResponseAction(LOGOUT_SUCCESS, data);
export const logoutFailureAction = (data) => doResponseAction(LOGOUT_FAILURE, data);


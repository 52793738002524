import { doAction, doResponseAction } from ".";

const UPDATE_ROOM = 'UPDATE_ROOM';
const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS';
const UPDATE_ROOM_FAILURE = 'UPDATE_ROOM_FAILURE';
export {
    UPDATE_ROOM,
    UPDATE_ROOM_FAILURE,
    UPDATE_ROOM_SUCCESS,
}
// UPDATE_ROOM
export const updateRoomAction = (params, callback) => doAction(UPDATE_ROOM, params, callback);
export const updateRoomSuccessAction = data => doResponseAction(UPDATE_ROOM_SUCCESS, data);
export const updateRoomFailureAction = data => doResponseAction(UPDATE_ROOM_FAILURE, data);
import { doAction, doResponseAction } from ".";

const SEARCH = 'SEARCH';
const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
const SEARCH_FAILURE = 'SEARCH_FAILURE';

const SEARCH_LIST = 'SEARCH_LIST';
const SEARCH_LIST_SUCCESS = 'SEARCH_LIST_SUCCESS';
const SEARCH_LIST_FAILURE = 'SEARCH_LIST_FAILURE';
export {
    SEARCH,
    SEARCH_FAILURE,
    SEARCH_SUCCESS,

    SEARCH_LIST,
    SEARCH_LIST_SUCCESS,
    SEARCH_LIST_FAILURE,
}
// search
export const searchAction = (params, callback) => doAction(SEARCH, params, callback);
export const searchSuccessAction = data => doResponseAction(SEARCH_SUCCESS, data);
export const searchFailureAction = data => doResponseAction(SEARCH_FAILURE, data);

//SEARCH_LIST
export const searchListAction = (params, callback) => doAction(SEARCH_LIST, params, callback);
export const searchListSuccessAction = data => doResponseAction(SEARCH_LIST_SUCCESS, data);
export const searchListFailureAction = data => doResponseAction(SEARCH_LIST_FAILURE, data);
import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';
import { HOSTEL_REPORT } from '../action/HostelReportAction';



export function* hostelReportSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.HOSTEL_REPORT_API, params)
        yield put(Actions.hostelReportSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.hostelReportFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}


export default function* hostelReportWatcher() {
    yield all([

        takeLatest(HOSTEL_REPORT, hostelReportSaga),


    ]);
}
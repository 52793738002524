import { doAction, doResponseAction } from ".";

const MASTER_SLOT_UPDATE = 'MASTER_SLOT_UPDATE';
const MASTER_SLOT_UPDATE_SUCCESS = 'MASTER_SLOT_UPDATE_SUCCESS';
const MASTER_SLOT_UPDATE_FAILURE = 'MASTER_SLOT_UPDATE_FAILURE';

const MASTER_SLOT_SAVE = "MASTER_SLOT_SAVE"
const MASTER_SLOT_SAVE_SUCCESS = "MASTER_SLOT_SAVE_SUCCESS"
const MASTER_SLOT_SAVE_FAILURE = "MASTER_SLOT_SAVE_FAILURE"

const DELETE_SLOT = "DELETE_SLOT"
const DELETE_SLOT_SUCCESS = "DELETE_SLOT_SUCCESS"
const DELETE_SLOT_FAILURE = "DELETE_SLOT_FAILURE"

export {
    MASTER_SLOT_UPDATE,
    MASTER_SLOT_UPDATE_FAILURE,
    MASTER_SLOT_UPDATE_SUCCESS,

    MASTER_SLOT_SAVE,
    MASTER_SLOT_SAVE_SUCCESS,
    MASTER_SLOT_SAVE_FAILURE,

    DELETE_SLOT,
    DELETE_SLOT_SUCCESS,
    DELETE_SLOT_FAILURE
}
// ATTENDANCE
export const MasterSlotUpdateAction = (params, callback) => doAction(MASTER_SLOT_UPDATE, params, callback);
export const MasterSlotUpdateSuccessAction = data => doResponseAction(MASTER_SLOT_UPDATE_SUCCESS, data);
export const MasterSlotUpdateFailureAction = data => doResponseAction(MASTER_SLOT_UPDATE_FAILURE, data);

export const masterSlotSave = (params, callback) => doAction(MASTER_SLOT_SAVE, params, callback);
export const masterSlotSaveSuccess = (params, callback) => doAction(MASTER_SLOT_SAVE_SUCCESS, params, callback);
export const masterSlotSaveFailure = (params, callback) => doAction(MASTER_SLOT_SAVE_FAILURE, params, callback);

export const DeleteSlot = (params, callback) => doAction(DELETE_SLOT, params, callback);
export const DeleteSlotSuccess = (params, callback) => doAction(DELETE_SLOT_SUCCESS, params, callback);
export const DeleteSlotFailure = (params, callback) => doAction(DELETE_SLOT_FAILURE, params, callback);


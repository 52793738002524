import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../Redux/action";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { toast } from "react-toastify";
import { roomData } from "../Redux/reducer/GetRoomDataReducer";
import moment from "moment";
import nodatafound from '../asset/img/datanotfound_black.png';
import { getHostelReports } from "../Redux/reducer/AttendanceReducer";
import AppDatePicker from "../component/AppDatePicker";
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import AppLoader from "../component/AppLoader";
import { getCollegeList, getCourseList, getSchedulerList, getSectionList, getSlotList, getYearList, getdepartmentList, getdepartmentSearch } from "../Redux/reducer/SchedulerReducer";
import { getHostelListSelectSelector, getHostelListSelector } from "../Redux/reducer/HostelReducer";
import { IMAGE_URL } from "../Redux/saga/EndPoints";
import { onboardingData } from "../Redux/reducer/OnboardingList";
export const HostelAttendance = () => {
    const EXCEL_EXTENSION = '.xlsx'
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const dispatch = useDispatch();
    const courseList = useSelector(getCourseList)
    const hostelList = useSelector(getHostelListSelectSelector)
    const collegeList = useSelector(getCollegeList)
    const slotList = useSelector(getSlotList)
    const yearList = useSelector(getYearList)
    const departmentList = useSelector(getdepartmentList)
    const [fromDate, setFromDate] = useState('')
    const [formDetails, setFormDetails] = useState({})
    const sectionList = useSelector(getdepartmentSearch);
    const [toDate, setToDate] = useState('')
    const [room, setRoom] = useState('')
    const [status, setStatus] = useState('')
    const [err, setErr] = useState({})
    const [viewReports, setViewReport] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const roomList = useSelector(roomData)
    const reportList = useSelector(getHostelReports)
    const [fromTimeValue, onChangeFromTimeValue] = useState('');
    const [toTimeValue, onChangeToTimeValue] = useState('');
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const onboardedList = useSelector(onboardingData);

    useEffect(() => {
        dispatch(Actions.clearReports())
        // dispatch(Actions.clearRooms())

        const callback = () => {
            setLoading(false)
        }
        setLoading(true)
        dispatch(Actions.roomAction("", callback))
        dispatch(Actions.CollegeListAction())
    }, [dispatch])

    const getHostelList = useCallback(() => {
        // let params = {
        //     page: pageNumber
        // }
        dispatch(Actions.hostelListSelect(""))
    }, [dispatch])

    useEffect(() => {
        getHostelList()
    }, [])

    const filterMembers = (input) => {
        if (input) {
            const filtered = onboardedList?.results?.filter(member =>
                member.member_id.toString().toLowerCase().includes(input.toLowerCase()) ||
                member.name.toLowerCase().includes(input.toLowerCase())
            );
            setFilteredMembers(filtered);
        } else {
            setFilteredMembers([]);
        }
    };

    const handleSuggestionClick = (id) => {
        setFormDetails({ ...formDetails, member_id: id });
        setShowSuggestions(false);
    };


    const memberFilterList = (hostel_id) => {
        const callback = (response) => {
            dispatch(Actions.onboardingSearchAction(response));
        };
        let params = {
            hostel_id: hostel_id
        };
        dispatch(Actions.hostellerList(params, callback));
    };

    useEffect(() => {
        if (formDetails?.hostel_id) {
            memberFilterList(formDetails?.hostel_id);
        }
    }, [formDetails?.hostel_id]);

    // useEffect(() => {
    //     const params = {
    //         id: formDetails?.college_id
    //     }
    //     dispatch(Actions.CoursetAction(params))
    // }, [dispatch, formDetails?.college_id])

    // useEffect(() => {
    //     const params = {
    //         id: formDetails?.course_id
    //     }
    //     dispatch(Actions.YearAction(params))
    // }, [dispatch, formDetails?.course_id])

    // useEffect(() => {
    //     const params = {
    //         id: formDetails?.course_id
    //     }
    //     dispatch(Actions.DepartmentAction(params))
    // }, [dispatch, formDetails?.course_id])

    // const getHostelList = useCallback(() => {
    //     setLoading(true)
    //     const callback = (res) => {
    //         setLoading(false)
    //     }
    //     dispatch(Actions.hostelListSelect("", callback))
    // }, [])

    // useEffect(() => {
    //     getHostelList()
    // }, [])

    const clearReport = () => {
        setFromDate("")
        setRoom("")
        setErr("")
        setViewReport("")
        dispatch(Actions.clearReports())
        setFormDetails({ hostel_id: "", member_id: "" })
        // dispatch(Actions.clearRooms())

    }
    const viewReport = () => {
        if (!formDetails?.hostel_id) {
            setErr({ "hostel_id": "Please select the hostel" })
        } else if (!fromDate) {
            setErr({ "fromDate": "Please select the Date" })
        }
        // else if (!formDetails?.start_time) {
        //     setErr({ "hostel_id": "Please select the start_time" })
        // } else if (!formDetails?.end_time) {
        //     setErr({ "hostel_id": "Please select the end_time" })
        // } 
        else {
            const callback = (response) => {
                if (response?.status_code === 0) {
                    if (response?.response?.length > 0) {
                        setViewReport(true)
                    } else {
                        toast.error("No report found")
                    }
                    setLoading(false)
                    setErr("")
                }
                else {
                    toast.error(response?.message)
                    setLoading(false)
                }
            }
            let st_time = moment(fromDate).format('YYYY-MM-DD')
            const params = {
                date: st_time,
                hostel_id: formDetails?.hostel_id,
                start_time: moment(formDetails?.start_time).format("HH:mm:ss"),
                end_time: moment(formDetails?.end_time).format("HH:mm:ss"),
            }
            if (formDetails?.member_id) {
                params.member_id = formDetails?.member_id
            }
            setLoading(true)
            dispatch(Actions.hostelAttendanceAction(params, callback))
        }
    }

    const handleDownload = () => {
        const saveAsExcelFile = (buffer, fileName) => {
            const data = new Blob([buffer], { type: EXCEL_TYPE }) || [];
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
        }
        const callback = (response) => {
            if (response?.status_code === 0) {
                const { Absentees, Presentees } = response?.response || {}
                // let modifiedReportJSON = reportList?.map((obj) => ({ ...obj}))
                const worksheet = XLSX.utils.json_to_sheet(reportList);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'Report')
                toast.success("Downloaded Successfully")
                setLoading(false)

            }
            else {
                toast.error("Something went worng")
                setLoading(false)
            }
        }
        let st_time = moment(fromDate).format('YYYY-MM-DD')
        const params = {
            date: st_time,
            hostel_id: formDetails?.hostel_id,
            start_time: moment(formDetails?.start_time).format("HH:mm:ss"),
            end_time: moment(formDetails?.end_time).format("HH:mm:ss"),
        }
        if (formDetails?.member_id) {
            params.member_id = formDetails?.member_id
        }
        if (fromDate !== '') {
            setLoading(true)
            dispatch(Actions.hostelAttendanceAction(params, callback))
        }
    }
    const handleDateSelect = (selected) => {
        setFromDate(selected)
        setViewReport(false)
        setErr("")
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormDetails((prevState) => ({
            ...prevState,
            [name]: value
        }));
        if (name === 'member_id') {
            filterMembers(value);
            setShowSuggestions(true);
        }
    }
    const handleHostelSelect = (selected) => {
        setViewReport(false)
        setFormDetails({ ...formDetails, 'hostel_id': parseInt(selected) })
        setErr({ ...err, "hostel_id": "" })
        memberFilterList(parseInt(selected));
    };

    const handleTimeChange = (value, time) => {
        if (value) {
            if (time === "start_time") {
                setFormDetails({ ...formDetails, [time]: value })
            } else {
                setFormDetails({ ...formDetails, [time]: value })
            }
        }
    }

    const disabledEndMinutes = (selectedHour) => {
        const startMinute = formDetails?.start_time ? moment(formDetails.start_time).minute() : 0;
        const startHour = formDetails?.start_time ? moment(formDetails.start_time).hour() : 0;
        if (selectedHour === startHour) {
            let minutes = [];
            for (let i = 0; i < startMinute; i++) {
                minutes.push(i);
            }
            return minutes;
        }
        return [];
    }

    const disabledEndHours = () => {
        const startHour = formDetails?.start_time ? moment(formDetails.start_time).hour() : 0;
        let hours = [];
        for (let i = 0; i < startHour; i++) {
            hours.push(i);
        }
        return hours;
    }

    return (
        <>
            {
                isLoading && <AppLoader />
            }
            <div className="ecommerce-widget">
                <h5 className="card-header">Student In / Out Report</h5>
                <div className="card border-3 border-top border-top-primary">
                    <div className="row m-0">
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label htmlFor="exampleInputEmail1" className="form-label">Hostel</label>
                            <select value={formDetails?.hostel_id} onChange={(e) => handleHostelSelect(e?.target?.value)}
                                className="form-select">
                                <option value='' hidden>Select Hostel</option>
                                {Array.isArray(hostelList) && hostelList?.map((o) => {
                                    return <option value={o?.id}>{o?.name}</option>
                                })
                                }
                            </select>
                            <p style={{ 'color': 'red' }}>{err?.hostel_id}</p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">Date</label>
                            <div className="input-group mb">
                                <AppDatePicker
                                    disabled={!formDetails?.hostel_id}
                                    selected={fromDate}
                                    placeholder="Date"
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                        handleDateSelect(date)
                                    }}
                                    notValidTime={true}
                                    id='FormDatePicker'
                                />
                            </div>
                            <p style={{ 'color': 'red' }}>{err?.fromDate}</p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">Start Time</label>
                            <div className="input-group w-75">
                                <TimePicker showSecond={false} format={'HH:mm'} disabled={!fromDate} onChange={(v) => handleTimeChange(v, "start_time")} value={formDetails?.start_time ?? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })} disableClock={true} className="form-control  cursor-pointer" />
                            </div>
                            {/* <p style={{ 'color': 'red' }}>{err?.hostel}</p> */}
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">End Time</label>
                            <div className="input-group w-75">
                                <TimePicker showSecond={false} format={'HH:mm'} disabled={!formDetails?.start_time} clearIcon={false} onChange={(v) => handleTimeChange(v, "end_time")} value={formDetails?.end_time ?? moment().set({ hour: 23, minute: 59, second: 59, millisecond: 59 })} disableClock={true} className="form-control dateInput cursor-pointer" disabledHours={disabledEndHours}
                                    disabledMinutes={disabledEndMinutes} />
                            </div>
                            {/* <p style={{ 'color': 'red' }}>{err?.end_time}</p> */}
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <div className="mb-3 position-relative">
                                <label htmlFor="exampleInputEmail1" className="form-label">Student Name/Id</label>
                                <input value={formDetails?.member_id} name='member_id' disabled={!formDetails?.hostel_id} onChange={handleChange} type="text" className="form-control" id="exampleInputEmail1"
                                    placeholder='Type here' />

                                {showSuggestions && filteredMembers?.length > 0 && (
                                    <div className="dropdown dropdown-position1">
                                        {filteredMembers?.map((member, index) => (
                                            <div key={index} className="dropdown-item" onClick={() => handleSuggestionClick(member?.member_id)}>
                                                <img src={IMAGE_URL + member.img_url} alt="search" style={{ width: "40px", height: "40px", marginRight: "10px" }} />
                                                {member?.name}, {member?.member_id}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 selectDate1">
                            {!viewReports ? <button type="button" className="btn btn-primary" onClick={() => viewReport()}>View</button>
                                : <button type="button"
                                    className="btn btn-primary" onClick={() => handleDownload()}>Download</button>}
                            &nbsp;
                            {(fromDate || reportList?.length > 0) && <button className="btn btn-primary" onClick={() => clearReport()}>Clear</button>}
                        </div>
                    </div>

                </div>
                {viewReports && <div className="card">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    {/* {
                                        (Array.isArray(reportList)) && <h4 className="mt-2 p-2">Presents-{Presentees?.length}</h4>
                                    } */}
                                    {(Array.isArray(reportList) && reportList?.length > 0) &&
                                        <table className="table onboardTable">
                                            <thead className="bg-light">
                                                <tr className="border-0">
                                                    <th className="border-0">S.No</th>
                                                    <th className="border-0">Name</th>
                                                    <th className="border-0">Date</th>
                                                    <th className="border-0">Time</th>
                                                    <th className="border-0">Entry/Exit</th>
                                                    <th className="border-0">Room</th>
                                                    <th className="border-0">Access Type</th>

                                                    {/* <th className="border-0">Remarks</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(Array.isArray(reportList) && reportList?.length > 0) && reportList?.map((item, index) => {
                                                    return <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.name} ({item?.member_id})</td>
                                                        <td>{item?.date}</td>
                                                        <td>{item?.time ? item?.time : "Not available"}</td>
                                                        <td>{item?.entry}</td>
                                                        <td>{item?.room}</td>
                                                        <td>{item?.access_type}</td>

                                                    </tr>
                                                })}

                                            </tbody>
                                        </table>}
                                    {reportList?.length === 0 && <div className="d-flex justify-content-center mt-5">
                                        <div className="NodataFound mb-3">
                                            <h3>No Data Found</h3>
                                        </div>
                                        <div className="NodataImage">
                                            <img src={nodatafound} alt="No Data Found" className="NoData" />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
}
import { doAction, doResponseAction } from ".";

const CREATE_ROOM = 'CREATE_ROOM';
const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS';
const CREATE_ROOM_FAILURE = 'CREATE_ROOM_FAILURE';
export {
    CREATE_ROOM,
    CREATE_ROOM_FAILURE,
    CREATE_ROOM_SUCCESS,
}
// createRoom
export const createRoomAction = (params, callback) => doAction(CREATE_ROOM, params, callback);
export const createRoomSuccessAction = data => doResponseAction(CREATE_ROOM_SUCCESS, data);
export const createRoomFailureAction = data => doResponseAction(CREATE_ROOM_FAILURE, data);
import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { MEMBER } from '../action/MemeberListAction'
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';
import { DELETE_SLOT, MASTER_SLOT_SAVE, MASTER_SLOT_UPDATE, masterSlotSave } from '../action/MasterSLotUploadAction';

export function* MasterSlotSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.MASTER_SLOT_SAVE, params)
        yield put(Actions.masterSlotSaveSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.masterSlotSaveFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* DeleteMasterSlotSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.MASTER_SLOT_DELETE, params)
        yield put(Actions.masterSlotSaveSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.masterSlotSaveFailure({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* MasterSlotUpdateSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.MASTER_SLOT_UPDATE, params)
        yield put(Actions.MasterSlotUpdateSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.MasterSlotUpdateFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* masterSlotwatcher() {
    yield all([
        takeLatest(MASTER_SLOT_SAVE, MasterSlotSaga),
        takeLatest(MASTER_SLOT_UPDATE, MasterSlotUpdateSaga),
        takeLatest(DELETE_SLOT, DeleteMasterSlotSaga),

    ]);
}
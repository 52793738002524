import Actions from "../action";

const initialState = {
    isLoading: false,
    editMember: undefined,
    error: false
}
const EditMemberListReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.EDIT_MEMBER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.EDIT_MEMBER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                editMember: action.data,
                error: false
            };
        }
        case Actions.EDIT_MEMBER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                editMember: undefined,
                error: true,
            };
        }
        default:
            return state;
    }
};
export const editMemberData = (state) => state?.MEMBER_CRED?.editMember?.response;
export default EditMemberListReducer;
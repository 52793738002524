import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Actions from "../Redux/action";
import { toast } from "react-toastify";
import { roomData } from "../Redux/reducer/GetRoomDataReducer";
import nodatafound from '../asset/img/datanotfound_black.png';
import AppLoader from "../component/AppLoader";
import { getHostelReportList } from "../Redux/reducer/HostelReportReducer";
import { getHostelListSelectSelector } from "../Redux/reducer/HostelReducer";
import moment from "moment";

export const HostelReport = () => {
    const dispatch = useDispatch();
    const hostelList = useSelector(getHostelListSelectSelector);
    const roomList = useSelector(roomData);
    const reportList = useSelector(getHostelReportList);
    const currentDate = new Date().toISOString().split('T')[0];
    const navigate = useNavigate();
    const location = useLocation();

    const initialState = location.state || {
        fromDate: '',
        toDate: '',
        formDetails: {},
        viewReports: false,
        isLoading: false
    };

    const [fromDate, setFromDate] = useState(initialState.fromDate);
    const [formDetails, setFormDetails] = useState(initialState.formDetails);
    const [toDate, setToDate] = useState(initialState.toDate);
    const [err, setErr] = useState({});
    const [viewReports, setViewReport] = useState(initialState.viewReports);
    const [isLoading, setLoading] = useState(initialState.isLoading);

    useEffect(() => {
        dispatch(Actions.clearReports());
    }, [dispatch]);

    const getHostelList = useCallback(() => {
        dispatch(Actions.hostelListSelect(""));
    }, [dispatch]);

    useEffect(() => {
        getHostelList();
    }, [getHostelList]);

    useEffect(() => {
        if (location.state && location.state.formDetails.hostel_id) {
            viewReport(location.state.formDetails.hostel_id);
        }
    }, [location.state]);

    const clearReport = () => {
        setFromDate("");
        setToDate("");
        setViewReport("");
        dispatch(Actions.clearReports());
        setFormDetails({ hostel_id: "" });
    };

    const viewReport = (hostel) => {
        const callback = (response) => {
            if (response?.status_code === 0) {
                if (response?.response?.length > 0) {
                    setViewReport(true);
                } else {
                    toast.error("No report found");
                }
                setLoading(false);
                setErr("");
            } else {
                toast.error(response?.message);
                setLoading(false);
            }
        };

        const params = { hostel_id: hostel };
        if (fromDate && toDate) {
            params.fromDate = moment(fromDate).format('YYYY-MM-DD');
            params.toDate = moment(toDate).format('YYYY-MM-DD');
        }

        setLoading(true);
        dispatch(Actions.hostelReportAction(params, callback));
    };

    const handleDateSelect = (selected, name) => {
        if (name === 'fromDate') {
            setFromDate(selected);
        } else {
            setToDate(selected);
        }
        setViewReport(false);
    };

    const handleHostelSelect = (selected) => {
        setViewReport(false);
        setFormDetails({ ...formDetails, 'hostel_id': parseInt(selected) });
        setErr({ ...err, "hostel_id": "" });
        viewReport(parseInt(selected));
    };

    const navigateToDayWiseReport = (hostelId, date) => {
        navigate(`/daywisereport/${hostelId}/${date}`, {
            state: {
                fromDate,
                toDate,
                formDetails,
                viewReports,
                isLoading
            }
        });
    };

    return (
        <>
            {isLoading && <AppLoader />}
            <div className="ecommerce-widget">
                <h5 className="card-header">Hostel Report</h5>
                <div className="card border-3 border-top border-top-primary">
                    <div className="row m-0">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 selectDate">
                            <label htmlFor="exampleInputEmail1" className="form-label">Hostel</label>
                            <select value={formDetails?.hostel_id} onChange={(e) => handleHostelSelect(e?.target?.value)} className="form-select">
                                <option value='' hidden>Select Hostel</option>
                                {Array.isArray(hostelList) && hostelList?.map((o) => {
                                    return <option value={o?.id} key={o?.id}>{o?.name}</option>;
                                })}
                            </select>
                            <p style={{ 'color': 'red' }}>{err?.hostel_id}</p>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">From Date</label>
                            <div className="input-group mb">
                                <input type="date"
                                    name="fromDate"
                                    className="form-control w-75"
                                    value={moment(fromDate).format('YYYY-MM-DD')}
                                    onChange={(e) => handleDateSelect(e.target.value, 'fromDate')}
                                    placeholder="Date"
                                    disabled={!formDetails?.hostel_id}
                                    max={moment().format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 selectDate">
                            <label className="dateLabel">To Date</label>
                            <div className="input-group mb">
                                <input type="date"
                                    name="toDate"
                                    className="form-control w-75"
                                    value={moment(toDate).format('YYYY-MM-DD')}
                                    onChange={(e) => handleDateSelect(e.target.value, 'toDate')}
                                    placeholder="Date"
                                    disabled={!formDetails?.hostel_id}
                                    min={moment(fromDate).format('YYYY-MM-DD')}
                                    max={currentDate}
                                />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 selectDate1">
                            {toDate && <button type="button" className="btn btn-primary" onClick={() => viewReport(formDetails?.hostel_id)}>View</button>}
                            &nbsp;
                            {(fromDate) && <button className="btn btn-primary" onClick={clearReport}>Clear</button>}
                        </div>
                    </div>
                </div>
                {viewReports && formDetails?.hostel_id && !fromDate && !toDate && <h5 className="card-day-wise-heading">
                   Report for {reportList[0]?.hostel} from {moment(reportList[0]?.date).format('YYYY-MM-DD')} to {moment(reportList[reportList?.length - 1]?.date).format('YYYY-MM-DD')}
                </h5>}
                {viewReports && formDetails?.hostel_id && fromDate && toDate && <h5 className="card-day-wise-heading">
                   Report for {reportList[0]?.hostel} from {moment(fromDate).format('YYYY-MM-DD')} to {moment(toDate).format('YYYY-MM-DD')}
                </h5>}
                {viewReports && <div className="card">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    {(Array.isArray(reportList) && reportList?.length > 0) && (
                                        <table className="table onboardTable">
                                            <thead className="bg-light">
                                                <tr className="border-0">
                                                    <th className="border-0 text-center">S.No</th>
                                                    <th className="border-0 text-center">Date</th>
                                                    <th className="border-0 text-center">Total Students</th>
                                                    <th className="border-0 text-center">Present Students</th>
                                                    <th className="border-0 text-center">Absent Students</th>
                                                    <th className="border-0 text-center">Not Appeared but present</th>
                                                    <th className="border-0 text-center">Special Access Entry/Exit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportList.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>
                                                            <button className="btn hover-date btn-link p-0" onClick={() => navigateToDayWiseReport(formDetails.hostel_id, item.date)}>
                                                                {item.date}
                                                            </button>
                                                        </td>
                                                        <td className="text-center">{item?.data?.total_students ? item?.data?.total_students : 0}</td>
                                                        <td className="text-center">{item?.data?.total_presentees ? item?.data?.total_presentees : 0}</td>
                                                        <td className="text-center">{item?.data?.total_absentees ? item?.data?.total_absentees : 0}</td>
                                                        <td className="text-center">{item?.data?.not_appeared_present ? item?.data?.not_appeared_present : 0}</td>
                                                        <td className="text-center">{item?.data?.special_access_entries ? item?.data?.special_access_entries : '-'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                    {reportList?.length === 0 && (
                                        <div className="d-flex justify-content-center mt-5">
                                            <div className="NodataFound mb-3">
                                                <h3>No Data Found</h3>
                                            </div>
                                            <div className="NodataImage">
                                                <img src={nodatafound} alt="No Data Found" className="NoData" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    );
};


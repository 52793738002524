import React, { useEffect, useState } from 'react';
import Close from '../asset/img/modal-close.svg'
import { days } from '../Utils/Constant';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../Redux/action';
import moment from 'moment';
import { toast } from 'react-toastify';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { getCollegeList, getCourseList, getSelectedData, getdepartmentList } from '../Redux/reducer/SchedulerReducer';
import AppLoader from './AppLoader';
const AddMasterSlot = ({ onClose, updateData, college_id, course_id, department_id }) => {
    const [masterSlots, setMasterSLots] = useState({})
    const [err, setErr] = useState({})
    const dispatch = useDispatch()
    const collegeList = useSelector(getCollegeList)
    const courseList = useSelector(getCourseList)
    const departmentList = useSelector(getdepartmentList)
    const selctedFormData = useSelector(getSelectedData);
    const [formDetails, setFormDetails] = useState({});
    const [isloading, setLoading] = useState(false)
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        if (updateData) {
            setMasterSLots(updateData)
            setEdit(true)
            setFormDetails({ ...formDetails, 'department_id': updateData?.department })
        } else {
            setFormDetails({ ...formDetails, 'college_id': college_id, 'course_id': course_id, 'department_id': department_id })
            setMasterSLots({ ...masterSlots, 'department': department_id })
        }
    }, [])
    useEffect(() => {
        dispatch(Actions.CollegeListAction())
    }, [dispatch])

    useEffect(() => {
        const params = {
            id: formDetails?.college_id
        }
        dispatch(Actions.CoursetAction(params))
    }, [dispatch, formDetails?.college_id])

    useEffect(() => {
        const params = {
            id: formDetails?.course_id
        }
        dispatch(Actions.DepartmentAction(params))
    }, [dispatch, formDetails?.course_id])

    const submit = () => {
        if (!edit && !formDetails?.college_id) {
            setErr({ college: "Please select college" })
        } else if (!edit && !formDetails?.course_id) {
            setErr({ course: "Please select course" })
        } else if (!formDetails?.department_id) {
            setErr({ department: "Please select department" })
        } else if (!masterSlots?.day) {
            setErr({ day: "Please select day" })
        } else if (!masterSlots?.start_time) {
            setErr({ start_time: "Please select start time" })
        } else if (!masterSlots?.end_time) {
            setErr({ end_time: "Please select end time" })
        } else if (moment(moment(new Date()).format("YYYY-MM-DD") + ' ' + masterSlots?.start_time).isAfter(moment(moment(new Date()).format("YYYY-MM-DD") + ' ' + masterSlots?.end_time))) {
            setErr({ end_time: "Start time not before end time" })
        } else {
            masterSlots.start_time = moment(new Date()).format("YYYY-MM-DD") + ' ' + masterSlots?.start_time
            masterSlots.end_time = moment(new Date()).format("YYYY-MM-DD") + ' ' + masterSlots?.end_time
            var start_time = moment.utc(masterSlots?.start_time).utcOffset(+`-${moment().utcOffset()}`).format('HH:mm:ss');
            var end_time = moment.utc(masterSlots?.end_time).utcOffset(+`-${moment().utcOffset()}`).format('HH:mm:ss')


            const params = {
                day: masterSlots?.day,
                start_time: start_time,
                end_time: end_time,
                department_id: formDetails?.department_id
            }
            const updateparams = {
                id: updateData?.id,
                day: masterSlots?.day,
                start_time: start_time,
                end_time: end_time,
                department_id: formDetails?.department_id
            }
            const updatedCallback = (res) => {
                setLoading(false)
                if (res?.status_code === 0) {
                    toast.success('Master Slot updated successfully')
                    onClose()
                } else {
                    toast.error(res?.message)
                }
            }

            const callback = (res) => {
                setLoading(false)
                if (res?.status_code === 0) {
                    toast.success('Master Slot created successfully')
                    onClose()
                } else {
                    toast.error(res?.message)
                }
            }

            setLoading(true)
            if (updateData) {
                dispatch(Actions.MasterSlotUpdateAction(updateparams, updatedCallback))
            } else {
                dispatch(Actions.masterSlotSave(params, callback))
            }
        }
    }
    const handleCollegeSelect = (selected) => {
        setFormDetails({ ...formDetails, 'course_id': '', 'year_id': '', 'department_id': '', 'college_id': selected })
    };
    const handleDepartmentSelect = (selected) => {
        setFormDetails({ ...formDetails, 'year_id': '', 'department_id': selected })
    };
    const handleCourseSelect = (selected) => {
        setFormDetails({ ...formDetails, 'department_id': '', 'course_id': selected })
    };
    return <>
        {
            isloading && <AppLoader />
        }
        <div className="modal d-block"
            id="view-multi-modal"
            tabIndex="-1"
            aria-labelledby="view-multi-modal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered  planogram-div">
                <div className="modal-content rounded-0 p-2">
                    <img id="close"
                        src={Close}
                        alt="X"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="modal-close"
                        onClick={() => onClose()}
                    />
                    <div className="modal-header rounded-0 align-items-center py-4 px-4">
                        <div className="col">
                            <h4 className="modal-title">{updateData?.id ? "Edit Time Slot" : "Add Time Slot"} </h4>
                        </div>
                    </div>
                    <div className="modal-body font14 p-40">
                        <div className="container">
                            <div className="row">
                                {!edit && <>
                                    <span className="form-label">College</span>
                                    <select value={formDetails?.college_id} onChange={(e) => handleCollegeSelect(e?.target?.value)}
                                        className="form-select">
                                        <option value='' hidden>Select College </option>
                                        {Array.isArray(collegeList) && collegeList?.map((o) => {
                                            return <option value={o?.id}>{o?.name}</option>

                                        })
                                        }
                                    </select>
                                    {err?.college && <p style={{ color: 'red' }}>{err?.college}</p>}
                                    <span className="form-label" style={{ marginTop: '10px' }} >Course</span>
                                    <select value={formDetails?.course_id} disabled={formDetails?.college_id ? false : true} onChange={(e) => handleCourseSelect(e?.target?.value)}
                                        className="form-select">
                                        <option value='' hidden>Select Course</option>
                                        {Array.isArray(courseList) && courseList?.map((o) => {
                                            return <option value={o?.id}>{o?.name}</option>

                                        })
                                        }
                                    </select>
                                    {err?.course && <p style={{ color: 'red' }}>{err?.course}</p>}
                                </>}
                                <span className="form-label" style={{ marginTop: '10px' }} >Department</span>
                                <select disabled={formDetails?.course_id ? false : true} value={masterSlots?.department} onChange={(e) => handleDepartmentSelect(e?.target?.value)}
                                    className="form-select">
                                    <option value='' hidden>Select Department</option>
                                    {Array.isArray(departmentList) && departmentList?.map((o) => {
                                        return <option value={o?.id}>{o?.name}</option>
                                    })
                                    }
                                </select>
                                {err?.department && <p style={{ color: 'red' }}>{err?.department}</p>}
                                <span className="form-label" style={{ marginTop: '10px' }} >Day</span>
                                <select className='form-control' value={masterSlots?.day} onChange={(e) => setMasterSLots({ ...masterSlots, day: e?.target?.value })}>
                                    <option value='' hidden>Select Day</option>
                                    {days?.map((d) => {
                                        return <option value={d?.type}>{d?.day}</option>
                                    })}
                                </select>
                                {err?.day && <p style={{ color: 'red' }}>{err?.day}</p>}
                                <span className="form-label" style={{ marginTop: '10px' }} >Start time</span>
                                <input className='form-control' type='time' value={masterSlots?.start_time} onChange={(e) => setMasterSLots({ ...masterSlots, start_time: e?.target?.value })}></input>
                                {err?.start_time && <p style={{ color: 'red' }}>{err?.start_time}</p>}
                                <span className="form-label" style={{ marginTop: '10px' }} >End time</span>
                                <input className='form-control' type='time' value={masterSlots?.end_time} onChange={(e) => setMasterSLots({ ...masterSlots, end_time: e?.target?.value })}></input>
                                {err?.end_time && <p style={{ color: 'red' }}>{err?.end_time}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <button
                            type="button"
                            className="btn"
                            style={{ backgroundColor: 'lightgray' }}
                            onClick={() => onClose()}
                        >Cancel
                        </button>

                        <button className="btn btn-primary submitRoom"
                            style={{ marginLeft: '20px' }}
                            onClick={() => submit()}>Submit
                        </button>
                    </div>
                </div>

            </div>

        </div>
        <div className="modal-backdrop show"></div>
    </>
}
export default AddMasterSlot;
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import Close from '../asset/img/modal-close.svg'
import Actions from '../Redux/action';
import { HostelValidation, WorkerValidation, validateIPV4Regex, validateMacRegex, validateName } from '../Utils/Validations';
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import WorkerRoomTypeAhead from '../component/WorkerRoomTypeAhead'
import moment from 'moment';

export const AddHostelPopup = ({ onClose, data, pageNumber, collegeList }) => {
    const dispatch = useDispatch();
    const [formDetails, setFormDetails] = useState({ ...data });
    const [errors, setErrors] = useState();
    const optionsRoomData = data?.id ? data?.rooms?.map(d => ({ label: d?.room_name, value: d?.id })) : null

    const handleWorkerRoomSelect = (selected) => {
        setFormDetails({ ...formDetails, 'rooms': selected })
    };

    const handleCollegeSelect = (selected) => {
        setFormDetails({ ...formDetails, 'college': parseInt(selected) })
    };

    const handleChange = (e) => {
        if (e.target.name === "name") {
            setFormDetails({ ...formDetails, [e.target.name]: e.target.value.replace(/[0-9]/g, '')})
        } else {
            setFormDetails({ ...formDetails, [e.target.name]: e.target.value })
        }
    }

    useEffect(() => {
        if (Object.keys(formDetails)?.length !== 0) {
            let validatedError = HostelValidation(formDetails, ["name", "hostel_no", "college"])
            if (validatedError) {
                setErrors(validatedError)
            }
        }
    }, [formDetails])

    const handleTimeChange = (value, time) => {
        if (value) {
            if (time === "start_time") {
                setFormDetails({ ...formDetails, [time]: value })
            } else {
                setFormDetails({ ...formDetails, [time]: value })
            }
        }
    }

    const createHostel = () => {
        if (!formDetails?.name) {
            setErrors({ name: "Please enter the valid hostel name" })
        }
        // else if (!validateName(formDetails?.name)) {
        //     setErrors({ ...errors, name: "Please enter the valid hostel name" })
        // } 
        else if (!formDetails?.hostel_no) {
            setErrors({ hostel_no: "Please enter the valid hostel_no" })
        } else if (!formDetails?.college) {
            setErrors({ college: "Please select the college" })
        } else {
            setErrors({})
            if (formDetails?.id) {
                let convertArray = formDetails?.rooms?.map(d => ({ id: d?.value ?? d?.id }))
                const updateCallBack = (response) => {
                    if (response?.status_code === 0) {
                        toast.success("Hostel successfully updated")
                        onClose()
                        let params = {
                            page: pageNumber
                        }
                        dispatch(Actions.hostelList(params))
                    } else {
                        toast.error(response?.message)
                    }
                }
                let params = {
                    id: formDetails?.id,
                    hostel_name: formDetails?.name,
                    hostel_no: formDetails?.hostel_no,
                    college_id: formDetails?.college,
                    compre_face: formDetails?.compre_face,
                    rooms: convertArray,
                    start_time: formDetails?.start_time ? moment(formDetails?.start_time, 'HH:mm:ss').format("HH:mm:ss") : null,
                    end_time: formDetails?.end_time ? moment(formDetails?.end_time, 'HH:mm:ss').format("HH:mm:ss") : null,
                }
                dispatch(Actions.hostelUpdate(params, updateCallBack))
            } else {
                let convertArray = formDetails?.rooms?.map(d => ({ id: d?.value ?? d?.id }))
                const createCallBack = (response) => {
                    if (response?.status_code === 0) {
                        toast.success("Hostel successfully created")
                        onClose()
                        let params = {
                            page: pageNumber
                        }
                        dispatch(Actions.hostelList(params))
                    } else {
                        toast.error(response?.message)
                    }
                }
                let params = {
                    hostel_name: formDetails?.name,
                    hostel_no: formDetails?.hostel_no,
                    college_id: formDetails?.college,
                    compre_face: formDetails?.compre_face,
                    rooms: convertArray,
                    start_time: formDetails?.start_time ? moment(formDetails?.start_time, 'HH:mm:ss').format("HH:mm:ss") : "00:00:00",
                    end_time: formDetails?.end_time ? moment(formDetails?.end_time, 'HH:mm:ss').format("HH:mm:ss") : "23:00:00",
                }
                dispatch(Actions.createHostel(params, createCallBack))
            }
        }
    }

    return (
        <>
            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg planogram-div">
                    <div className="modal-content rounded-0">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() => onClose()} />
                        <div className="modal-header rounded-0 align-items-center py-4 px-4">
                            <div className="col">
                                <h4 className="modal-title">{data?.id ? "Update Hostel" : "Add Hostel"}</h4>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Hostel Name</label>
                                            <input placeholder='Type here' value={formDetails?.name} name='name' onChange={handleChange} type="text" className="form-control w-75" id="exampleInputEmail1" />
                                            {errors?.name && <p className='errorMessage'>{errors?.name}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Hostel No</label>
                                            <input placeholder='Type here' value={formDetails?.hostel_no} name='hostel_no' onChange={handleChange} type="number" className="form-control w-75" id="exampleInputEmail1" />
                                            {errors?.hostel_no && <p className='errorMessage'>{errors?.hostel_no}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Hostel Rooms</label>
                                            <WorkerRoomTypeAhead
                                                id="WorkerTypeAhead"
                                                style={{ margin: '10px' }}
                                                placeholder="Select Room"
                                                isMulti={true}
                                                isHostel={true}
                                                defaultValue={optionsRoomData}
                                                onChange={handleWorkerRoomSelect}
                                                disabled
                                            />
                                            {errors?.rooms && <p className='errorMessage'>{errors?.rooms}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">College</label>
                                            <select value={formDetails?.college}
                                                onChange={(e) => handleCollegeSelect(e?.target?.value)}
                                                className="form-select w-75">
                                                <option value='' hidden>Select College</option>
                                                {Array.isArray(collegeList) && collegeList?.map((o) => {
                                                    return <option value={o?.id}>{o?.name}</option>

                                                })
                                                }
                                            </select>
                                            {errors?.college && <p className='errorMessage'>{errors?.college}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Compreface Id</label>
                                            <input placeholder='Type here' value={formDetails?.compre_face} name='compre_face' onChange={handleChange} type="text" className="form-control w-75" id="exampleInputEmail1" />
                                            {errors?.compre_face && <p className='errorMessage'>{errors?.compre_face}</p>}
                                        </div>
                                    </div>
                                    <div className="row col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="col">
                                            <label className="dateLabel">Start Time</label>
                                            <div className="input-group w-75">
                                                <TimePicker showSecond={false} onChange={(v) => handleTimeChange(v, "start_time")} format={'HH:mm'} value={formDetails?.start_time ? moment(formDetails?.start_time, 'HH:mm:ss') : moment().set({ hour: 0, minute: 0 })} disableClock={true} className="form-control  cursor-pointer" />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <label className="dateLabel">End Time</label>
                                            <div className="input-group w-75">
                                                <TimePicker showSecond={false} onChange={(v) => handleTimeChange(v, "end_time")} format={'HH:mm'} value={formDetails?.end_time ? moment(formDetails?.end_time, 'HH:mm:ss') : moment()} disableClock={true} className="form-control dateInput cursor-pointer" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-3">
                                    <button className="btn btn-primary submitRoom" onClick={() => createHostel()}>{formDetails?.id ? "Update" : "Create"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>

        </>
    )
}
import React from "react";
import Close from '../asset/img/modal-close.svg'
import AppDatePicker from "../component/AppDatePicker";

const DownloadSectionReport = ({ onClose, year, setYear, department }) => {
    const submit = () => {

    }

    return <>
        <div className="modal d-block"
            id="view-multi-modal"
            tabIndex="-1"
            aria-labelledby="view-multi-modal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg planogram-div">
                <div className="modal-content rounded-0 p-2">
                    <img id="close"
                        src={Close}
                        alt="X"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="modal-close"
                        onClick={() => onClose()}
                    />
                    <div className="modal-header rounded-0 align-items-center py-4 px-4">
                        <div className="col">
                            <h4 className="modal-title">Download report</h4>
                        </div>
                    </div>
                    <div className="modal-body font14 p-40">
                        <div className="container">
                            <div className="row">
                                <div className="row">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="exampleInputEmail1" className="form-label">From Date</label>
                                            <AppDatePicker
                                                // selected={''}
                                                placeholder="To Date"
                                                maxDate={new Date()}

                                                notValidTime={true}

                                                id='ToDatePicker'
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="exampleInputEmail1" className="form-label">To Date</label>
                                            <AppDatePicker
                                                // selected={''}
                                                placeholder="To Date"
                                                maxDate={new Date()}
                                                notValidTime={true}
                                                id='ToDatePicker'
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Year</label>
                                                <select value={year} disabled={department ? false : true} onChange={(e) => setYear(e?.target?.value)}
                                                    className="form-select">
                                                    <option value='' hidden>Select Year</option>

                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="text-end">
                        <button
                            type="button"
                            className="btn"
                            style={{ backgroundColor: 'lightgray' }}

                        >Cancel
                        </button>

                        <button className="btn btn-primary submitRoom" style={{ marginLeft: '20px' }} onClick={() => submit()}>Submit</button>

                    </div>
                </div>

            </div>

        </div>
        <div className="modal-backdrop show"></div>
    </>
}
export default DownloadSectionReport
import { doAction, doResponseAction } from ".";


const DAYWISE_REPORT = 'DAYWISE_REPORT';
const DAYWISE_REPORT_SUCCESS = 'DAYWISE_REPORT_SUCCESS';
const DAYWISE_REPORT_FAILURE = 'DAYWISE_REPORT_FAILURE';

export {
    DAYWISE_REPORT ,
    DAYWISE_REPORT_SUCCESS,
    DAYWISE_REPORT_FAILURE,

}

export const daywiseReportAction = (params, callback) => doAction(DAYWISE_REPORT, params, callback);
export const daywiseReportSuccessAction = data => doResponseAction(DAYWISE_REPORT_SUCCESS, data);
export const daywiseReportFailureAction = data => doResponseAction(DAYWISE_REPORT_FAILURE, data);

import React, { useRef, useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux'
import Actions from '../Redux/action';

export const COLORS = {
    appThemeHex: '#1e1f29',
    appThemeHover: 'rgba(30, 31, 41, .5)',
    loadingBackround: 'rgba(255, 255, 255, .4)',
    listBackground: 'rgba(30, 31, 41, .2)',

}
export const customSelectStyles = {
    menu: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: COLORS.appThemeHex,
        boxShadow: null,
        display: 'flex',
        flex: 1,
        minWidth: '300px'
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? COLORS.appThemeHex : 'white',
        zIndex: 999
    }),
    valueContainer: (provided, state) => ({
        ...provided,
    }),
};

const WorkerRoomTypeAhead = ({
    defaultOptions = null,
    placeholder = '',
    isHostel,
    onChange = (value) => value,
    isMulti = false,
    ...props
}) => {
    // const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(defaultOptions || []);
    const dispatch = useDispatch();

    const optionsRef = useRef()

    const searchApi = useCallback((value) => {
        // setIsLoading(true);
        const defaultFilter = {
            keyword: value ? value : '',
            limit: 100,
            purpose : isHostel ? 1 : 0
        };
        dispatch(Actions.workerRoomMappingList(defaultFilter, (resp) => {
            let data = [];
            data = resp?.message?.map(d => ({ label: d.room_name , value: d.id }))
            // setIsLoading(false)
            setOptions(data)
            optionsRef.current = data
        }))
    }, [dispatch]);

    const debounceRef = useRef(debounce((value) => {
        searchApi(value ? value : '');
    }, 500));

    useEffect(() => {
        searchApi();
    }, [dispatch, searchApi])


    return <Select
        className="title-case w-75"
        {...props}
        placeholder={placeholder}
        // isLoading={isLoading}
        isClearable={true}
        isSearchable={true}
        onInputChange={(value) => debounceRef.current(value)}
        options={options}
        isMulti={isMulti}
        onChange={(value) => onChange(value || {})}
    />
}



export default WorkerRoomTypeAhead;
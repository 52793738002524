import { doAction, doResponseAction } from ".";

const HOSTEL_SPECIAL_ACCESS_LIST = 'HOSTEL_SPECIAL_ACCESS_LIST';
const HOSTEL_SPECIAL_ACCESS_LIST_SUCCESS = 'HOSTEL_SPECIAL_ACCESS_LIST_SUCCESS';
const HOSTEL_SPECIAL_ACCESS_LIST_FAILURE = 'HOSTEL_SPECIAL_ACCESS_LIST_FAILURE';

const HOSTEL_SPECIAL_ACCESS_DETAILS = 'HOSTEL_SPECIAL_ACCESS_DETAILS';
const HOSTEL_SPECIAL_ACCESS_DETAILS_SUCCESS = 'HOSTEL_SPECIAL_ACCESS_DETAILS_SUCCESS';
const HOSTEL_SPECIAL_ACCESS_DETAILS_FAILURE = 'HOSTEL_SPECIAL_ACCESS_DETAILS_FAILURE';

const CREATE_HOSTEL_SPECIAL_ACCESS = 'CREATE_HOSTEL_SPECIAL_ACCESS';
const CREATE_HOSTEL_SPECIAL_ACCESS_SUCCESS = 'CREATE_HOSTEL_SPECIAL_ACCESS_SUCCESS';
const CREATE_HOSTEL_SPECIAL_ACCESS_FAILURE = 'CREATE_HOSTEL_SPECIAL_ACCESS_FAILURE';

const HOSTEL_SPECIAL_ACCESS_DELETE = 'HOSTEL_SPECIAL_ACCESS_DELETE';
const HOSTEL_SPECIAL_ACCESS_DELETE_SUCCESS = 'HOSTEL_SPECIAL_ACCESS_DELETE_SUCCESS';
const HOSTEL_SPECIAL_ACCESS_DELETE_FAILURE = 'HOSTEL_SPECIAL_ACCESS_DELETE_FAILURE';

const HOSTEL_SPECIAL_ACCESS_UPDATE = 'HOSTEL_SPECIAL_ACCESS_UPDATE';
const HOSTEL_SPECIAL_ACCESS_UPDATE_SUCCESS = 'HOSTEL_SPECIAL_ACCESS_UPDATE_SUCCESS';
const HOSTEL_SPECIAL_ACCESS_UPDATE_FAILURE = 'HOSTEL_SPECIAL_ACCESS_UPDATE_FAILURE';

const HOSTEL_SPECIAL_ACCESS_ACTIVE = 'HOSTEL_SPECIAL_ACCESS_ACTIVE';
const HOSTEL_SPECIAL_ACCESS_ACTIVE_SUCCESS = 'HOSTEL_SPECIAL_ACCESS_ACTIVE_SUCCESS';
const HOSTEL_SPECIAL_ACCESS_ACTIVE_FAILURE = 'HOSTEL_SPECIAL_ACCESS_ACTIVE_FAILURE';
export {
    HOSTEL_SPECIAL_ACCESS_LIST,
    HOSTEL_SPECIAL_ACCESS_LIST_FAILURE,
    HOSTEL_SPECIAL_ACCESS_LIST_SUCCESS,

    HOSTEL_SPECIAL_ACCESS_DETAILS,
    HOSTEL_SPECIAL_ACCESS_DETAILS_FAILURE,
    HOSTEL_SPECIAL_ACCESS_DETAILS_SUCCESS,

    CREATE_HOSTEL_SPECIAL_ACCESS,
    CREATE_HOSTEL_SPECIAL_ACCESS_FAILURE,
    CREATE_HOSTEL_SPECIAL_ACCESS_SUCCESS,

    HOSTEL_SPECIAL_ACCESS_DELETE,
    HOSTEL_SPECIAL_ACCESS_DELETE_FAILURE,
    HOSTEL_SPECIAL_ACCESS_DELETE_SUCCESS,

    HOSTEL_SPECIAL_ACCESS_UPDATE,
    HOSTEL_SPECIAL_ACCESS_UPDATE_FAILURE,
    HOSTEL_SPECIAL_ACCESS_UPDATE_SUCCESS,

    HOSTEL_SPECIAL_ACCESS_ACTIVE,
    HOSTEL_SPECIAL_ACCESS_ACTIVE_FAILURE,
    HOSTEL_SPECIAL_ACCESS_ACTIVE_SUCCESS,
}

// HOSTEL_SPECIAL_ACCESS_LIST
export const hostelSpecialAccessList = (params, callback) => doAction(HOSTEL_SPECIAL_ACCESS_LIST, params, callback);
export const hostelSpecialAccessListSuccess = (data = {}) => doResponseAction(HOSTEL_SPECIAL_ACCESS_LIST_SUCCESS, data);
export const hostelSpecialAccessListFailure = (data = {}) => doResponseAction(HOSTEL_SPECIAL_ACCESS_LIST_FAILURE, data);

// HOSTEL_SPECIAL_ACCESS_DETAILS
export const hostelSpecialAccessDetails = (params, callback) => doAction(HOSTEL_SPECIAL_ACCESS_DETAILS, params, callback);
export const hostelSpecialAccessDetailsSuccess = (data = {}) => doResponseAction(HOSTEL_SPECIAL_ACCESS_DETAILS_SUCCESS, data);
export const hostelSpecialAccessDetailsFailure = (data = {}) => doResponseAction(HOSTEL_SPECIAL_ACCESS_DETAILS_FAILURE, data);

// CREATE_HOSTEL_SPECIAL_ACCESS
export const createhostelSpecialAccess = (params, callback) => doAction(CREATE_HOSTEL_SPECIAL_ACCESS, params, callback);
export const createhostelSpecialAccessSuccess = (data = {}) => doResponseAction(CREATE_HOSTEL_SPECIAL_ACCESS_SUCCESS, data);
export const createhostelSpecialAccessFailure = (data = {}) => doResponseAction(CREATE_HOSTEL_SPECIAL_ACCESS_FAILURE, data);

// HOSTEL_SPECIAL_ACCESS_DELETE
export const hostelSpecialAccessDelete = (params, callback) => doAction(HOSTEL_SPECIAL_ACCESS_DELETE, params, callback);
export const hostelSpecialAccessDeleteSuccess = (data = {}) => doResponseAction(HOSTEL_SPECIAL_ACCESS_DELETE_SUCCESS, data);
export const hostelSpecialAccessDeleteFailure = (data = {}) => doResponseAction(HOSTEL_SPECIAL_ACCESS_DELETE_FAILURE, data);


import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import Close from '../asset/img/modal-close.svg'
import Actions from '../Redux/action';
import { WorkerValidation, validateIPV4Regex, validateMacRegex, validateName } from '../Utils/Validations';
export const AddWorkerPopup = ({ onClose, data,pageNumber }) => {
    const dispatch = useDispatch();
    const [formDetails, setFormDetails] = useState({ ...data });
    const [errors, setErrors] = useState();

    const handleChange = (e) => {
        setFormDetails({ ...formDetails, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        if (Object.keys(formDetails)?.length !== 0) {
            let validatedError = WorkerValidation(formDetails, ["worker_name", "mac_address","ip_address"])
            if (validatedError) {
                setErrors(validatedError)
            }
        }
    }, [formDetails])

    const createWorker = () => {
        if (!formDetails?.worker_name ) {
            setErrors({ ...errors, worker_name: "Please enter the valid worker name" })
        }else if(!validateName(formDetails?.worker_name)){
            setErrors({ ...errors, worker_name: "Please enter the valid worker name" })
        } else if (!formDetails?.mac_address) {
            setErrors({ ...errors, mac_address: "Please enter the valid mac address" })
        }else if(!validateMacRegex(formDetails?.mac_address)){
            setErrors({ ...errors, mac_address: "Please enter the valid mac address" })
        } else if (!formDetails?.ip_address) {
            setErrors({ ...errors, ip_address: "Please enter the valid ip address" })
        }else if(!validateIPV4Regex(formDetails?.ip_address)){
            setErrors({ ...errors, ip_address: "Please enter the valid ip address" })
        } else {
            if (formDetails?.id) {
                const updateCallBack = (response) => {
                    if (response?.status_code === 0) {
                        toast.success("Worker successfully updated")
                        onClose()
                        let params = {
                            page: pageNumber
                        }
                        dispatch(Actions.workerList(params))
                    } else {
                        toast.error(response?.message)
                    }
                }
                let params = {
                    id:formDetails?.id,
                    worker_name: formDetails?.worker_name,
                    mac_address: formDetails?.mac_address,
                    ip_address: formDetails?.ip_address,
                }
                dispatch(Actions.workerUpdate(params, updateCallBack))
            } else {
                const createCallBack = (response) => {
                    if (response?.status_code === 0) {
                        toast.success("Worker successfully created")
                        onClose()
                        let params = {
                            page: pageNumber
                        }
                        dispatch(Actions.workerList(params))
                    } else {
                        toast.error(response?.message)
                    }
                }
                let params = {
                    worker_name: formDetails?.worker_name,
                    mac_address: formDetails?.mac_address,
                    ip_address: formDetails?.ip_address,
                }
                dispatch(Actions.createWorker(params, createCallBack))
            }
        }
    }
    return (
        <>
            

            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg planogram-div">
                    <div className="modal-content rounded-0">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() => onClose()} />
                        <div className="modal-header rounded-0 align-items-center py-4 px-4">
                            <div className="col">
                                <h4 className="modal-title">{data?.id?"Update worker":"Add Worker"}</h4>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Worker Name</label>
                                            <input placeholder='Type here' value={formDetails?.worker_name} name='worker_name' onChange={handleChange} type="text" className="form-control w-75" id="exampleInputEmail1" />
                                            {errors?.worker_name && <p className='errorMessage'>{errors?.worker_name}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Mac Address</label>
                                            <input placeholder='Type here' value={formDetails?.mac_address} name='mac_address' onChange={handleChange} type="text" className="form-control w-75" id="exampleInputEmail1" />
                                            {errors?.mac_address && <p className='errorMessage'>{errors?.mac_address}</p>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Ip Address</label>
                                            <input placeholder='Type here' value={formDetails?.ip_address} name='ip_address' onChange={handleChange} type="text" className="form-control w-75" id="exampleInputEmail1" />
                                            {errors?.ip_address && <p className='errorMessage'>{errors?.ip_address}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row-3">
                                    <button className="btn btn-primary submitRoom" onClick={() => createWorker()}>{formDetails?.id ? "Update" : "Create"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>

        </>
    )
}
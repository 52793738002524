import { doAction, doResponseAction } from ".";

const TOGGLE = 'TOGGLE';
const TOGGLE_SUCCESS = 'TOGGLE_SUCCESS';
const TOGGLE_FAILURE = 'TOGGLE_FAILURE';

const TOGGLE_HOSTELLER = 'TOGGLE_HOSTELLER';

export {
    TOGGLE,
    TOGGLE_FAILURE,
    TOGGLE_SUCCESS,
    TOGGLE_HOSTELLER
}
// toggle
export const toggleAction = (params, callback) => doAction(TOGGLE, params, callback);
export const toggleSuccessAction = data => doResponseAction(TOGGLE_SUCCESS, data);
export const toggleFailureAction = data => doResponseAction(TOGGLE_FAILURE, data);

export const toggleHostellerAction = (params, callback) => doAction(TOGGLE_HOSTELLER, params, callback);

import Close from '../asset/img/modal-close.svg'
export const LogoutPopup = (props) => {

    // const callback = () => {
    //     // navigate('/')
    //     localStorage.clear();
    //     props.close(false)
    //     setTimeout(() => {
    //         window.location.reload()
    //     }, 0);
    // }
    const logout = () => {
        // dispatch(Actions.logoutAction("", callback))
        localStorage.removeItem('persist:root')
        window.location.href = '/'
    }
    return (
        <>
            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl planogram-div">
                    <div className="modal-content LogOutPopup rounded-0">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() => {
                                props.close(false)
                            }
                            } />
                        <div className="modal-header logoutheader rounded-0 align-items-center mt-4 py-4 px-4">
                            <h3>Do you want to Logout ?</h3>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container">
                                <div className="row">
                                    <div className="col-6">
                                        <button className="btn btn-primary logOut" onClick={() => logout()}>Yes</button>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-secondary Cancel" onClick={() => props.close(false)}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}
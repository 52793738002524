import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import Close from '../asset/img/modal-close.svg'
import Actions from '../Redux/action';
import AppLoader from './AppLoader';
import { getCollegeList } from '../Redux/reducer/SchedulerReducer';
import moment from 'moment';
export const EditPopup = ({ data, roomId, close }) => {
    const dispatch = useDispatch();
    const [cameraCount, setCameraCount] = useState(0)
    const [authorizedUserCount, setAuthorizedUserCount] = useState(0)
    const [camera, setCamera] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [isDeleted, setDeleted] = useState(false)
    const [memberList, setmemberList] = useState([])
    const [authorizedUser, setAuthorizedUser] = useState([])
    const [cameraList, setCameraList] = useState([])
    const [editCameraList, setEditCameraList] = useState([])
    const [college, setCollege] = useState('')
    const [fromdate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const collegelist = useSelector(getCollegeList)

    //Callback for the member list api to set the memberList state
    const callback = (response) => {
        setLoading(false)
        if (response?.status_code !== 0) {
            toast.error("Something went worng")
        }
        else {
            setmemberList(response?.response)
        }
    }

    //UseEffect to call the member list api to get person and camera list store the edit add in cameralist and authorizedUser array
    useEffect(() => {
        console.log(data, 'data')
        setLoading(true)
        const params = {
            room_id: roomId
        }
        dispatch(Actions.editMemberListAction(params, callback))
        dispatch(Actions.CollegeListAction())

        //Store the edit data by using props data from the room list component to show the authorized user against the room.
        let user = []
        data?.authorized_persons && data?.authorized_persons?.forEach((item, index) => {
            user.push({
                user: item?.id,
            })
        })
        setAuthorizedUser(user)
        setCollege(data?.college?.id)
        setFromDate(data?.rooms_schedulers?.from_date)
        setToDate(data?.rooms_schedulers?.to_date)
        //Store the edit data by using props data from the room list component to show the camera against the room
        let camere = []
        data?.cameras && data?.cameras?.forEach((cameraData, index) => {
            camere.push({
                id: cameraData?.id,
                type: cameraData?.camera_type,
                camera_name: cameraData?.camera_name
            })
        })
        setCameraList(camere)

    }, [dispatch])

    //handle authorized user based on the array authorizedUser which contain all added user against the room
    const handleAuthorizedUser = (i, e) => {
        const updatedArray = [...authorizedUser];
        updatedArray[i] = { ...updatedArray[i], user: e.target.value }
        setAuthorizedUser(updatedArray)
    }

    //handle camera based on the array cameraList which contain all added camera against the room
    // const handleCamera = (i, items, e) => {
    //     console.log(items, 'items')
    //     handleCameraType(i, items?.type)
    //     console.log(camera, 'camera')
    //     const updateCameraArray = [...cameraList]
    //     updateCameraArray[i] = { ...updateCameraArray[i], id: e.target.value }
    //     console.log(updateCameraArray, 'update')
    //     setCameraList(updateCameraArray);
    //     setEditCameraList(updateCameraArray)
    // }

    const handleCamera = (i,item, e) => {
        const updatedCameraArray = [...editCameraList];
        updatedCameraArray[i] = { ...updatedCameraArray[i], id: e.target.value };
        setEditCameraList(updatedCameraArray);
    }
    
    //handle camera type based on the array cameraList which contain all added camera details against the room
    const handleCameraType = (i, e) => {
        console.log(memberList.cameras[i]?.camera_type, "memberlist")
        const updateCameraType = [...cameraList, ...camera]
        updateCameraType[i] = { ...updateCameraType[i], type: memberList?.cameras[i]?.camera_type }
        setEditCameraList(updateCameraType)
    }

    //handle add authorized user which increase the authorized user count and all the appendAuthorizedUser function to add one more user
    const addAuthorizedUser = (e) => {
        setAuthorizedUserCount(authorizedUserCount + 1)
        appendAuthorizedUser()

    }

    //It is used to push the another object against the varibale authorized user
    const appendAuthorizedUser = () => {
        if (authorizedUserCount >= 0) {
            setAuthorizedUser([...authorizedUser, { user: "" }])
        }
    }

    //Delete the authorized user based on the index provided by the delete icon
    const deleteAuthorized = (index, a) => {
        const updatedArray = authorizedUser.filter((obj, i) => i !== index); // create a new array with all objects except the one at the specified index
        setAuthorizedUser(updatedArray);
        setDeleted(!isDeleted)
    }

    //Add the carmera by increase the Camera count state and calling the function cameraCountList
    const addCamera = (e) => {
        setCameraCount(cameraCount + 1)
        cameraCountList()
    }

    //It push the object with empty id and type in the variable cameraList
    const cameraCountList = () => {
        if (cameraCount >= 0) {
            setCamera([{
                id: "",
                type: "EN",
            }])
        }
    }

    //Delete the camera based on the index provided by the delete icon in the camere list
    const deleteCamera = (index) => {
        const updatedCameraArray = cameraList.filter((obj, i) => i !== index); // create a new array with all objects except the one at the specified index
        setCameraList(updatedCameraArray);
        setDeleted(!isDeleted)
    }


    const deleteNewCamera = (index) => {
        const updatedCameraArray = editCameraList.filter((obj, i) => i !== index); // create a new array with all objects except the one at the specified index
        setEditCameraList(updatedCameraArray);
        setDeleted(!isDeleted)
    }

    //It is used to update the authorized user data and camera in the list
    const update = () => {
        const callback = (response) => {
            if (response?.status_code !== 0) {
                toast.error(response?.message ? response.message : 'Something went wrong')
            } else {
                toast.success("Updated Successfully")
                close(false)
            }
        }

        //To find the unique person value.
        // let uniqueAuthorizedPerson = authorizedUser.filter((obj, index, self) =>
        //     index === self.findIndex((t) => (
        //         t.user == obj.user
        //     ))
        // );

        //To find the unique camera.
        let uniqueCameraList = editCameraList.filter((obj, index, self) =>
            index === self.findIndex((t) => (
                parseInt(t.id) == parseInt(obj.id)
            ))
        );

        let getDummyCamera = editCameraList?.findIndex((cobj) => cobj?.id === '')
        if (getDummyCamera !== -1) {
            toast.error("Please choose any camera")
            return;
        }
        // if (uniqueAuthorizedPerson?.length !== authorizedUser?.length) {
        //     toast.error("Same user is found")
        // }
        else if (uniqueCameraList?.length !== editCameraList?.length) {
            toast.error("Same camera is found")
        } else if ((moment(fromdate).isAfter(moment(toDate)))) {
            toast.error("From date should not be greater than To date")
        } else {
            let params = {
                room_id: roomId,
                room_name: data?.name,
                room_no: data?.room_no,
                cameras: [...editCameraList,...cameraList],
                authorized_members: authorizedUser,
                college_id: Number(college),
                from_date: fromdate,
                to_date: toDate
            }
            dispatch(Actions.updateRoomAction(params, callback))
        }
    }
    return (
        <>
            {
                isLoading && <AppLoader />
            }

            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl planogram-div">
                    <div className="modal-content rounded-0">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() => {
                                setAuthorizedUser([])
                                setCameraList([])
                                close(false)
                            }
                            } />
                        <div className="modal-header rounded-0 align-items-center py-4 px-4">
                            <div className="col">
                                <h4 className="modal-title">Edit Room</h4>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Room Name</label>
                                            <input type="text" className="form-control w-75" id="exampleInputEmail1" disabled value={data?.name} />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Room Number</label>
                                            <input type="text" className="form-control w-75" id="exampleInputEmail1" disabled value={data?.room_no} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">From Date</label>
                                            <input type="date" className="form-control w-75" value={fromdate} id="exampleInputEmail1" onChange={(e) => {
                                                setFromDate(e.target.value)

                                            }
                                            } />

                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">To Date</label>
                                            <input type="date" className="form-control w-75" value={toDate} id="exampleInputEmail1" onChange={(e) => {
                                                setToDate(e.target.value)

                                            }
                                            } />

                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">College</label>
                                            <select
                                                disabled={true}
                                                value={college}
                                                onChange={(e) => {
                                                    setCollege(e?.target?.value)
                                                }}
                                                className="form-select w-75">
                                                <option value='' hidden>Select</option>
                                                {Array.isArray(collegelist) && collegelist?.map((o) => {
                                                    return <option value={o?.id} >{o?.name}</option>

                                                })
                                                }

                                            </select>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3 appendList">
                                            <div className="authorizedLabel">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Authorized User</label>
                                                <button className="btn btn-primary authorizedButton" onClick={(e) => addAuthorizedUser(e)}>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                    Add
                                                </button>
                                            </div>
                                            {
                                                authorizedUser && authorizedUser?.map((item, index) =>
                                                    <div className="authorizedSelect mb-3">
                                                        <select className={"form-select w-75 "} aria-label="Default select example" key={index} onChange={(e) => handleAuthorizedUser(index, e)} value={item?.user}>
                                                            <option value="" selected disabled>Select User</option>
                                                            {
                                                                memberList && memberList?.persons?.map((list, index) => {
                                                                    return <option value={list?.id} key={index}>{list?.name}</option>
                                                                }
                                                                )
                                                            }
                                                        </select>
                                                        {index !== 0 && <i className="fa fa-trash fa-xl cursor-pointer" aria-hidden="true" onClick={() => deleteAuthorized(index, data?.authorized_persons)}></i>}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div> */}
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="mb-3 appendList">
                                            <div className="authorizedLabel">
                                                <label htmlFor="exampleInputEmail1" className="form-label" >Add Camera</label>
                                                <button className="btn btn-primary cameraButton" onClick={(e) => addCamera(e)} >
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                    Add
                                                </button>
                                            </div>


                                            {
                                                cameraList?.length > 0 && cameraList?.map((item, index) => {
                                                    console.log(item, 'item')
                                                    return (
                                                        <div className="cameraDownListRoom cameraChangeList key={index}">

                                                            <select disabled className="form-select w-50 mb-2" aria-label="Default select example" onChange={(e) => handleCamera(index, item, e)} value={item?.id}>
                                                                <option disabled value={item?.id} key={index}>{item?.camera_name} {item?.camera_type}</option>

                                                            </select>
                                                            <i className="fa fa-trash fa-xl cursor-pointer mx-2" aria-hidden="true" onClick={() => deleteCamera(index, cameraList)}></i>

                                                        </div>
                                                    )
                                                })

                                            }
                                            {
                                                camera && camera?.map((item, index) =>
                                                    <>
                                                        <div className="cameraDownList cameraChangeList">
                                                            <select className="form-select w-50" aria-label="Default select example" defaultValue={'none'} onChange={(e) => handleCamera(index, item, e)} >
                                                                <option value="none" selected disabled hidden>Select Camera</option>
                                                                {
                                                                    memberList && memberList?.cameras?.map((list, index) =>
                                                                  <option value={list?.id} key={index}>{list?.camera_name} {list?.camera_type === "EX" ? "EXIT" : "ENTRY"}</option>

                                                                    )
                                                                }
                                                            </select>

                                                            <i className="fa fa-trash fa-xl mx-2" aria-hidden="true" onClick={() => deleteNewCamera(index, camera)}></i>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {/* {cameraCount?.map(()=>  <div className= "cameraDownListRoom cameraChangeList">
                                                <select className="form-select w-50" aria-label="Default select example" onChange={(e) => handleCamera(e.target.value)}>
                                                    <option value="" selected disabled>Select Camera</option>
                                                    {
                                                        memberList && memberList?.cameras?.map((list, index) => {
                                                            return <option value={list?.id} key={index}>{list?.camera_name} {list?.camera_type}</option>
                                                        })
                                                    }
                                                </select>
                                                <i className="fa fa-trash fa-xl cursor-pointer mx-2" aria-hidden="true" onClick={() => deleteCamera()}></i>
                                            </div>)} */}

                                        </div>
                                    </div>
                                </div>

                                <div className="row-3">

                                    <button className="btn btn-primary submitRoom" onClick={() => update()}>Update</button>&nbsp;

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="modal-backdrop show"></div>
        </>
    )
}
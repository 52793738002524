import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { MEMBER } from '../action/MemeberListAction'
import { doGet } from '../../service/index';
import END_POINTS from './EndPoints';

export function* memberSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.MEMBER_LIST_API, params)
        yield put(Actions.memberSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.memberFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* memberWatcher() {
    yield all([
        takeLatest(MEMBER, memberSaga),
    ]);
}
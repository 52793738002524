import { doAction, doResponseAction } from ".";

const WORKER_LIST = 'WORKER_LIST';
const WORKER_LIST_SUCCESS = 'WORKER_LIST_SUCCESS';
const WORKER_LIST_FAILURE = 'WORKER_LIST_FAILURE';

const WORKER_DETAILS = 'WORKER_DETAILS';
const WORKER_DETAILS_SUCCESS = 'WORKER_DETAILS_SUCCESS';
const WORKER_DETAILS_FAILURE = 'WORKER_DETAILS_FAILURE';

const CREATE_WORKER = 'CREATE_WORKER';
const CREATE_WORKER_SUCCESS = 'CREATE_WORKER_SUCCESS';
const CREATE_WORKER_FAILURE = 'CREATE_WORKER_FAILURE';

const WORKER_DELETE = 'WORKER_DELETE';
const WORKER_DELETE_SUCCESS = 'WORKER_DELETE_SUCCESS';
const WORKER_DELETE_FAILURE = 'WORKER_DELETE_FAILURE';

const WORKER_UPDATE = 'WORKER_UPDATE';
const WORKER_UPDATE_SUCCESS = 'WORKER_UPDATE_SUCCESS';
const WORKER_UPDATE_FAILURE = 'WORKER_UPDATE_FAILURE';

const WORKER_ACTIVE = 'WORKER_ACTIVE';
const WORKER_ACTIVE_SUCCESS = 'WORKER_ACTIVE_SUCCESS';
const WORKER_ACTIVE_FAILURE = 'WORKER_ACTIVE_FAILURE';
export {
    WORKER_LIST,
    WORKER_LIST_FAILURE,
    WORKER_LIST_SUCCESS,

    WORKER_DETAILS,
    WORKER_DETAILS_FAILURE,
    WORKER_DETAILS_SUCCESS,

    CREATE_WORKER,
    CREATE_WORKER_FAILURE,
    CREATE_WORKER_SUCCESS,

    WORKER_DELETE,
    WORKER_DELETE_FAILURE,
    WORKER_DELETE_SUCCESS,

    WORKER_UPDATE,
    WORKER_UPDATE_FAILURE,
    WORKER_UPDATE_SUCCESS,

    WORKER_ACTIVE,
    WORKER_ACTIVE_FAILURE,
    WORKER_ACTIVE_SUCCESS,
}

// WORKER_LIST
export const workerList = (params, callback) => doAction(WORKER_LIST, params, callback);
export const workerListSuccess = (data = {}) => doResponseAction(WORKER_LIST_SUCCESS, data);
export const workerListFailure = (data = {}) => doResponseAction(WORKER_LIST_FAILURE, data);

// WORKER_DETAILS
export const workerDetails = (params, callback) => doAction(WORKER_DETAILS, params, callback);
export const workerDetailsSuccess = (data = {}) => doResponseAction(WORKER_DETAILS_SUCCESS, data);
export const workerDetailsFailure = (data = {}) => doResponseAction(WORKER_DETAILS_FAILURE, data);

// CREATE_WORKER
export const createWorker = (params, callback) => doAction(CREATE_WORKER, params, callback);
export const createWorkerSuccess = (data = {}) => doResponseAction(CREATE_WORKER_SUCCESS, data);
export const createWorkerFailure = (data = {}) => doResponseAction(CREATE_WORKER_FAILURE, data);

// WORKER_DELETE
export const workerDelete = (params, callback) => doAction(WORKER_DELETE, params, callback);
export const workerDeleteSuccess = (data = {}) => doResponseAction(WORKER_DELETE_SUCCESS, data);
export const workerDeleteFailure = (data = {}) => doResponseAction(WORKER_DELETE_FAILURE, data);

// WORKER_UPDATE
export const workerUpdate = (params, callback) => doAction(WORKER_UPDATE, params, callback);
export const workerUpdateSuccess = (data = {}) => doResponseAction(WORKER_UPDATE_SUCCESS, data);
export const workerUpdateFailure = (data = {}) => doResponseAction(WORKER_UPDATE_FAILURE, data);

// WORKER_ACTIVE
export const workerActiveList = (params, callback) => doAction(WORKER_ACTIVE, params, callback);
export const workerActiveListSuccess = (data = {}) => doResponseAction(WORKER_ACTIVE_SUCCESS, data);
export const workerActiveListFailure = (data = {}) => doResponseAction(WORKER_ACTIVE_FAILURE, data);

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaginationComponent } from "../component/PaginationComponent";
import Actions from "../Redux/action"
import { onboardingData, onboardingStaffData } from "../Redux/reducer/OnboardingList";
import { toast } from "react-toastify";
import { Search } from "../component/Search";
import AppLoader from "../component/AppLoader";
import { IMAGE_URL } from "../Redux/saga/EndPoints";
import nodatafound from '../asset/img/datanotfound_black.png';
import Placeholder from '../asset/img/no-preview.png';
import { DeclinePopup } from "../component/DeclinePopup";
import AlertGeneratePsswordModal from "./AlertGeneratePasswordModal";
import { getCollegeList, getCourseList, getSectionList, getSelectedData, getYearList, getdepartmentList, getdepartmentSearch } from "../Redux/reducer/SchedulerReducer";
import { searchList } from "../Redux/reducer/SearchList";
import { getHostelListSelectSelector, getHostelListSelector, getHostellerListSelector } from "../Redux/reducer/HostelReducer";
import { getUserData } from "../Redux/reducer/LoginReducer";

export const OnboardingList = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUserData)
    const [pageNumber, setPageNumber] = useState(1)
    const onboardedList = useSelector(onboardingData)
    const onboardedStaffList = useSelector(onboardingStaffData)
    const [isloading, setLoading] = useState(false)
    const [count, setcount] = useState();
    const [alertGenPasswordModal, setAlertGetPasswordModal] = useState(false)
    const [isSearch, onSearch] = useState(false);
    const [user_name, setUserName] = useState('');
    const collegeList = useSelector(getCollegeList)
    const courseList = useSelector(getCourseList)
    const hostelList = useSelector(getHostelListSelectSelector)
    const departmentList = useSelector(getdepartmentList)
    const yearList = useSelector(getYearList)
    const sectionList = useSelector(getSectionList);
    const searchData = useSelector(searchList);
    const selctedFormData = useSelector(getSelectedData);
    const [formDetails, setFormDetails] = useState({});
    const tabValue = (user?.user_type === '5' ? 2 : 1)
    const [tab, setTab] = useState(tabValue)
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);
    const [showList, setShowList] = useState(false);
    const [searchvalue, setvalue] = useState('');
    const [itemObj, setItemObj] = useState('');
    const [hostelId, setHostelId] = useState();
    const hostellerList = useSelector(getHostellerListSelector)

    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const DepartmentSearch = useCallback(() => {
        setLoading(true)
        const callback = (res) => {
            setcount(res?.count)
            setLoading(false)
        }
        let params = {
            page: pageNumber,
            searching_text: searchvalue,
            college_id: formDetails?.college_id,
        }
        if (tab === 1) {
            params['course_id'] = formDetails?.course_id
            params['year_id'] = formDetails?.year_id
            params['department_id'] = formDetails?.department_id
            params['section_id'] = formDetails?.section_id
        } else {
            params['hostel_id'] = hostelId
        }
        tab === 1 ? dispatch(Actions.onboardingAction(params, callback))
            : dispatch(Actions.hostellerList(params, callback))
    }, [dispatch, formDetails?.college_id, formDetails?.course_id, formDetails?.department_id, formDetails?.year_id, formDetails?.section_id, tab, pageNumber, hostelId])

    useEffect(() => {
        DepartmentSearch()
    }, [dispatch, formDetails?.college_id, formDetails?.course_id, formDetails?.department_id, formDetails?.year_id, formDetails?.section_id, tab, pageNumber, hostelId])

    // useEffect(()=>{
    //   dispatch(Actions.)
    // },[])

    useEffect(() => {
        dispatch(Actions.CollegeListAction())
    }, [dispatch])

    useEffect(() => {
        if (tab === 1) {
            const params = {
                id: formDetails?.college_id
            }
            dispatch(Actions.CoursetAction(params))
        }
    }, [dispatch, formDetails?.college_id])

    useEffect(() => {
        if (tab === 1) {
            const params = {
                id: formDetails?.course_id
            }
            dispatch(Actions.DepartmentAction(params))
        }
    }, [dispatch, formDetails?.course_id])

    useEffect(() => {
        if (tab === 1) {
            const params = {
                id: formDetails?.department_id
            }
            dispatch(Actions.YearAction(params))
        }
    }, [dispatch, formDetails?.department_id])

    useEffect(() => {
        setHostelId('')
        let params = {
            college_id: formDetails?.college_id
        }
        dispatch(Actions.hostelListSelect(params))
    }, [dispatch, formDetails?.college_id])

    useEffect(() => {
        if (tab === 1) {
            const params = {
                id: formDetails?.department_id,
                year: formDetails?.year_id
            }
            dispatch(Actions.SectiontAction(params))
        }
    }, [dispatch, formDetails?.year_id])

    const updateStatus = (id, active) => {
        const updateParams = {
            id,
            is_active: !active,
        }
        let params = {
            page: pageNumber,
            searching_text: searchvalue,
            college_id: formDetails?.college_id,
        }
        if (tab === 1) {
            params['course_id'] = formDetails?.course_id
            params['department_id'] = formDetails?.department_id
            params['year_id'] = formDetails?.year_id
            params['section_id'] = formDetails?.section_id
            params['person_type'] = 0
        } else {
            params['hostel_id'] = hostelId
            params['person_type'] = 1
        }
        setLoading(true)
        const callback = (response) => {
            const onboardCallback = () => {
                setLoading(false)
            }
            if (response.status_code === 0) {
                toast.success(!active ? "Enabled Successfully" : "Disabled Successfully")
                setLoading(false)
                tab === 1 ? dispatch(Actions.onboardingAction(params, onboardCallback())) : dispatch(Actions.hostellerList(params, onboardCallback()))
            }
            else {
                setLoading(false)
                toast.error(response.response_message)
            }
        }
        tab === 1 ? dispatch(Actions.toggleAction(updateParams, callback)) : dispatch(Actions.toggleHostellerAction(updateParams, callback))
    }

    const deleteMember = () => {
        setLoading(true)
        const params = {
            person_id: itemObj?.member_id,
        }
        if (tab === 1) {
            params['person_type'] = 0
        } else {
            params['person_type'] = 1
        }
        const callback = (res) => {
            if (res?.status_code === 0) {
                // setLoading(false)
                // let params = {
                //     page: pageNumber ?? 1
                // }
                let params = {
                    page: pageNumber ?? 1,
                    searching_text: searchvalue,
                    college_id: formDetails?.college_id,
                }
                if (tab === 1) {
                    params['course_id'] = formDetails?.course_id
                    params['department_id'] = formDetails?.department_id
                    params['year_id'] = formDetails?.year_id
                    params['section_id'] = formDetails?.section_id
                    params['person_type'] = 0
                } else {
                    params['hostel_id'] = hostelId
                    params['person_type'] = 1
                }
                const callback = (response) => {
                    setLoading(false)
                    onCloseModel()
                    if (response) {
                        setLoading(false)
                        toast.success("Deleted Successfully")
                        setcount(response?.count)
                        // getOnboardList()
                    }
                    else {
                        setLoading(false)
                        toast.error("Something went worng")
                    }
                }

                tab === 1 ? dispatch(Actions.onboardingAction(params, callback))
                    : dispatch(Actions.hostellerList(params, callback))
            } else {
                setLoading(false)
                toast.error(res?.message)
            }
        }
        dispatch(Actions.deleteMember(params, callback))
    }

    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };

    const openGeneratPasswordeAlert = (data) => {
        setUserName(data)
        setAlertGetPasswordModal(true)
    }
    const handleClick = (item) => {
        setItemObj(item);
        setOpenDeclineModal(true);
    };
    const handleCollegeSelect = (selected) => {
        setFormDetails({ ...formDetails, 'course_id': '', 'year_id': '', 'department_id': '', 'section_id': '', 'college_id': selected })
    };
    const handleDepartmentSelect = (selected) => {
        setFormDetails({ ...formDetails, 'year_id': '', 'department_id': selected, 'section_id': '' })
    };
    const handleCourseSelect = (selected) => {
        setFormDetails({ ...formDetails, 'department_id': '', 'course_id': selected, 'section_id': '', 'year_id': '' })
    };
    const handleYearSelect = (selected) => {
        setFormDetails({ ...formDetails, 'year_id': selected, 'section_id': '' })
    };
    const handleSectionSelect = (selected) => {
        setFormDetails({ ...formDetails, 'section_id': selected })
    };
    const url = IMAGE_URL.replace(/\/$/, '');
    const handleTab = (tab) => {
        if(user?.user_type === "5"){
            return
        }
        setPageNumber(1)
        setTab(tab)
        setvalue("")
        setFormDetails({ college_id: "" })
        setShowList(false)
        setHostelId("")
    }
    const handleOnchangeSearch = (event) => {
        const callback = (response) => {
            dispatch(Actions.onboardingSearchAction(response))
            setLoading(false)
        }
        const callBack = (response) => {
            setcount(response?.count)
            dispatch(Actions.searchSuccessAction(response))
            setLoading(false)
            onSearch(false)
            setPageNumber(1)
        }
        if ((event.target.value)?.length >= 3) {
            setShowList(true)
            onSearch(true)
            let params = {
                searching_text: event.target.value,
                college_id: formDetails?.college_id,
            }
            if (tab === 1) {
                params['course_id'] = formDetails?.course_id
                params['department_id'] = formDetails?.department_id
                params['year_id'] = formDetails?.year_id
                params['section_id'] = formDetails?.section_id
                params['person_type'] = 0
            } else {
                params['hostel_id'] = hostelId
                params['person_type'] = 1
            }
            setLoading(true)
            dispatch(Actions.searchAction(params, callback));
            dispatch(Actions.hostellerList(params, callBack))
        } else if ((event.target.value)?.length === 0) {
            setShowList(true)
            let params = {
                page: 1,
                searching_text: event?.target?.value,
                college_id: formDetails?.college_id,
            }
            if (tab === 1) {
                params['course_id'] = formDetails?.course_id
                params['department_id'] = formDetails?.department_id
                params['year_id'] = formDetails?.year_id
                params['section_id'] = formDetails?.section_id
                params['person_type'] = 0
            } else {
                params['hostel_id'] = hostelId
                params['person_type'] = 1
            }
            setLoading(true)
            tab === 1 ? dispatch(Actions.onboardingAction(params, callBack))
                : dispatch(Actions.hostellerList(params, callBack))
        }
        else {
            setShowList(false)
        }
        setvalue(event.target.value)
    }
    const Onsearch = (serachProduct) => {
        const callback = (response) => {
            dispatch(Actions.onboardingSearchAction(response))
            setLoading(false)
        }
        setShowList(false)
        setLoading(true)
        // setvalue(serachProduct)
        let params = {
            id: serachProduct
        }
        dispatch(Actions.searchListAction(params, callback))
    }
    return (
        <>
            {
                isloading && <AppLoader />
            }
            <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                <div className="card">

                    <h5 className="card-header">
                        <p>Onboarded List</p>
                        <nav className="nav nav-pills flex-column flex-sm-row">
                            {user?.user_type === "0" && <button className={`flex-sm-fill text-sm-center nav-link ${tab === 1 ? "common_btn" : "nav-div"}`} onClick={() => handleTab(1)}>College</button>}
                            <button className={`flex-sm-fill  text-sm-center nav-link ${(user?.user_type === "5") ? "cursor_pointer_disable" : ""} ${(tab === 2 || user?.user_type === "5") ? "common_btn" : "nav-div"}`} onClick={() => handleTab(2)}>Hostler</button>
                        </nav>
                    </h5>
                    <div className="card-body">
                        <div className="">
                            <div className="row mb-3">
                                <div className="col-sm-4 mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Search</label>
                                    <div className="search">
                                        <i className="fa fa-search fa-1x"></i>
                                        <input type="text" className="form-control" placeholder="Enter the Name/Employee ID" onChange={(e) => handleOnchangeSearch(e)} value={searchvalue} />
                                        {
                                            showList &&
                                            <div className='searchAutoComplete'>
                                                <div className="container searchAutoContainer">
                                                    {
                                                        searchData && Array.isArray(searchData) && searchData?.map((item, index) =>
                                                            <div className="row" key={index}>
                                                                <div className="col-3 searchcolumn">
                                                                    <img src={IMAGE_URL + item.img_url} alt="serach" style={{ width: "40px", height: "40px" }} />
                                                                </div>
                                                                <div className="col-9 searchcolumn2" onClick={() => Onsearch(item?.id)}>
                                                                    {item?.name},{item?.member_id}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">College</label>
                                    <select value={formDetails?.college_id} onChange={(e) => handleCollegeSelect(e?.target?.value)}
                                        className="form-select">
                                        <option value='' hidden>Select College </option>
                                        {Array.isArray(collegeList) && collegeList?.map((o) => {
                                            return <option value={o?.id}>{o?.name}</option>

                                        })
                                        }
                                    </select>
                                </div>
                                {(tab === 1 && user?.user_type === "0") ? <div className="col-sm-4 mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Course</label>
                                    <select value={formDetails?.course_id} disabled={formDetails?.college_id ? false : true} onChange={(e) => handleCourseSelect(e?.target?.value)}
                                        className="form-select">
                                        <option value='' hidden>Select Course</option>
                                        {Array.isArray(courseList) && courseList?.map((o) => {
                                            return <option value={o?.id}>{o?.name}</option>

                                        })
                                        }
                                    </select>
                                </div>
                                    :
                                    <div className="col-sm-4 mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Hostel</label>
                                        <select value={hostelId} disabled={formDetails?.college_id ? false : true} onChange={(e) => setHostelId(e?.target?.value)}
                                            className="form-select">
                                            <option value='' hidden>Select Hostel</option>
                                            {Array.isArray(hostelList) && hostelList?.map((o) => {
                                                return <option value={o?.id}>{o?.name}</option>

                                            })
                                            }
                                        </select>
                                    </div>
                                }
                            </div>
                            <div className="row mb-3">
                                {(tab === 1 && user?.user_type === "0") && <div className="col-sm-4 mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Department</label>
                                    <select disabled={formDetails?.course_id ? false : true} value={formDetails?.department_id} onChange={(e) => handleDepartmentSelect(e?.target?.value)}
                                        className="form-select">
                                        <option value='' hidden>Select Department</option>
                                        {Array.isArray(departmentList) && departmentList?.map((o) => {
                                            return <option value={o?.id}>{o?.name}</option>
                                        })
                                        }
                                    </select>
                                </div>}
                                {(tab === 1 && user?.user_type === "0") && <div className="col-sm-4 mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Year</label>
                                    <select value={formDetails?.year_id} disabled={formDetails?.department_id ? false : true} onChange={(e) => handleYearSelect(e?.target?.value)}
                                        className="form-select">
                                        <option value='' hidden>Select Year</option>
                                        {Array.isArray(yearList) && yearList?.map((o) => {
                                            return <option value={o?.id}>{o?.name}</option>
                                        })}
                                    </select>
                                </div>}
                                {(tab === 1 && user?.user_type === "0") && <div className="col-sm-4 mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Section</label>
                                    <select value={formDetails?.section_id} disabled={formDetails?.year_id ? false : true} onChange={(e) => handleSectionSelect(e?.target?.value)}
                                        className="form-select">
                                        <option value='' hidden>Select Section</option>
                                        {Array.isArray(sectionList) && sectionList?.map((o) => {
                                            // return o?.sections?.map((o) => {
                                            return <option value={o?.id}>{o?.name}</option>
                                            // })

                                        })}
                                    </select>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div className="table-responsive">
                            {

                                (tab === 1 && user?.user_type === "0") &&
                                (
                                    ((onboardedList?.results && onboardedList?.results?.length > 0) || (onboardedList?.response && onboardedList?.response?.length > 0))
                                        ?
                                        <table className="table onboardTable">
                                            <thead className="bg-light">

                                                <tr className="border-0">
                                                    <th className="border-0 text-center">S.No</th>
                                                    <th className="border-0 text-center">Image</th>
                                                    <th className="border-0 text-center">Name</th>
                                                    <th className="border-0 text-center">Employee ID</th>
                                                    <th className="border-0 text-center">Mask Status</th>
                                                    <th className="border-0 text-center">Re-generate password</th>
                                                    <th className="border-0 text-center">Status</th>
                                                    <th className="border-0 text-center">Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    ((tab === 1 && user?.user_type === "0") &&
                                                        ((onboardedList?.results || onboardedList?.response) && (onboardedList?.results?.length > 0 ? onboardedList?.results : onboardedList?.response))).map((item, index) =>
                                                            <tr>
                                                                <td className=" text-center">{((pageNumber - 1) * 10) + (index + 1)}</td>
                                                                <td className=" text-center">
                                                                    <div className="m-r-10"><img src={item?.img_url ? `${url + item?.img_url}` : Placeholder} alt="user" className="rounded" width="45" /></div>
                                                                </td>
                                                                <td className=" text-center">{item?.name} </td>
                                                                <td className=" text-center">{item?.member_id} </td>
                                                                <td className=" text-center">{item?.mask_flag ? <>
                                                                    <i className='fas fa-head-side-mask'></i> <i className='fas fa-head-side-cough-slash'></i>
                                                                </> : <>
                                                                    <i className='fas fa-head-side-cough-slash'></i>
                                                                </>
                                                                } </td>
                                                                <td className="text-center" ><button className="btn btn-primary" onClick={() => openGeneratPasswordeAlert(item?.member_id)}><i class="fas fa-lock cursor-pointer" ></i> Change password</button></td>
                                                                <td className=" text-center">
                                                                    <i className={item.is_active ? "fa-solid fa-toggle-on fa-2xl cursor-pointer" : "fa-solid fa-toggle-off fa-2xl cursor-pointer"} onClick={() => updateStatus(item?.id, item?.is_active)} />
                                                                </td>
                                                                <td className=" text-center"><i className="fa fa-lg fa-trash cursor-pointer" aria-hidden="true" onClick={() => handleClick(item)}></i></td>
                                                            </tr>
                                                        )
                                                }
                                                {
                                                    isSearch ? <></> :
                                                        <tr>
                                                            <td colspan="9">
                                                                <PaginationComponent onChangeHandle={onChangeHandle} pageCount={count} pageNumber={pageNumber} setpage={setPageNumber} />
                                                            </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <div className="d-flex justify-content-center mt-5 mb-5">
                                            <div className="NodataFound">
                                                <h3>No Data Found</h3>
                                            </div>
                                            <div className="NodataImage">
                                                <img src={nodatafound} alt="No Data Found" className="NoData" />
                                            </div>
                                        </div>
                                )
                            }
                            {
                                (tab === 2 && (
                                    hostellerList?.length > 0 ?
                                        <table className="table onboardTable">
                                            <thead className="bg-light">
                                                <tr className="border-0">
                                                    <th className="border-0 text-center">S.No</th>
                                                    <th className="border-0 text-center">Image</th>
                                                    <th className="border-0 text-center">Name</th>
                                                    <th className="border-0 text-center">Person ID</th>
                                                    <th className="border-0 text-center">Mask Status</th>
                                                    {user?.user_type !== "5" && <th className="border-0 text-center">Status</th>}
                                                    {user?.user_type !== "5" && <th className="border-0 text-center">Delete</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tab === 2 &&
                                                    hostellerList?.length > 0 && hostellerList?.map((item, index) =>
                                                        <tr>
                                                            <td className=" text-center">{((pageNumber - 1) * 10) + (index + 1)}</td>
                                                            <td className=" text-center">
                                                                <div className="m-r-10"><img src={item?.img_url ? `${url + item?.img_url}` : Placeholder} alt="user" className="rounded" width="45" /></div>
                                                            </td>
                                                            <td className=" text-center">{item?.name} </td>
                                                            <td className=" text-center">{item?.member_id} </td>
                                                            <td className=" text-center">{item?.mask_flag ? <>
                                                                <i className='fas fa-head-side-mask'></i> <i className='fas fa-head-side-cough-slash'></i>
                                                            </> : <>
                                                                <i className='fas fa-head-side-cough-slash'></i>
                                                            </>
                                                            } </td>
                                                            {/* <td className="text-center" ><button className="btn btn-primary" onClick={() => openGeneratPasswordeAlert(item?.member_id)}><i class="fas fa-lock cursor-pointer" ></i> Change password</button></td> */}
                                                            {user?.user_type !== "5" && <td className=" text-center">
                                                                <i className={item.is_active ? "fa-solid fa-toggle-on fa-2xl cursor-pointer" : "fa-solid fa-toggle-off fa-2xl cursor-pointer"} onClick={() => updateStatus(item?.id, item?.is_active)} />
                                                            </td>}
                                                            {user?.user_type !== "5" && <td className=" text-center"><i className="fa  fa-lg fa-trash cursor-pointer" aria-hidden="true" onClick={() => handleClick(item)}></i></td>
                                                            }</tr>
                                                    )
                                                }
                                                {
                                                    isSearch ? <></> :
                                                        <tr>
                                                            <td colspan="9">
                                                                <PaginationComponent onChangeHandle={onChangeHandle} pageCount={count} pageNumber={pageNumber} setpage={setPageNumber} />
                                                            </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <div className="d-flex justify-content-center mt-5 mb-5">
                                            <div className="NodataFound">
                                                <h3>No Data Found</h3>
                                            </div>
                                            <div className="NodataImage">
                                                <img src={nodatafound} alt="No Data Found" className="NoData" />
                                            </div>
                                        </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {
                    alertGenPasswordModal && <AlertGeneratePsswordModal onClose={() => setAlertGetPasswordModal(false)} user_name={user_name} />
                }
            </div>
            {isOpenDeclineModal && <DeclinePopup
                title={`Are you sure you want to delete ${itemObj?.name}?`}
                onClose={() => onCloseModel()}
                onSuccess={() => deleteMember()}
            />}
        </>
    )
}
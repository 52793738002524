import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppLoader from "../component/AppLoader";
import { AddWorkerRoomPopup } from "../component/AddWorkerRoom";
import { PaginationComponent } from "../component/PaginationComponent"
import Actions from "../Redux/action";
import { getWorkerRoomListSelector, getWorkerRoomCountSelector } from "../Redux/reducer/WorkerRoomReducer";
import nodatafound from '../asset/img/datanotfound_black.png';
import { DeclinePopup } from "../component/DeclinePopup";
export const WorkerRoomList = () => {
    const totalCount = useSelector(getWorkerRoomCountSelector);
    const [pageNumber, setPageNumber] = useState(1);
    const [isloading, setLoading] = useState(false);
    const workerRoomlist = useSelector(getWorkerRoomListSelector);
    const [popupData, setPopupData] = useState();
    const [popup, setPopup] = useState(false);
    const [itemObj, setItemObj] = useState('')
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);
    const dispatch = useDispatch();

    const getWorkerRoomList = useCallback(() => {
        const callback = () => {
            setLoading(false)
        }
        let params = {
            page: pageNumber,
            mapping:1
        }
        setLoading(true)
        dispatch(Actions.workerRoomList(params, callback))
    }, [dispatch, pageNumber])

    useEffect(() => {
        getWorkerRoomList()
    }, [getWorkerRoomList])

    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const editWorkerRoom = (workerRoomData) => {
        setPopupData(workerRoomData)
        setPopup(true)
    }

    const handleClick = (item) => {
        setItemObj(item);
        setOpenDeclineModal(true);
    };

    const deleteWorkerRoom = () => {
        const roomId = itemObj?.rooms?.map(d => ({ id: d?.id }))
        const callback = (response) => {
            setLoading(false)
            if (response?.status_code === 0) {
                toast.success("Worker room successfully deleted")
                getWorkerRoomList()
                onCloseModel()
                if (workerRoomlist?.length === 1) {
                    setPageNumber(pageNumber - 1)
                } else {
                    setPageNumber(pageNumber)
                }
            } else {
                toast.error(response?.message)
            }
        }
        let params = {
            worker_id: itemObj?.id,
            room_id: roomId
        }
        setLoading(true)
        dispatch(Actions.workerRoomDelete(params, callback))
    }

    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };

    const onClosePopUp = () => {
        setPopupData('')
        setPopup(false)
    }

    // const renderCamera = (item) => {
    //     let camCount = 0
    //     if (item?.rooms?.length) {
    //         item?.rooms?.forEach((camObj) => {
    //             camCount += camObj?.cameras?.length ? camObj?.cameras?.length : 0
    //         })
    //     } else {
    //         camCount = 0
    //     }
    //     return camCount
    // }

    return (
        <>
            {
                isloading && <AppLoader />
            }
            <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                <div className="mb-2" style={{ textAlign: 'end' }}>
                    <button type="button" className="btn btn-primary" onClick={() => setPopup(true)}>Add Worker Room</button>
                </div>
                <div className="card">
                    <h5 className="card-header">Worker Room List</h5>

                    <div className="card-body p-0">
                        {
                            workerRoomlist && workerRoomlist.length > 0 ?
                                <div className="table-responsive">
                                    <table className="table onboardTable">
                                        <thead className="bg-light">
                                            <tr className="border-0">
                                                <th className="border-0">S.No</th>
                                                <th className="border-0">Worker Name</th>
                                                <th className="border-0">Worker Room</th>
                                                <th className="border-0">Cameras</th>
                                                <th className="border-0">Edit</th>
                                                <th className="border-0">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                workerRoomlist && workerRoomlist?.map((item, index) => {
                                                    const totalCamerasCount = item?.rooms?.reduce((acc, curr) => {
                                                        return acc + curr?.cameras?.length;
                                                      }, 0);
                                                    return (<tr>
                                                        <td>{((pageNumber - 1) * 10) + (index + 1)}</td>
                                                        <td>{item?.worker_name}</td>
                                                        <td className="authorizedBox">
                                                            {
                                                                item?.rooms.map((obj, i) => {
                                                                    return (<div key={i}>
                                                                        {obj?.room_name}
                                                                    </div>
                                                                    )
                                                                }
                                                                )}
                                                        </td>
                                                        <td>{totalCamerasCount}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" onClick={() => editWorkerRoom(item)}>Edit</button>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" onClick={() => handleClick(item)}>Delete</button>
                                                        </td>
                                                    </tr>)
                                                }
                                                )
                                            }
                                            <tr>
                                                <td colspan="9">
                                                    <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="d-flex justify-content-center mt-5">
                                    <div className="NodataFound">
                                        <h3>No Data Found</h3>
                                    </div>
                                    <div className="NodataImage">
                                        <img src={nodatafound} alt="No Data Found" className="NoData" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {
                popup && <AddWorkerRoomPopup
                    onClose={() => onClosePopUp()}
                    data={popupData}
                    pageNumber={pageNumber}
                />

            }
             {isOpenDeclineModal && <DeclinePopup
                title={`Are you sure you want to delete ?`}
                onClose={() => onCloseModel()}
                onSuccess={() => deleteWorkerRoom()}
            />}
        </>
    )
}
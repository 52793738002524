import Actions from "../action";

const initialState = {
    isLoading: false,
    roomList: undefined,
    error: false
}
const RoomListReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.ROOM_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ROOM_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                roomList: action.data,
                error: false
            };
        }
        case Actions.ROOM_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                roomList: undefined,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const roomListData = (state) => state?.ROOM_LIST_CRED?.roomList?.results;
export default RoomListReducer;
import Actions from "../action";
 
const initialState = {
    isLoading: false,
    onboarding: undefined,
    onboardingStaff: undefined,
    error: false
}
const onboardingListReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.ONBOARDINGLIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ONBOARDINGLIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                onboarding: action.data,
                error: false
            };
        }
        case Actions.ONBOARDING_SEARCH_LIST: {
            return {
                ...state,
                isLoading: false,
                onboarding: action.data,
                error: action.error,
            }
        }
        case Actions.ONBOARDINGLIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                onboarding: undefined,
                error: action.error,
            };
        }
        case Actions.ONBOARDINGSTAFFLIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ONBOARDINGSTAFFLIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                onboardingStaff: action.data,
                error: false
            };
        }
        case Actions.ONBOARDINGSTAFFLIST_SEARCH_LIST: {
            return {
                ...state,
                isLoading: false,
                onboardingStaff: action.data,
                error: action.error,
            }
        }
        case Actions.ONBOARDINGSTAFFLIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                onboardingStaff: undefined,
                error: action.error,
            };
        }
        case Actions.DELETE_MEMBER_LIST:{
            return {
                ...state,
                isLoading:false,
 
            }
        }
        case Actions.DELETE_MEMBER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
               
            }
        }
        case Actions.DELETE_MEMBER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error:undefined
            }
        }
        case Actions.GENERATE_PASSWORD: {
            return {
                ...state,
                isLoading: false,
 
            }
        }
        case Actions.GENERATE_PASSWORD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
 
            }
        }
        case Actions.GENERATE_PASSWORD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: undefined
            }
        }
        default:
            return state;
    }
};
export const onboardingData = (state) => state?.ONBOARDINGlIST_CRED?.onboarding;
export const onboardingStaffData = (state) => state?.ONBOARDINGlIST_CRED?.onboardingStaff;
export default onboardingListReducer;
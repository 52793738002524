import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppLoader from "../component/AppLoader";
import { PaginationComponent } from "../component/PaginationComponent"
import Actions from "../Redux/action";
import { getHostelSpecialAccessListSelector, getHostelSpecialAccessCountSelector, getHostelSpecialAccessListLoadingSelector } from "../Redux/reducer/HostelSpecialAccessReducer";
import nodatafound from '../asset/img/datanotfound_black.png';
import { DeclinePopup } from "../component/DeclinePopup";
import { AddHostelSpecialAccessPopup } from "../component/AddHostelSpecialAccess";
import moment from "moment";
export const HostelSpecialAccess = () => {
    const totalCount = useSelector(getHostelSpecialAccessCountSelector);
    const loading = useSelector(getHostelSpecialAccessListLoadingSelector);

    const [pageNumber, setPageNumber] = useState(1);
    const hostelSpecialAccesslist = useSelector(getHostelSpecialAccessListSelector);
    const [popupData, setPopupData] = useState();
    const [popup, setPopup] = useState(false);
    const dispatch = useDispatch();
    const [itemObj, setItemObj] = useState('')
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);

    const getHostelSpecialAccessList = useCallback(() => {
        let params = {
            page: pageNumber
        }
        dispatch(Actions.hostelSpecialAccessList(params))
    }, [dispatch, pageNumber])

    useEffect(() => {
        getHostelSpecialAccessList()
    }, [getHostelSpecialAccessList])

    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }


    const deleteHostelSpecialAccess = () => {
        const callback = (response) => {
            if (response?.status_code === 0) {
                onCloseModel()
                toast.success("HostelSpecialAccess successfully deleted")
                getHostelSpecialAccessList()
                if (hostelSpecialAccesslist?.length === 1) {
                    setPageNumber(pageNumber - 1)
                } else {
                    setPageNumber(pageNumber)
                }

            } else {
                toast.error(response?.message)
            }
        }
        let params = {
            id: itemObj?.id
        }
        dispatch(Actions.hostelSpecialAccessDelete(params, callback))
    }
    const onClosePopUp = () => {
        setPopupData('')
        setPopup(false)
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const handleClick = (item) => {
        setItemObj(item);
        setOpenDeclineModal(true);
    };
    return (
        <>
            {
                loading && <AppLoader />
            }
            <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                <div className="mb-2" style={{ textAlign: 'end' }}>
                    <button type="button" className="btn btn-primary" onClick={() => setPopup(true)}>Add Special Access</button>
                </div>
                <div className="card">
                    <h5 className="card-header">Hostel Special Access</h5>

                    <div className="card-body p-0">
                        {
                            hostelSpecialAccesslist && hostelSpecialAccesslist.length > 0 ?
                                <div className="table-responsive">
                                    <table className="table onboardTable">
                                        <thead className="bg-light">
                                            <tr className="border-0">
                                                <th className="border-0">S.No</th>
                                                <th className="border-0">Name</th>
                                                <th className="border-0">Hostel Name</th>
                                                <th className="border-0">Start Time</th>
                                                <th className="border-0">End Time</th>
                                                <th className="border-0">Entry Type</th>
                                                <th className="border-0">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                hostelSpecialAccesslist && hostelSpecialAccesslist?.map((item, index) => {
                                                    return (<tr>
                                                        <td>{((pageNumber - 1) * 10) + (index + 1)}</td>
                                                        <td>{item?.name} ({item?.member_id})</td>
                                                        <td>{item?.hostel} </td>
                                                        <td>{moment(item?.start_time).format("DD-MM-YYYY HH:mm:ss")}</td>
                                                        <td>{moment(item?.end_time).format("DD-MM-YYYY HH:mm:ss")}</td>
                                                        <td>{item?.entry_type} </td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" onClick={() => handleClick(item)}>Delete</button>
                                                        </td>
                                                    </tr>)
                                                }
                                                )
                                            }
                                            <tr>
                                                <td colspan="9">
                                                    <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="d-flex justify-content-center mt-5">
                                    <div className="NodataFound">
                                        <h3>No Data Found</h3>
                                    </div>
                                    <div className="NodataImage">
                                        <img src={nodatafound} alt="No Data Found" className="NoData" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {
                popup && <AddHostelSpecialAccessPopup pageNumber={pageNumber} onClose={() => onClosePopUp(false)} data={popupData} />

            }
            {isOpenDeclineModal && <DeclinePopup
                title={`Are you sure you want to delete ${itemObj?.hostel} special access ?`}
                onClose={() => onCloseModel()}
                onSuccess={() => deleteHostelSpecialAccess()}
            />}
        </>
    )
}
import { doAction, doResponseAction } from ".";

const CAMERA_LIST = 'CAMERA_LIST';
const CAMERA_LIST_SUCCESS = 'CAMERA_LIST_SUCCESS';
const CAMERA_LIST_FAILURE = 'CAMERA_LIST_FAILURE';

const CAMERA_DETAILS = 'CAMERA_DETAILS';
const CAMERA_DETAILS_SUCCESS = 'CAMERA_DETAILS_SUCCESS';
const CAMERA_DETAILS_FAILURE = 'CAMERA_DETAILS_FAILURE';

const CREATE_CAMERA = 'CREATE_CAMERA';
const CREATE_CAMERA_SUCCESS = 'CREATE_CAMERA_SUCCESS';
const CREATE_CAMERA_FAILURE = 'CREATE_CAMERA_FAILURE';

const CAMERA_DELETE = 'CAMERA_DELETE';
const CAMERA_DELETE_SUCCESS = 'CAMERA_DELETE_SUCCESS';
const CAMERA_DELETE_FAILURE = 'CAMERA_DELETE_FAILURE';

const CAMERA_UPDATE = 'CAMERA_UPDATE';
const CAMERA_UPDATE_SUCCESS = 'CAMERA_UPDATE_SUCCESS';
const CAMERA_UPDATE_FAILURE = 'CAMERA_UPDATE_FAILURE';

const CAMERA_ACTIVE = 'CAMERA_ACTIVE';
const CAMERA_ACTIVE_SUCCESS = 'CAMERA_ACTIVE_SUCCESS';
const CAMERA_ACTIVE_FAILURE = 'CAMERA_ACTIVE_FAILURE';
export {
    CAMERA_LIST,
    CAMERA_LIST_FAILURE,
    CAMERA_LIST_SUCCESS,

    CAMERA_DETAILS,
    CAMERA_DETAILS_FAILURE,
    CAMERA_DETAILS_SUCCESS,

    CREATE_CAMERA,
    CREATE_CAMERA_FAILURE,
    CREATE_CAMERA_SUCCESS,

    CAMERA_DELETE,
    CAMERA_DELETE_FAILURE,
    CAMERA_DELETE_SUCCESS,

    CAMERA_UPDATE,
    CAMERA_UPDATE_FAILURE,
    CAMERA_UPDATE_SUCCESS,

    CAMERA_ACTIVE,
    CAMERA_ACTIVE_FAILURE,
    CAMERA_ACTIVE_SUCCESS,
}

// CAMERA_LIST
export const cameraList = (params, callback) => doAction(CAMERA_LIST, params, callback);
export const cameraListSuccess = (data = {}) => doResponseAction(CAMERA_LIST_SUCCESS, data);
export const cameraListFailure = (data = {}) => doResponseAction(CAMERA_LIST_FAILURE, data);

// CAMERA_DETAILS
export const cameraDetails = (params, callback) => doAction(CAMERA_DETAILS, params, callback);
export const cameraDetailsSuccess = (data = {}) => doResponseAction(CAMERA_DETAILS_SUCCESS, data);
export const cameraDetailsFailure = (data = {}) => doResponseAction(CAMERA_DETAILS_FAILURE, data);

// CREATE_CAMERA
export const createCamera = (params, callback) => doAction(CREATE_CAMERA, params, callback);
export const createCameraSuccess = (data = {}) => doResponseAction(CREATE_CAMERA_SUCCESS, data);
export const createCameraFailure = (data = {}) => doResponseAction(CREATE_CAMERA_FAILURE, data);

// CAMERA_DELETE
export const cameraDelete = (params, callback) => doAction(CAMERA_DELETE, params, callback);
export const cameraDeleteSuccess = (data = {}) => doResponseAction(CAMERA_DELETE_SUCCESS, data);
export const cameraDeleteFailure = (data = {}) => doResponseAction(CAMERA_DELETE_FAILURE, data);

// CAMERA_UPDATE
export const cameraUpdate = (params, callback) => doAction(CAMERA_UPDATE, params, callback);
export const cameraUpdateSuccess = (data = {}) => doResponseAction(CAMERA_UPDATE_SUCCESS, data);
export const cameraUpdateFailure = (data = {}) => doResponseAction(CAMERA_UPDATE_FAILURE, data);

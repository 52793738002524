import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getUserData } from '../Redux/reducer/LoginReducer';
import { Tooltip } from 'react-tooltip';
export const SideBar = () => {
    const location = useLocation();
    const user = useSelector(getUserData)


    return (
        <div className={`${(location?.pathname === '/schedular' || location?.pathname === '/sections/table') ? "nav-left-sidebar-hide sidebar-dark sideBar text-center" : "nav-left-sidebar sidebar-dark sideBar"} `}>

            <div className="menu-list">
                {/* <nav className="navbar navbar-expand-lg navbar-light">
                    <span className="d-xl-none d-lg-none" >Dashboard</span>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav flex-column">
                            <li className="nav-divider">
                                Menu
                            </li>
                          
                            <Link to={'/dashboard'} className="sideBarLink">
                                <span className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`} data-target="#submenu-8" ><i className="fa fa-fw fa-user-circle navIconSize"></i>
                                    <span
                                        className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                    >Dashboard</span></span>
                            </Link>
                            {user?.user_type === "0" && <Link to={'/attendance'} className="sideBarLink">
                                <span className={`nav-link ${location.pathname === '/attendance' ? 'active' : ''}`} data-target="#submenu-8" >
                                    <i className="fab fa-fw fa-wpforms navIconSize"></i><span
                                        className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                    >Daily Attendance Sheet</span></span>
                            </Link>}
                            <Link to={'/hostelAttendance'} className="sideBarLink">
                                <span className={`nav-link ${location.pathname === '/hostelAttendance' ? 'active' : ''}`} data-target="#submenu-8" >
                                    <i className="fab fa-fw fa-wpforms navIconSize"></i><span
                                        className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                    >Student In/Out Report</span></span>
                            </Link>
                            <Link to={'/onboardedlist'} className="sideBarLink">
                                <span className={`nav-link ${location.pathname === '/onboardedlist' ? 'active' : ''}`} data-target="#submenu-8" >
                                    <i className="fas fa-fw fa-table navIconSize"></i><span
                                        className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                    >Onboarded List</span></span>
                            </Link>
                            <Link to={'/notificationlist'} className="sideBarLink">
                                <span className={`nav-link ${location.pathname === '/notificationlist' ? 'active' : ''}`} data-target="#submenu-8"><i className="fas fa-fw fa-table navIconSize"></i><span
                                    className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                >Notification List</span></span>
                            </Link>
                            <Link to={'/hostelspecialaccess'} className="sideBarLink">
                                <span className={`nav-link ${location.pathname === '/hostelspecialaccess' ? 'active' : ''}`} data-target="#submenu-8"><i className="fa fa-calendar cursor-pointer"></i><span
                                    className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                >Hostel Special Access</span></span>
                            </Link>
                            <Link to={'/hostelreport'} className="sideBarLink">
                                <span className={`nav-link ${location.pathname === '/hostelreport' ? 'active' : ''}`} data-target="#submenu-8"><i className="fas fa-fw fa-table navIconSize"></i><span
                                    className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                >Hostel Report</span></span>
                            </Link>
                            {user?.user_type === "0" &&
                                <Link to={'/cameraList'} className="sideBarLink">
                                    <span className={`nav-link ${location.pathname === '/cameraList' ? 'active' : ''}`} data-target="#submenu-8">
                                        <i className="fa-solid fa-camera navIconSize"></i><span
                                            className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                        >Camera List</span></span>
                                </Link>}
                            {user?.user_type === "0" && <Link to={'/createroom'} className="sideBarLink">
                                <span className={`nav-link ${location.pathname === '/createroom' ? 'active' : ''}`} data-target="#submenu-8" >
                                    <i className="fa-solid fa-hotel navIconSize"></i><span
                                        className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                    >Create Room</span></span>
                            </Link>}
                            {user?.user_type === "0" &&
                                <Link to={'/masterslot'} className="sideBarLink">
                                    <span className={`nav-link ${location.pathname === '/masterslot' ? 'active' : ''}`} data-target="#submenu-8" >
                                        <i className="fa fa-calendar cursor-pointer"></i><span
                                            className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                        >Master slot</span></span>
                                </Link>}
                            {user?.user_type === "0" &&
                                <Link to={'/roomlist'} className="sideBarLink">
                                    <span className={`nav-link ${location.pathname === '/roomlist' ? 'active' : location.pathname === '/schedular' ? 'activeSchedule' : ""}`} data-target="#submenu-8" >
                                        <i className="fas fa-fw fa-table navIconSize"></i><span
                                            className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                        >Room List</span></span>
                                </Link>
                            }
                            {user?.user_type === "0" &&
                                <Link to={'/schedularList'} className="sideBarLink">
                                    <span className={`nav-link ${location.pathname === '/schedularList' ? 'active' : location.pathname === '/sections/table' ? 'activeSchedule' : ''}`} data-target="#submenu-8">
                                        <i className="fa-solid fa fa-clock-o navIconSize"></i><span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}>Scheduler List
                                        </span>
                                    </span>
                                </Link>
                            }
                            {user?.user_type === "0" &&
                                <Link to={'/workerList'} className="sideBarLink">
                                    <span className={`nav-link ${location.pathname === '/workerList' ? 'active' : ''}`} data-target="#submenu-8"><i className="fas fa-wrench navIconSize"></i><span
                                        className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                    >Workers List</span></span>
                                </Link>
                            }{user?.user_type === "0" &&
                                <Link to={'/hostelList'} className="sideBarLink">
                                    <span className={`nav-link ${location.pathname === '/hostelList' ? 'active' : ''}`} data-target="#submenu-8"><i className="fa-solid fa-user navIconSize"></i><span
                                        className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                    >Hostels</span></span>
                                </Link>}
                            {user?.user_type === "0" &&
                                <Link to={'/workerRoomList'} className="sideBarLink">
                                    <span className={`nav-link ${location.pathname === '/workerRoomList' ? 'active' : ''}`} data-target="#submenu-8"><i className="fa-solid fa-door-open navIconSize"></i><span
                                        className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                    >Workers room List</span></span>
                                </Link>}
                           



                        </ul>
                    </div>
                </nav> */}
                <nav class="navbar navbar-expand-lg navbar-light">
                    <div class="container-fluid">
                        <span className="d-xl-none d-lg-none" >Dashboard</span>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav flex-column">
                                <li className="nav-divider">
                                    Menu
                                </li>
                                {/* {user?.user_type === "0" && <Link to={'/dashboard'} className="sideBarLink">
                                <span className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`} data-target="#submenu-8" ><i className="fa fa-fw fa-user-circle navIconSize"></i>
                                    <span
                                        className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                    >Dashboard</span></span>
                            </Link>} */}
                                <Link to={'/dashboard'} className="sideBarLink tooltipDashboard">
                                    <span className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`} data-target="#submenu-8">
                                        <i className="fa fa-fw fa-user-circle navIconSize"></i>
                                        <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}>
                                            Dashboard
                                        </span>
                                    </span>
                                    {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                    <Tooltip anchorSelect=".tooltipDashboard" place="right" variant='warning' positionStrategy='fixed'>Dashboard</Tooltip>
                                     ) : null} 
                                </Link>
                                {user?.user_type === "0" &&
                                    <Link to={'/attendance'} className="sideBarLink tooltipDailyAttendenceSheet">
                                        <span className={`nav-link ${location.pathname === '/attendance' ? 'active' : ''}`} data-target="#submenu-8" >
                                            <i className="fab fa-fw fa-wpforms navIconSize"></i>
                                            <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}>
                                                Daily Attendance Sheet
                                            </span>
                                        </span>
                                        {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                            <Tooltip anchorSelect=".tooltipDailyAttendenceSheet" place="right" variant='warning' positionStrategy='fixed'>Daily Attendance Sheet</Tooltip>
                                        ) : null}
                                    </Link>}
                                <Link to={'/hostelAttendance'} className="sideBarLink tooltipStudentIn_OutReport">
                                    <span className={`nav-link ${location.pathname === '/hostelAttendance' ? 'active' : ''}`} data-target="#submenu-8" >
                                        <i className="fab fa-fw fa-wpforms navIconSize"></i>
                                        <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}>
                                            Student In/Out Report
                                        </span>
                                    </span>
                                    {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                        <Tooltip anchorSelect=".tooltipStudentIn_OutReport" place="right" variant='warning' positionStrategy='fixed'>Student In/Out Report</Tooltip>
                                    ) : null}
                                </Link>
                                <Link to={'/onboardedlist'} className="sideBarLink tooltipOnboardedList">
                                    <span className={`nav-link ${location.pathname === '/onboardedlist' ? 'active' : ''}`} data-target="#submenu-8" >
                                        <i className="fas fa-fw fa-table navIconSize"></i>
                                        <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}>
                                            Onboarded List
                                        </span>
                                    </span>
                                    {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                        <Tooltip anchorSelect=".tooltipOnboardedList" place="right" variant='warning' positionStrategy='fixed'>Onboarded List</Tooltip>
                                    ) : null}
                                </Link>
                                <Link to={'/notificationlist'} className="sideBarLink tooltipNotificationList">
                                    <span className={`nav-link ${location.pathname === '/notificationlist' ? 'active' : ''}`} data-target="#submenu-8">
                                        <i className="fas fa-fw fa-table navIconSize"></i>
                                        <span
                                            className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}>
                                            Notification List
                                        </span>
                                    </span>
                                    {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                        <Tooltip anchorSelect=".tooltipNotificationList" place="right" variant='warning' positionStrategy='fixed'>Notification List</Tooltip>
                                    ) : null}
                                </Link>
                                <Link to={'/hostelspecialaccess'} className="sideBarLink tooltipHostelSpecialAccess">
                                    <span className={`nav-link ${location.pathname === '/hostelspecialaccess' ? 'active' : ''}`} data-target="#submenu-8">
                                        <i className="fa fa-calendar cursor-pointer"></i>
                                        <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}>
                                            Hostel Special Access
                                        </span>
                                    </span>
                                    {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                        <Tooltip anchorSelect=".tooltipHostelSpecialAccess" place="right" variant='warning' positionStrategy='fixed'>Hostel Special Access</Tooltip>
                                    ) : null}
                                </Link>
                                <Link to={'/hostelreport'} className="sideBarLink tooltipHostelReport">
                                    <span className={`nav-link ${location.pathname === '/hostelreport' ? 'active' : ''}`} data-target="#submenu-8">
                                        <i className="fas fa-fw fa-table navIconSize"></i>
                                        <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}>
                                            Hostel Report
                                        </span>
                                    </span>
                                    {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                        <Tooltip anchorSelect=".tooltipHostelReport" place="right" variant='warning' positionStrategy='fixed'>Hostel Report</Tooltip>
                                    ) : null}
                                </Link>
                                {user?.user_type === "0" &&
                                    <Link to={'/cameraList'} className="sideBarLink tooltipCameraList">
                                        <span className={`nav-link ${location.pathname === '/cameraList' ? 'active' : ''}`} data-target="#submenu-8">
                                            <i className="fa-solid fa-camera navIconSize"></i>
                                            <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""} >
                                                Camera List
                                            </span>
                                        </span>
                                        {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                            <Tooltip anchorSelect=".tooltipCameraList" place="right" variant='warning' positionStrategy='fixed'>Camera List</Tooltip>
                                        ) : null}
                                    </Link>}
                                {/* {user?.user_type === "0" && <Link to={'/createroom'} className="sideBarLink">
                                    <span className={`nav-link ${location.pathname === '/createroom' ? 'active' : ''}`} data-target="#submenu-8" >
                                        <i className="fa-solid fa-hotel navIconSize"></i><span
                                            className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                        >Create Room</span></span>
                                </Link>} */}
                                {user?.user_type === "0" &&
                                    <Link to={'/masterslot'} className="sideBarLink tooltipMasterslot">
                                        <span className={`nav-link ${location.pathname === '/masterslot' ? 'active' : ''}`} data-target="#submenu-8" >
                                            <i className="fa fa-calendar cursor-pointer"></i>
                                            <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""} >
                                                Master slot
                                            </span>
                                        </span>
                                        {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                            <Tooltip anchorSelect=".tooltipMasterslot" place="right" variant='warning' positionStrategy='fixed'> Master slot</Tooltip>
                                        ) : null}
                                    </Link>}
                                {user?.user_type === "0" &&
                                    <Link to={'/roomlist'} className="sideBarLink tooltipRoomList">
                                        <span className={`nav-link ${location.pathname === '/roomlist' ? 'active' : location.pathname === '/schedular' ? 'activeSchedule' : ""}`} data-target="#submenu-8" >
                                            <i className={`fas fa-fw fa-table navIconSize ${location.pathname === '/schedular' ? "icon-active" : ""}`}></i>
                                            <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}>
                                                Room List
                                            </span>
                                        </span>
                                        {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                            <Tooltip anchorSelect=".tooltipRoomList" place="right" variant='warning' positionStrategy='fixed'>Room List</Tooltip>
                                        ) : null}
                                    </Link>
                                }
                                {user?.user_type === "0" &&
                                    <Link to={'/schedularList'} className="sideBarLink tooltipSchedulerList">
                                        <span className={`nav-link ${location.pathname === '/schedularList' ? 'active' : location.pathname === '/sections/table' ? 'activeSchedule' : ''}`} data-target="#submenu-8">
                                            {/* <i className="fa-solid fa fa-clock-o navIconSize"></i> */}
                                            <i className={`fa-solid fa fa-clock-o navIconSize ${location.pathname === '/sections/table' ? "icon-active" : ""}`}></i>
                                            <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}>
                                                Scheduler List
                                            </span>
                                        </span>
                                        {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                            <Tooltip anchorSelect=".tooltipSchedulerList" place="right" variant='warning' positionStrategy='fixed'>Scheduler List</Tooltip>
                                        ) : null}
                                    </Link>
                                }
                                {user?.user_type === "0" &&
                                    <Link to={'/workerList'} className="sideBarLink tooltipWorkersList">
                                        <span className={`nav-link ${location.pathname === '/workerList' ? 'active' : ''}`} data-target="#submenu-8">
                                            <i className="fas fa-wrench navIconSize"></i>
                                            <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""} >
                                                Workers List
                                            </span>
                                        </span>
                                        {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                            <Tooltip anchorSelect=".tooltipWorkersList" place="right" variant='warning' positionStrategy='fixed'>Workers List</Tooltip>
                                        ) : null}
                                    </Link>
                                }{user?.user_type === "0" &&
                                    <Link to={'/hostelList'} className="sideBarLink tooltipHostels">
                                        <span className={`nav-link ${location.pathname === '/hostelList' ? 'active' : ''}`} data-target="#submenu-8">
                                            <i className="fa-solid fa-user navIconSize"></i>
                                            <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""} >
                                                Hostels
                                            </span>
                                        </span>
                                        {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                            <Tooltip anchorSelect=".tooltipHostels" place="right" variant='warning' positionStrategy='fixed'>Hostels</Tooltip>
                                        ) : null}
                                    </Link>}
                                {user?.user_type === "0" &&
                                    <Link to={'/workerRoomList'} className="sideBarLink tooltipWorkersroomList">
                                        <span className={`nav-link ${location.pathname === '/workerRoomList' ? 'active' : ''}`} data-target="#submenu-8">
                                            <i className="fa-solid fa-door-open navIconSize"></i>
                                            <span className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""} >
                                                Workers room List
                                            </span>
                                        </span>
                                        {location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? (
                                            <Tooltip anchorSelect=".tooltipWorkersroomList" place="right" variant='warning' positionStrategy='fixed'>Workers room List</Tooltip>
                                        ) : null}
                                    </Link>}
                                {/* <Link to={'/notification'} className="sideBarLink">
                                <span className={`nav-link ${location.pathname === '/notification' ? 'active' : ''}`} data-target="#submenu-8" >
                                    <i className="fa-solid fa-bell navIconSize"></i><span
                                        className={location?.pathname === '/schedular' || location?.pathname === '/sections/table' ? 'hiddenSideNav' : ""}
                                    >Notification History</span> </span>
                            </Link> */}



                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}
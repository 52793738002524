import { doAction, doResponseAction } from ".";

const NOTIFICATION_HISTORY = 'NOTIFICATION_HISTORY';
const NOTIFICATION_HISTORY_SUCCESS = 'NOTIFICATION_HISTORY_SUCCESS';
const NOTIFICATION_HISTORY_FAILURE = 'NOTIFICATION_HISTORY_FAILURE';
export {
    NOTIFICATION_HISTORY,
    NOTIFICATION_HISTORY_FAILURE,
    NOTIFICATION_HISTORY_SUCCESS,
}
// notificationHistory
export const notificationHistoryAction = (params, callback) => doAction(NOTIFICATION_HISTORY, params, callback);
export const notificationHistorySuccessAction = data => doResponseAction(NOTIFICATION_HISTORY_SUCCESS, data);
export const notificationHistoryFailureAction = data => doResponseAction(NOTIFICATION_HISTORY_FAILURE, data);
import { doAction, doResponseAction } from ".";

const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS';
const NOTIFICATION_LIST_FAILURE = 'NOTIFICATION_LIST_FAILURE';

export {
    NOTIFICATION_LIST,
    NOTIFICATION_LIST_FAILURE,
    NOTIFICATION_LIST_SUCCESS,
}

export const notificationList = (params, callback) => doAction(NOTIFICATION_LIST, params, callback);
export const notificationListSuccess = (data = {}) => doResponseAction(NOTIFICATION_LIST_SUCCESS, data);
export const notificationListFailure = (data = {}) => doResponseAction(NOTIFICATION_LIST_FAILURE, data);

import React from "react"
import Close from '../asset/img/modal-close.svg'
import { useDispatch } from "react-redux"
import Actions from "../Redux/action"
import { toast } from "react-toastify"
const AlertGeneratePsswordModal = ({ onClose,user_name}) => {
     const dispatch=useDispatch()
    const handleGenerate=()=>{
        const params={
           username: user_name
        }
        const callback=(res)=>{
            if(res?.status_code === 0){
                onClose()
                toast.success(res?.message)
            }else{
                console.log(res,)
                toast.error(res?.message)
            }
        }
        dispatch(Actions.GeneratePassword(params,callback))
       
    }

    return <>
        <div className="modal d-block"
            id="view-multi-modal"
            tabIndex="-1"
            aria-labelledby="view-multi-modal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sl planogram-div alert-schedule-modal">
                <div className="modal-content rounded-0">
                    <img id="close"
                        src={Close}
                        alt="X"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="modal-close"
                        onClick={() => onClose()}
                    />
                    <div className="modal-header rounded-0 align-items-center py-2 px-4">
                        <div className="col">
                            <h4 className="modal-title">Alert </h4>
                        </div>
                    </div>
                    <div className="modal-body font14 p-40">
                        <div className="container">
                            <h4>Would you like to Re generate password?</h4>
                            <div className="row-3 btn_center">
                                <button
                                    className="btn btn-primary submitRoom"
                                    onClick={() => handleGenerate()}>Yes
                                </button>
                                <button
                                    className="btn submitRoom"
                                    style={{ backgroundColor: 'lightgray', marginLeft: '10px' }}
                                    onClick={() => onClose()}>No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop show"></div>
    </>
}
export default AlertGeneratePsswordModal
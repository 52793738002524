import { combineReducers } from 'redux';
import ClientCredentialsReducer from './ClientCredential';
import LoginReducer from './LoginReducer';
import AttendanceReducer from './AttendanceReducer';
import onboardingListReducer from './OnboardingList';
import searchListReducer from './SearchList';
import MemberReducer from './MemberReducer';
import CreateRoomReducer from './CreateRoomReducer';
import NotificationHistoryReducer from './NotificationHistoryReducer';
import ToggleReducer from './ToggleReducer';
import RoomListReducer from './RoomListReducer';
import RoomReducer from './GetRoomDataReducer';
import UpdateRoomReducer from './GetRoomDataReducer';
import DeleteRoomReducer from './DeleteRoomReducer';
import EditMemberListReducer from './EditMemberListReducer';
import DashboardReducer from './DashboardReducer';
import CameraReducer from './CameraReducer';
import WorkerReducer from './WorkerReducer';
import WorkerRoomReducer from './WorkerRoomReducer';
import SchedulerReducer from './SchedulerReducer';
import MasterSlotReducers from './MasterSLotReducers';
import HostelReducer from './HostelReducer'
import NotificationListReducer from './NotificationListReducer';
import HostelSpecialAccessReducer from './HostelSpecialAccessReducer';
import HostelDashboardReducer from './HostelDashboardReducer';
import HostelReportReducer from './HostelReportReducer';
import DaywiseReportReducer from './DaywiseReportReducer';

const rootReducer = combineReducers(({
    LOGIN_CREDS: LoginReducer,
    CLIENT_CREDS: ClientCredentialsReducer,
    ATTENDANCE_CREDS: AttendanceReducer,
    ONBOARDINGlIST_CRED: onboardingListReducer,
    SEARCHLIST_CRED: searchListReducer,
    MEMBER_CRED: MemberReducer,
    CREATE_ROOM: CreateRoomReducer,
    NOTIFICATION_CRED: NotificationHistoryReducer,
    TOGGLE_CRED: ToggleReducer,
    ROOM_LIST_CRED: RoomListReducer,
    ROOM_CRED: RoomReducer,
    UPDATE_ROOM_CRED: UpdateRoomReducer,
    DELETE_ROOM_CRED: DeleteRoomReducer,
    EDIT_MEMBER_LIST: EditMemberListReducer,
    DASHBOARD_CRED: DashboardReducer,
    CAMERA: CameraReducer,
    WORKER: WorkerReducer,
    HOSTEL: HostelReducer,
    WORKER_ROOM: WorkerRoomReducer,
    SCHEDULER: SchedulerReducer,
    MASTER_SLOT:MasterSlotReducers,
    NOTIFICATION: NotificationListReducer,  
    HOSTEL_SPECIAL_ACCESS: HostelSpecialAccessReducer,
    HOSTEL_DASHBOARD:HostelDashboardReducer,
    HOSTEL_REPORT:HostelReportReducer,
    DAYWISE_REPORT: DaywiseReportReducer,
}))

export default rootReducer;

import { doAction, doResponseAction } from ".";

const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS';
const CREATE_SCHEDULE_FAILURE = 'CREATE_SCHEDULE_FAILURE';

const SAVE_COMBRIFACE = 'SAVE_COMBRIFACE';
const SAVE_COMBRIFACE_SUCCESS = 'SAVE_COMBRIFACE_SUCCESS';
const SAVE_COMBRIFACE_FAILURE = 'SAVE_COMBRIFACE_FAILURE';

const SCHEDULER_LIST = 'SCHEDULER_LIST';
const SCHEDULER_LIST_SUCCESS = 'SCHEDULER_LIST_SUCCESS';
const SCHEDULER_LIST_FAILURE = 'SCHEDULER_LIST_FAILURE';

const COLLEGE_LIST = 'COLLEGE_LIST'
const COLLEGE_LIST_SUCCESS = "COLLEGE_LIST_SUCCESS"
const COLLEGE_LIST_FAILURE = "COLLEGE_LIST_FAILURE"

const COURSE = 'COURSE'
const COURSE_SUCCESS = "COURSE_SUCCESS"
const COURSE_FAILURE = "COURSE_FAILURE"

const YEAR_LIST = 'YEAR_LIST'
const YEAR_LIST_SUCCESS = "YEAR_LIST_SUCCESS"
const YEAR_LIST_FAILURE = "YEAR_LIST_FAILURE"


const SECTION_LIST = 'SECTION_LIST'
const SECTION_LIST_SUCCESS = "SECTION_LIST_SUCCESS"
const SECTION_LIST_FAILURE = "SECTION_LIST_FAILURE"

const DEPARTMENT_LIST = 'DEPARTMENT_LIST'
const DEPARTMENT_LIST_SUCCESS = "DEPARTMENT_LIST_SUCCESS"
const DEPARTMENT_LIST_FAILURE = "DEPARTMENT_LIST_FAILURE"

const UPDATE_SCHEDULER = 'UPDATE_SCHEDULER'
const UPDATE_SCHEDULER_SUCCESS = "UPDATE_SCHEDULER_SUCCESS"
const UPDATE_SCHEDULER_FAILURE = "UPDATE_SCHEDULER_FAILURE"

const DEPERTMENT_SECTION_SEARCH = 'DEPERTMENT_SECTION_SEARCH'
const DEPERTMENT_SECTION_SEARCH_SUCCESS = "DEPERTMENT_SECTION_SEARCH_SUCCESS"
const DEPERTMENT_SECTION_SEARCH_FAILURE = "DEPERTMENT_SECTION_SEARCH_FAILURE"

const SLOT_LIST = 'SLOT_LIST'
const SLOT_LIST_SUCCESS = "SLOT_LIST_SUCCESS"
const SLOT_LIST_FAILURE = "SLOT_LIST_FAILURE"

const SELECTED_DATA = "SELECTED_DATA"
export {
    CREATE_SCHEDULE,
    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_FAILURE,

    SCHEDULER_LIST,
    SCHEDULER_LIST_SUCCESS,
    SCHEDULER_LIST_FAILURE,

    COLLEGE_LIST,
    COLLEGE_LIST_SUCCESS,
    COLLEGE_LIST_FAILURE,

    COURSE,
    COURSE_SUCCESS,
    COURSE_FAILURE,

    DEPARTMENT_LIST,
    DEPARTMENT_LIST_SUCCESS,
    DEPARTMENT_LIST_FAILURE,

    SECTION_LIST,
    SECTION_LIST_SUCCESS,
    SECTION_LIST_FAILURE,

    YEAR_LIST,
    YEAR_LIST_SUCCESS,
    YEAR_LIST_FAILURE,

    UPDATE_SCHEDULER,
    UPDATE_SCHEDULER_SUCCESS,
    UPDATE_SCHEDULER_FAILURE,

    DEPERTMENT_SECTION_SEARCH,
    DEPERTMENT_SECTION_SEARCH_SUCCESS,
    DEPERTMENT_SECTION_SEARCH_FAILURE,

    SAVE_COMBRIFACE,
    SAVE_COMBRIFACE_SUCCESS,
    SAVE_COMBRIFACE_FAILURE,

    SLOT_LIST,
    SLOT_LIST_SUCCESS,
    SLOT_LIST_FAILURE,

    SELECTED_DATA
}
// SCHEDULER
export const CreateScheduleAction = (params, callback) => doAction(CREATE_SCHEDULE, params, callback);
export const CreateScheduleSuccessAction = data => doResponseAction(CREATE_SCHEDULE_SUCCESS, data);
export const CreateScheduleFailureAction = data => doResponseAction(CREATE_SCHEDULE_FAILURE, data);

//SCHEDULER_LIST
export const SchedulerListAction = (params, callback) => doAction(SCHEDULER_LIST, params, callback);
export const SchedulerListSuccessAction = data => doResponseAction(SCHEDULER_LIST_SUCCESS, data);
export const SchedulerListFailureAction = data => doResponseAction(SCHEDULER_LIST_FAILURE, data);

//COLLEGE_LIST
export const CollegeListAction = (params, callback) => doAction(COLLEGE_LIST, params, callback);
export const CollegeListSuccessAction = data => doResponseAction(COLLEGE_LIST_SUCCESS, data);
export const CollegeListFailureAction = data => doResponseAction(COLLEGE_LIST_FAILURE, data);

//COURSE
export const CoursetAction = (params, callback) => doAction(COURSE, params, callback);
export const CoursetSuccessAction = data => doResponseAction(COURSE_SUCCESS, data);
export const CoursetFailureAction = data => doResponseAction(COURSE_FAILURE, data);

export const SectiontAction = (params, callback) => doAction(SECTION_LIST, params, callback);
export const SectiontSuccessAction = data => doResponseAction(SECTION_LIST_SUCCESS, data);
export const SectiontFailureAction = data => doResponseAction(SECTION_LIST_FAILURE, data);

export const YearAction = (params, callback) => doAction(YEAR_LIST, params, callback);
export const YearSuccessAction = data => doResponseAction(YEAR_LIST_SUCCESS, data);
export const YearFailureAction = data => doResponseAction(YEAR_LIST_FAILURE, data);

export const DepartmentAction = (params, callback) => doAction(DEPARTMENT_LIST, params, callback);
export const DepartmentSuccessAction = data => doResponseAction(DEPARTMENT_LIST_SUCCESS, data);
export const DepartmentFailureAction = data => doResponseAction(DEPARTMENT_LIST_FAILURE, data);

//
export const UpdateScheduler = (params, callback) => doAction(UPDATE_SCHEDULER, params, callback);
export const UpdateschedulerSuccessAction = data => doResponseAction(UPDATE_SCHEDULER_SUCCESS, data);
export const UpdateSchedulerFailureAction = data => doResponseAction(UPDATE_SCHEDULER_FAILURE, data);

export const DepartmentSearchSectionList = (params, callback) => doAction(DEPERTMENT_SECTION_SEARCH, params, callback);
export const DepartmentSearchSectionListSuccessAction = data => doResponseAction(DEPERTMENT_SECTION_SEARCH_SUCCESS, data);
export const DepartmentSearchSectionListFailureAction = data => doResponseAction(DEPERTMENT_SECTION_SEARCH_FAILURE, data);

export const SaveCombrifaceAction = (params, callback) => doAction(SAVE_COMBRIFACE, params, callback);
export const SaveCombrifaceActionSuccessAction = data => doResponseAction(SAVE_COMBRIFACE_SUCCESS, data);
export const SaveCombrifaceActionFailureAction = data => doResponseAction(SAVE_COMBRIFACE_FAILURE, data);

export const SlotList = (params, callback) => doAction(SLOT_LIST, params, callback);
export const SlotListSuccessAction = data => doResponseAction(SLOT_LIST_SUCCESS, data);
export const SlotListFailureAction = data => doResponseAction(SLOT_LIST_FAILURE, data);

export const selectedDataList = data => doResponseAction(SELECTED_DATA, data)
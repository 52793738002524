import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../Redux/action';
import { useNavigate } from 'react-router';
import srmLogo from '../asset/img/cv_shield-logo.svg';
import AppLoader from '../component/AppLoader';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { validatemaxLengthZipReg } from '../Utils/Validations';
import { getToken, getUserData } from "../../src/Redux/reducer/LoginReducer";

function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userName, setuserName] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false)
  const [rememberMe, setRememberMe] = useState(false);
  const Token = useSelector(getToken);
  const user = useSelector(getUserData)

  useEffect(() => {
    if (Token) {
      if (user?.user_type === "0" || user?.user_type === "5") {
        navigate('/dashboard')
      }
     
    } else {
      const savedRememberMe = localStorage.getItem('rememberMe');
      if (savedRememberMe === 'true') {
        const savedUserName = localStorage.getItem('userName');
        const savedPassword = localStorage.getItem('password');
        if (savedUserName && savedPassword) {
          setuserName(savedUserName);
          setPassword(savedPassword);
          setRememberMe(true);
        }
      }else{
        navigate('/')
      }

    }
  }, [Token, user, navigate])

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }
  const isUserName = (user) => {
    const validUsername = RegExp(/^[a-zA-Z0-9]+$/)
    return validUsername.test(user);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const callback = (res) => {
      console.log(res, 'response')
      if (res?.token) {
        if (res?.user_type === "0" || res?.user_type === "5") {
          navigate('/dashboard')
          setLoading(false)
          if (rememberMe) {
            localStorage.setItem('rememberMe', 'true');
            localStorage.setItem('userName', userName);
            localStorage.setItem('password', password);
          } else {
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('userName');
            localStorage.removeItem('password');
          }
        }
        else {
          toast.error('Unauthorized Log in')
          setLoading(false)
        }
      } else if (res?.status_code === 1) {
        toast.error(res?.response?.data?.message)
        setLoading(false)
      } else {
        toast.error(res?.response?.data?.message)
        setLoading(false)
      }

    }
    let params = {
      username: userName,
      password: password
    }
    if (userName.trim().length === 0) {
      setError("Please enter the user name")
    } else if (validatemaxLengthZipReg(userName)) {
      setError("Please enter a valid user name")
    } else if (password.trim().length === 0) {
      setError("Please enter the password")
    } else if (validatemaxLengthZipReg(password)) {
      setError("Please enter a valid password")
    } else if (!isUserName(userName)) {
      setError("Invalid user name")
    }
    else {
      e.preventDefault();
      setError('')
      dispatch(Actions.loginAction(params, callback))
      setLoading(true)
    }
  }
  const onChangeUserName = (userName) => {
    setuserName(userName)
    if (userName.trim().length === 0) {
      setError("Please enter the user name")
    } else if (validatemaxLengthZipReg(userName)) {
      setError("Please enter a valid user name")
    } else if (!isUserName(userName)) {
      setError("Invalid user name")
    } else {
      setError("")
    }
  }
  const onChangePassword = (password) => {
    setPassword(password)
    if (password.trim().length === 0) {
      setError("Please enter the password")
    } else if (validatemaxLengthZipReg(password)) {
      setError("Please enter a valid password")
    } else {
      setError("")
    }
  }
  return (
    <>
      {
        isLoading && <AppLoader />
      }
      <div className='login-body'>
        <div className="splash-container">
          <div className="card ">
            <div className="card-header text-center"><a href="../index.html">
              <img className="logo-img" src={srmLogo} alt="logo" />
            </a>
              <span className="splash-description">{error}</span>
            </div>
            <div className="card-body">
              <form>
                <div className="form-group">
                  <input
                    className="form-control form-control-lg"
                    id="username"
                    type="text"
                    placeholder="User name"
                    autoComplete="off"
                    value={userName}
                    onChange={(e) => onChangeUserName(e?.target?.value)}
                  />
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <input
                      className="form-control form-control-lg"
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => onChangePassword(e.target.value)}
                    />
                    <div onClick={togglePasswordVisibility} className="input-group-append">
                      <span className="input-group-text" style={{ padding: "19px" }}>
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    className="form-check-input"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label htmlFor="rememberMe" className="form-check-label">
                    Remember Me
                  </label>
                </div>
                <button type="submit" className="btn btn-primary SignIn" onClick={handleSubmit}>
                  Sign in
                </button>
              </form>
            </div>
            {/* <div className="card-footer bg-white p-0 ">
            <div className="card-footer-item card-footer-item-bordered">
              <a href="/signUp" className="footer-link">Create An Account</a></div>
            <div className="card-footer-item card-footer-item-bordered">
              <a href="/forgotPassword" className="footer-link">Forgot Password</a>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginScreen;

import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { doGet, doPost } from '../../service/index';
import END_POINTS from './EndPoints';
import { COLLEGE_LIST, COURSE, CREATE_SCHEDULE, DEPARTMENT_LIST, DEPERTMENT_SECTION_SEARCH, SAVE_COMBRIFACE, SCHEDULER_LIST, SECTION_LIST, SLOT_LIST, UPDATE_SCHEDULER, YEAR_LIST } from '../action/ScheduleAction';

export function* schedulerListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.SCHEDULER_SLOTS, params)
        yield put(Actions.SchedulerListSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.SchedulerListFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* updateSchedulerSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.UPDATE_SCHEDULER, params)
        yield put(Actions.UpdateschedulerSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.UpdateSchedulerFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* collegeListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.COLLEGE_LIST, params)
        yield put(Actions.CollegeListSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.CollegeListFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* CourseListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.COURSE_LIST, params)
        yield put(Actions.CoursetSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.CoursetFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* SectionListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.SECTION_LIST, params)
        yield put(Actions.SectiontSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.SectiontFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* YearListSaga({ params, callback }) {
    try {
        console.log(params,'params')
        const response = yield doGet(END_POINTS.YEAR_LIST, params)
        console.log(response,'response')
        yield put(Actions.YearSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.YearFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* DepartmentListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.DEPARTMENT_LIST, params)
        yield put(Actions.DepartmentSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.DepartmentFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* DepartmentSearchListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.DEPARTMENT__SEARCH, params)
        yield put(Actions.DepartmentSearchSectionListSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.DepartmentSearchSectionListFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* SlotListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.SLOT_LIST, params)
        yield put(Actions.SlotListSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.SlotListFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* SaveCombrifaceSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.SAVE_COMBRIFACE, params)
        yield put(Actions.SaveCombrifaceActionSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.SaveCombrifaceActionFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export function* createSchedule({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_SCHEDULER, params)
        yield put(Actions.CreateScheduleSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.CreateScheduleFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* schedulerWatcher() {
    yield all([
        takeLatest(SCHEDULER_LIST, schedulerListSaga),
        takeLatest(CREATE_SCHEDULE, createSchedule),
        takeLatest(COLLEGE_LIST, collegeListSaga),
        takeLatest(COURSE, CourseListSaga),
        takeLatest(DEPARTMENT_LIST, DepartmentListSaga),
        takeLatest(SECTION_LIST, SectionListSaga),
        takeLatest(YEAR_LIST, YearListSaga),
        takeLatest(UPDATE_SCHEDULER, updateSchedulerSaga),
        takeLatest(DEPERTMENT_SECTION_SEARCH, DepartmentSearchListSaga),
        takeLatest(SAVE_COMBRIFACE, SaveCombrifaceSaga),
        takeLatest(SLOT_LIST, SlotListSaga),

    ]);
}
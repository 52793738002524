import { takeLatest, all, put } from 'redux-saga/effects';
import Actions from "../action";
import { SEARCH, SEARCH_LIST } from '../action/SearchAction'
import { doGet } from '../../service/index';
import END_POINTS from './EndPoints';

export function* searchSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.SEARCH_API, params)
        yield put(Actions.searchSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.searchFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}

export function* searchListSaga({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.SEARCH_LIST_API, params)
        yield put(Actions.searchListSuccessAction(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.searchListFailureAction({ error }));
        if (callback) {
            callback(error)
        }
    }
}
export default function* serachWatcher() {
    yield all([
        takeLatest(SEARCH, searchSaga),
        takeLatest(SEARCH_LIST, searchListSaga),
    ]);
}
import { doAction, doResponseAction } from ".";


const HOSTEL_REPORT = 'HOSTEL_REPORT';
const HOSTEL_REPORT_SUCCESS = 'HOSTEL_REPORT_SUCCESS';
const HOSTEL_REPORT_FAILURE = 'HOSTEL_REPORT_FAILURE';

const CLEAR_HOSTEL_REPORT="CLEAR_HOSTEL_REPORT"
export {
    HOSTEL_REPORT ,
    HOSTEL_REPORT_SUCCESS,
    HOSTEL_REPORT_FAILURE,
    CLEAR_HOSTEL_REPORT
}
// REPORT
export const clearReports=data=>doAction(CLEAR_HOSTEL_REPORT,data)


export const hostelReportAction = (params, callback) => doAction(HOSTEL_REPORT, params, callback);
export const hostelReportSuccessAction = data => doResponseAction(HOSTEL_REPORT_SUCCESS, data);
export const hostelReportFailureAction = data => doResponseAction(HOSTEL_REPORT_FAILURE, data);

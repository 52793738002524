import Close from '../asset/img/modal-close.svg'
export const DeclinePopup = ({ title, onClose, onSuccess }) => {
    return (
        <>
            <div className="modal deleteModel d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl planogram-div">
                    <div className="modal-content LogOutPopup rounded-0" style={{width:'400px'}}>
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={onClose} />
                        <div className="modal-header logoutheader rounded-0 align-items-center py-4 px-4 mt-4">
                            <div className="container">
                            <p className="text-center">{title}</p>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="container">
                                <div className="row">
                                    <div className="col-6">
                                        <button className="btn btn-primary logOut" onClick={onSuccess}>Yes</button>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-primary Cancel" onClick={onClose}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}
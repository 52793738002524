import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'


const AppDatePicker = ({ notValidTime, placeholder, ...props }) => {
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div onClick={onClick}>
            <input
                defaultValue={value}
                className="form-control dateInput cursor-pointer"
                // disabled
                readOnly
                ref={ref}
                placeholder={placeholder}
            />
        </div>
    ));
    return (
        <div >

            {
                notValidTime ? <DatePicker
                    {...props}
                    customInput={<CustomInput />}
                    strictParsing
                    // isClearable
                /> : <DatePicker
                    {...props}
                    customInput={<CustomInput />}
                    strictParsing
                    // isClearable
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                />
            }

        </div>
    );

}

export default AppDatePicker;
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppLoader from "../component/AppLoader";
import { AddCameraPopup } from "../component/AddCamera";
import { PaginationComponent } from "../component/PaginationComponent"
import Actions from "../Redux/action";
import { getCameraListSelector, getCameraCountSelector, getCameraListLoadingSelector } from "../Redux/reducer/CameraReducer";
import nodatafound from '../asset/img/datanotfound_black.png';
import { DeclinePopup } from "../component/DeclinePopup";
export const CameraList = () => {
    const totalCount = useSelector(getCameraCountSelector);
    const loading = useSelector(getCameraListLoadingSelector);

    const [pageNumber, setPageNumber] = useState(1);
    const cameralist = useSelector(getCameraListSelector);
    const [popupData, setPopupData] = useState();
    const [popup, setPopup] = useState(false);
    const dispatch = useDispatch();
    const [itemObj, setItemObj] = useState('')
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);

    const getCameraList = useCallback(() => {
        let params = {
            page: pageNumber
        }
        dispatch(Actions.cameraList(params))
    }, [dispatch, pageNumber])

    useEffect(() => {
        getCameraList()
    }, [getCameraList])

    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const editCamera = (cameraData) => {
        setPopupData(cameraData)
        setPopup(true)
    }

    const deleteCamera = () => {
        const callback = (response) => {
            if (response?.status_code === 0) {
                onCloseModel()
                toast.success("Camera successfully deleted")
                getCameraList()
                if (cameralist?.length===1) {
                    setPageNumber(pageNumber - 1)
                } else {
                    setPageNumber(pageNumber)
                }

            } else {
                toast.error(response?.message)
            }
        }
        let params = {
            id: itemObj?.id
        }
        dispatch(Actions.cameraDelete(params, callback))
    }
    const onClosePopUp = () => {
        setPopupData('')
        setPopup(false)
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const handleClick = (item) => {
        setItemObj(item);
        setOpenDeclineModal(true);
    };
    return (
        <>
            {
                loading && <AppLoader />
            }
            <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                <div className="mb-2" style={{ textAlign: 'end' }}>
                    <button type="button" className="btn btn-primary" onClick={() => setPopup(true)}>Add Camera</button>
                </div>
                <div className="card">
                    <h5 className="card-header">Camera List</h5>

                    <div className="card-body p-0">
                        {
                            cameralist && cameralist.length > 0 ?
                                <div className="table-responsive">
                                    <table className="table onboardTable">
                                        <thead className="bg-light">
                                            <tr className="border-0">
                                                <th className="border-0">S.No</th>
                                                <th className="border-0">Camera Name</th>
                                                <th className="border-0">Camera type</th>
                                                <th className="border-0">Entry Side</th>
                                                <th className="border-0">RTSP URL</th>
                                                <th className="border-0">Edit</th>
                                                <th className="border-0">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cameralist && cameralist?.map((item, index) => {
                                                    return (<tr>
                                                        <td>{((pageNumber - 1) * 10) + (index + 1)}</td>
                                                        <td>{item?.camera_name}</td>
                                                        <td>{item?.camera_type} </td>
                                                        <td>{item?.entry_side} </td>
                                                        <td>{item?.rtsp_url} </td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" onClick={() => editCamera(item)}>Edit</button>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" onClick={() => handleClick(item)}>Delete</button>
                                                        </td>
                                                    </tr>)
                                                }
                                                )
                                            }
                                            <tr>
                                                <td colspan="9">
                                                    <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="d-flex justify-content-center mt-5">
                                    <div className="NodataFound">
                                        <h3>No Data Found</h3>
                                    </div>
                                    <div className="NodataImage">
                                        <img src={nodatafound} alt="No Data Found" className="NoData" />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {
                popup && <AddCameraPopup pageNumber={pageNumber} onClose={() => onClosePopUp(false)} data={popupData}  />

            }
             {isOpenDeclineModal && <DeclinePopup
                title={`Are you sure you want to delete ${itemObj?.camera_name}?`}
                onClose={() => onCloseModel()}
                onSuccess={() => deleteCamera()}
            />}
        </>
    )
}
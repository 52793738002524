import Actions from "../action";

const initialState = {
    isLoading: false,
    createRoom: undefined,
    error: false
}
const CreateRoomReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.CREATE_ROOM: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CREATE_ROOM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                createRoom: action.data,
                error: false
            };
        }
        case Actions.CREATE_ROOM_FAILURE: {
            return {
                ...state,
                isLoading: false,
                createRoom: undefined,
                error: true,
            };
        }
        default:
            return state;
    }
};

export default CreateRoomReducer;